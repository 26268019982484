import React, { Component } from "react";
import { Navbar, Nav  } from "react-bootstrap";

class NavHeader extends Component {

  homeOnclick =()=>{
    this.props.data.history.push(`/interactives/${this.props.user}/${sessionStorage.getItem("org_id")}/${sessionStorage.getItem("loreeVersion")}`);
    window.location.reload();
  }

  interactivesOnclick =()=>{
    this.props.data.history.push(`/Contents/${this.props.user}/${this.props.user_id}`);
    window.location.reload();
  }

  render() {
    return (
      <React.Fragment>
        <Navbar bg="light" variant="light" className="shadow fixed-top">
          <Nav activeKey={window.location.pathname} variant="pills" className="mr-auto navbtn">
            <Nav.Link as={"button"} href={`/interactives/${this.props.user}/${sessionStorage.getItem("org_id")}/${sessionStorage.getItem("loreeVersion")}`} className="btn btn-pill btn-white btn-sm ml-2 mw-100" onSelect={this.homeOnclick}>Home</Nav.Link>
            <Nav.Link as={"button"} href={`/Contents/${this.props.user}/${this.props.user_id}`} className="btn btn-pill btn-white btn-sm ml-2 mw-100" onSelect={this.interactivesOnclick}>My Interactives</Nav.Link>
          </Nav>
        </Navbar>
      </React.Fragment>
    );
  }
}

export default NavHeader;
