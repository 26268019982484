//initial state for redux
const INITIAL_STATE = {
  num: 0,
  contentArr: [["title_0", "text_0"]],
  dndArray: [["background_0", "text_0"]],
  title: "",
  parameters: [{title: "", text: ""}],
  dndParameters: [{background: "", dropZone:[]}],
  errors: {},
  type: "",
  fontHeadings:"",
  fontFamily:"Helvetica",
  fontSize:"16",
  fontWeight:"Normal",
  radius: "rounded-0",
  icon: "arrow",
  bgcolor: "#F2F2F2",
  alignment: "left",
  fontColor: "#000000",
  borderType:"",
  borderColor:"#000000",
  borderSize:"1",
  borderStyle:"solid",
  setcaptionAlignment:"bottom",
  iconColor:"#F2F2F2",
  iconSize:"10px",
  iconAlignment: "Default",
  iconType: "0%",
  dndLayout: "CustomOverlay",
  dragAreaType:"btn btn-square btn-dark",
  paddingTop:"10",
  paddingLeft:"10",
  paddingBottom:"10",
  paddingRight:"10",
  dropzoneBgColor: "#F2F2F2",
  dropzoneOpacity: "0.5",
  hotspotIcon: { draw: "M0,15A15,15,0,1,1,15,30,15,15,0,0,1,0,15Zm1.2,0A13.8,13.8,0,1,0,15,1.2,13.816,13.816,0,0,0,1.2,15Zm12.445,6.145V16.354H8.855a1.355,1.355,0,0,1,0-2.709h4.791V8.854a1.355,1.355,0,0,1,2.709,0v4.791h4.791a1.355,1.355,0,1,1,0,2.709H16.355v4.791a1.355,1.355,0,0,1-2.709,0Z"
    , iconType: "Plus"},
};

//action creators with actions
const formReducer = (state = INITIAL_STATE, data) => {
  switch (data.type) {
  //add panel action
  case "ADD_PANEL":
    return {
      ...state,
      parameters: data.parameters,
      contentArr: data.contentArr,
      num: data.num
    };

  //title within panel action
  case "HANDLE_CHANGE":
    return {
      ...state,
      parameters: data.parameters,
      errors: data.errors
    };
  
  //rearange the parameters
  case "REARANGE_ARRAY":
    return {
      ...state,
      parameters: data.parameters,
      contentArr: data.contentArr
    };

  //main-title action  
  case "HANDLE_TITLE":
    return {
      ...state,
      title: data.title
    };

  //remove action
  case "REMOVE_CLICK": 
    return {
      ...state,
      parameters: data.parameters,
      contentArr: data.contentArr,
      errors: data.errors
    };

  //validation action
  case "ERRORS": 
    return {
      ...state,
      errors: data.errors
    };
  case "MAKE_EDIT":
    return {
      ...state,
      parameters: data.parameters,
      contentArr: data.contentArr,
      title: data.title,
      type: data.component,
      fontHeadings:data.fontHeadings,
      fontFamily:data.fontFamily,
      fontSize:data.fontSize,
      fontWeight:data.fontWeight,
      radius: data.radius,
      bgcolor : data.bgcolor,
      icon : data.icon,
      alignment: data.alignment,
      fontColor: data.fontColor,
      borderType: data.borderType,
      borderColor: data.borderColor,
      borderSize: data.borderSize,
      borderStyle: data.borderStyle,
      setcaptionAlignment: data.setcaptionAlignment,
      iconColor: data.iconColor,
      iconSize: data.iconSize,
      iconAlignment: data.iconAlignment,
      iconType: data.iconType,
      dndLayout: data.dndLayout,
      dndParameters: data.dndParameters,
      dndArray: data.dndArray,
      dragAreaType: data.dragAreaType,
      paddingTop:data.paddingTop,
      paddingLeft:data.paddingLeft ,
      paddingBottom:data.paddingBottom,
      paddingRight:data.paddingRight,
      dropzoneBgColor: data.dropzoneBgColor,
      dropzoneOpacity: data.dropzoneOpacity,
      hotspotIcon: data.hotspotIcon,
    };
  case "SET_TYPE":
    return {
      ...state,
      type: data.component
    };

  // To set the ID for the panels 
  case "SET_VALUE":
    return{
      ...state,
      num: data.num
    };

  //customization actions
  case "FONT_HEADINGS":
    return {
      ...state,
      fontHeadings:data.component
    };  
  
  case "FONT_TYPE":
    return {
      ...state,
      fontFamily:data.component
    };

  case "FONT_SIZE":
    return {
      ...state,
      fontSize:data.component
    };

  case "FONT_WEIGHT":
    return {
      ...state,
      fontWeight:data.component
    };

  case "CUSTOM_BORDER":
    return {
      ...state,
      borderType: data.borderType
    };

  case "BORDER_COLOR":
    return {
      ...state,
      borderColor: data.borderColor
    };

  case "BORDER_SIZE":
    return {
      ...state,
      borderSize:data.component
    };   

  case "BORDER_STYLE":
    return {
      ...state,
      borderStyle:data.component
    };

  case "SET_ICON":
    return {
      ...state,
      icon: data.icon,
    };
  case "CUSTOM_RADIUS":
    return {
      ...state,
      radius: data.radius
    };
  case "CUSTOM_BGCOLOR":
    return {
      ...state,
      bgcolor: data.bgcolor
    };

  case "CUSTOM_ALIGNMENT":
    return {
      ...state,
      alignment: data.alignment
    };
  case "CUSTOM_FONTCOLOR":
    return {
      ...state,
      fontColor: data.fontColor
    };
  case "CUSTOM_CAPTIONALIGNMENT":
    return {
      ...state,
      setcaptionAlignment:data.setcaptionAlignment
    };
  case "ICON_COLOR":
    return {
      ...state,
      iconColor: data.iconColor
    };
  
  case "ICON_SIZE":
    return {
      ...state,
      iconSize: data.iconSize
    };

  case "ICON_TYPE":
    return {
      ...state,
      iconType: data.iconType
    };
  
  case "CUSTOM_ICON_ALIGNMENT":
    return {
      ...state,
      iconAlignment: data.iconAlignment
    };

  case "DRAG_AND_DROP_LAYOUT":
    return {
      ...state,
      dndLayout: data.dndLayout
    };
  
  case "SET_BACKGROUND_IMG":
    return{
      ...state,
      dndParameters: data.dndParameters,  
      errors: data.errors
    };

  case "SET_ERROR":
    return{
      ...state,
      errors: data.errors
    };
  
  case "ADD_SECTION":
    return {
      ...state,
      dndParameters: data.dndParameters,
      dndArray: data.dndArray,
      num: data.num
    };
  
  //rearange the parameters of drangdrop
  case "SORT_ARRAY":
    return {
      ...state,
      dndParameters: data.dndParameters,
      dndArray: data.dndArray
    };

  case "REMOVE_ELEMENT": 
    return {
      ...state,
      dndParameters: data.dndParameters,
      dndArray: data.dndArray,
      errors: data.errors
    };

  case "DRAG_AREA_TYPE":
    return {
      ...state,
      dragAreaType: data.dragAreaType
    };

  case "CUSTOM_PADDINGTOP":
    return {
      ...state,
      paddingTop: data.paddingTop
    };

  case "CUSTOM_PADDINGLEFT":
    return {
      ...state,
      paddingLeft: data.paddingLeft
    };

  case "CUSTOM_PADDINGBOTTOM":
    return {
      ...state,
      paddingBottom: data.paddingBottom
    };
  
  case "CUSTOM_PADDINGRIGHT":
    return {
      ...state,
      paddingRight: data.paddingRight
    };

  case "DROPZONE_BGCOLOR":
    return {
      ...state,
      dropzoneBgColor: data.dropzoneBgColor
    };
  
  case "DROPZONE_OPACITY":
    return {
      ...state,
      dropzoneOpacity:data.dropzoneOpacity
    };
  
  case "SET_HOTSPOT_ICON":
    return {
      ...state,
      hotspotIcon: data.hotspotIcon,
    };
  default: 
    return state;
  }
};

export default formReducer;
