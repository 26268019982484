import React, { Fragment } from 'react';
import './loader.scss';

const circleLoader = ({
  width,
  height,
  color
}) => {
  return (
    <Fragment>
      <svg className="spinner" width={width} height={height} viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
        <circle className="path" fill="transparent" strokeWidth="2" cx="33" cy="33" r="30" stroke="url(#gradient)" />
        <linearGradient id="gradient">
          <stop offset="50%" stopColor={color} stopOpacity="1"/>
          <stop offset="65%" stopColor={color} stopOpacity=".5"/>
          <stop offset="100%" stopColor={color} stopOpacity="0"/>
        </linearGradient>
      </svg>
    </Fragment>
  );
};


export default circleLoader;
