/* eslint-disable no-undefined */
import React, { Component } from "react";
import { Card, Col, Accordion, Form, InputGroup, Button, FormControl, CardDeck } from "react-bootstrap";
import { connect } from "react-redux";
import { setDragandDropLayout, handleErrors, fontType, fontSize, fontWeight, setBgColor, setDropzoneBgColor, setDropzoneOpacity, fontColor, setDragAreaType, 
  paddingBottom, paddingLeft, paddingRight, paddingTop} from "../../../redux/form/form.actions";

import { SketchPicker } from "react-color";
import styles from "../../../assets/customstyles";
import "./customize.scss";
class DnDCustomize extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseID: "collapse1", 
    };
  }

  componentDidMount(){
    if(this.props.content.match.params.id){
      this.setState({collapseID: "collapse2"});
    }
  }
  // toggle the collapse ID for changing the accordion icon
  toggleCollapse(collapseID) {
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : ""
    }));
  }

  // set the layout of drag and drop
  setLayout(e) {
    const { Layout, HandleError } = this.props;
    const value =  e.currentTarget.value;
    Layout({
      dndLayout: value,
    });
    HandleError({
      errors: {}
    });
  }

   //font customisation
   fontType = (e) => {
     const { HandleFontFamily } = this.props;
     HandleFontFamily({
       fontFamily: e.target.value
     });
   }

  fontsize = (e) => {
    const { HandleFontSize, errors } = this.props;
    const {value} = e.target;
    let fail = errors;
    delete fail["fontSize"];
    var charCode = (e.which) ? e.which : e.keyCode;
    if ((value <= 40) === true ) {
      if (charCode === 8 || charCode === 13 || charCode === 99 || charCode === 118 || charCode === 46 || charCode === 101 || charCode === 45 || charCode === 43) {
        e.preventDefault();
      }
      HandleFontSize({
        fontSize: e.target.value,
        errors: fail
      });
    }
  }

  dropzoneOpacity = (e) => {
    const { DropzoneOpacity } = this.props;
    const {value} = e.target;
    let charCode = (e.which) ? e.which : e.keyCode;
    if (charCode === 8 || charCode === 13 || charCode === 99 || charCode === 118 || charCode === 101 || charCode === 45 || charCode === 43) {
      e.preventDefault();
    }
    DropzoneOpacity({
      dropzoneOpacity: value, 
    });
  }

  fontweight = (e) => {
    const { HandleFontWeight } = this.props;
    HandleFontWeight({
      fontWeight: e.target.value
    });
  }

  //change font color
  changeFontColor = (color) => {
    const { FontColor } = this.props;
    FontColor({
      fontColor: color.hex ? color.hex : color.target.value
    });
  }

  //change background color
  changeBackgroundColor = (color) => {
    const { BgColor } = this.props;
    BgColor({
      bgcolor: color.hex ? color.hex : color.target.value
    });
  }

   //change dropzone background color
   changeDropzoneBackgroundColor = (color) => {
     const { DropzoneBgColor } = this.props;
     DropzoneBgColor({
       dropzoneBgColor: color.hex ? color.hex : color.target.value
     });
   }

  colorSubmit = (value) => {
    if(value === "font"){
      this.setState({ displayFontColorPicker: !this.state.displayFontColorPicker });
    }else{
      this.setState({ displayColorPicker: !this.state.displayColorPicker });
    }
  }

  //value for buttontype
  dragAreaType = (event) => {
    const { DragAreaType } = this.props;
    DragAreaType({
      dragAreaType: event.target.value
    });
  }

  setCustompaddingtop = (e) => {
    const { SetPaddingtop } = this.props;
    const {value} = e.target;
    var charCode = (e.which) ? e.which : e.keyCode;

    if ((value <= 200) === true ) {
      if (charCode === 8 || charCode === 13 || charCode === 99 || charCode === 118 || charCode === 46 || charCode === 101 || charCode === 45 || charCode === 43) {
        e.preventDefault();
      }
      SetPaddingtop({
        paddingTop:e.target.value
      });
    }
  }

  //set custom css
  setCustompaddingleft = (e) => {
    const { SetPaddingleft } = this.props;
    const {value} = e.target;
    var charCode = (e.which) ? e.which : e.keyCode;

    if ((value <= 200) === true ) {
      if (charCode === 8 || charCode === 13 || charCode === 99 || charCode === 118 || charCode === 46 || charCode === 101 || charCode === 45 || charCode === 43) {
        e.preventDefault();
      }
      SetPaddingleft({
        paddingLeft:e.target.value
      });
    }
  }

  setCustompaddingbottom = (e) => {
    const { SetPaddingbottom } = this.props;
    const {value} = e.target;
    var charCode = (e.which) ? e.which : e.keyCode;

    if ((value <= 200) === true ) {
      if (charCode === 8 || charCode === 13 || charCode === 99 || charCode === 118 || charCode === 46 || charCode === 101 || charCode === 45 || charCode === 43) {
        e.preventDefault();
      }
      SetPaddingbottom({
        paddingBottom:e.target.value
      });
    }
  }

  setCustompaddingright = (e) => {
    const { SetPaddingright } = this.props;
    const {value} = e.target;
    var charCode = (e.which) ? e.which : e.keyCode;

    if ((value <= 200) === true ) {
      if (charCode === 8 || charCode === 13 || charCode === 99 || charCode === 118 || charCode === 46 || charCode === 101 || charCode === 45 || charCode === 43) {
        e.preventDefault();
      }
      SetPaddingright({
        paddingRight:e.target.value
      });
    }
  }

  render() {
    const { collapseID } = this.state;
    const { dndLayout, content, fontColor, fontFamily, fontSize, fontWeight, errors, bgcolor, dropzoneBgColor, dropzoneOpacity, dragAreaType, paddingTop,
      paddingLeft, paddingBottom, paddingRight } = this.props;

    let draggableArea= (
      <React.Fragment>
        <Accordion.Toggle className="font-weight-bold" as={Card.Header} eventKey="1" onClick={() => this.toggleCollapse("collapse2")}>
          <i className={collapseID === "collapse2" ? "fa fa-caret-down" : "fa fa-caret-right"}></i> Draggable Area
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="1">
          <Card.Body className="subheader_bottom">
            <Form>
              <Form.Row>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Font Size</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                      </InputGroup.Prepend>
                      <Form.Control type="number" min="10" max="40" name="fontSize" size="md" value={fontSize} onKeyPress={this.fontsize} onChange={this.fontsize} />
                      <InputGroup.Append>
                        <InputGroup.Text>px</InputGroup.Text>
                      </InputGroup.Append>
                      {errors.fontSize ? (
                        <small className="text-danger">{errors.fontSize}</small>
                      ) : null}
                    </InputGroup>
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Font Family</Form.Label>
                    <Form.Control as="select" value = {fontFamily} onChange={this.fontType} size="md">
                      {/* Looping for font family */}
                      {
                        Object.entries(styles.fontFamily).map(([key, value], i) => {
                          return <option key={i} style={{ fontFamily: value }} value = {value} >{key}</option>;
                        })
                      }
                    </Form.Control>
                  </Form.Group>
                </Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>Font Weight</Form.Label>
                  <Form.Control as="select"  value = {fontWeight} onChange={this.fontweight} size="md">
                    {
                      styles.fontWeight.map((fontlist, i) => {
                        return <option key={i} value = {fontlist}>{fontlist}</option>;
                      })
                    }
                  </Form.Control>
                </Form.Group>
              </Form.Row>
              <Form.Group>
                <label>Font Color</label>
                <InputGroup size="sm">
                  <FormControl
                    placeholder="Font Color"
                    value={fontColor}
                    onChange={this.changeFontColor}
                    maxLength="7"
                  />
                  <InputGroup.Append>
                    <Button variant="outline-secondary" onClick={this.colorSubmit.bind(this,"font")} style={{ backgroundColor: fontColor }}><i className="fa fa-eye-dropper"></i></Button>
                  </InputGroup.Append>
                </InputGroup>
                {this.state.displayFontColorPicker ? <SketchPicker disableAlpha color={fontColor} onChange={this.changeFontColor}></SketchPicker> : null}
              </Form.Group>
              <Form.Group>
                <label>Background Color</label>
                <InputGroup size="sm">
                  <FormControl
                    value={this.props.bgcolor}
                    onChange={this.changeBackgroundColor}
                    maxLength="7"
                  />
                  <InputGroup.Append>
                    <Button variant="outline-secondary" onClick={this.colorSubmit.bind(this,"background")} style={{ backgroundColor: bgcolor }}><i className="fa fa-eye-dropper"></i></Button>
                  </InputGroup.Append>
                </InputGroup>
                {this.state.displayColorPicker ? <SketchPicker disableAlpha color={bgcolor} onChange={this.changeBackgroundColor}></SketchPicker> : null}
              </Form.Group> 
              <label>Type</label>
              <CardDeck>
                {Object.entries(styles.btnType).map(([key,value], idx) => {
                  return (
                    <label key={idx}>
                      <input type="radio" name="btn-type" value={value} onChange={this.dragAreaType} checked={dragAreaType === value} className="card-input-element" />
                      <Card className="small-card card-input">
                        <Button variant={value} size="sm" style={{ marginTop: 15, height: 30 }}>{key}</Button>
                      </Card>
                    </label>
                  );
                })}
              </CardDeck><hr></hr>
              <Form.Group className="mt-3">
                <Form.Label>Padding <span style={{ fontSize: 13 }} className="text-muted"> (Value must be 0-200px) </span> </Form.Label>
                <Form.Row>
                  <Col xs={6}>                    
                    <Form.Label>Top</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                      </InputGroup.Prepend>
                      <Form.Control type="number" min="0" max="200" value={paddingTop} onKeyPress={this.setCustompaddingtop} onChange={this.setCustompaddingtop} />
                      <InputGroup.Append>
                        <InputGroup.Text>px</InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                  </Col>
                  <Col>
                    <Form.Label>Left</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                      </InputGroup.Prepend>
                      <Form.Control type="number" min="0" max="200" value={paddingLeft} onKeyPress={this.setCustompaddingleft} onChange={this.setCustompaddingleft} />
                      <InputGroup.Append>
                        <InputGroup.Text>px</InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                  </Col>
                  <Col xs={6}>
                    <Form.Label>Bottom</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                      </InputGroup.Prepend>
                      <Form.Control type="number" min="0" max="200" value={paddingBottom} onKeyPress={this.setCustompaddingbottom} onChange={this.setCustompaddingbottom} />
                      <InputGroup.Append>
                        <InputGroup.Text>px</InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup> </Col>
                  <Col>
                    <Form.Label>Right</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                      </InputGroup.Prepend>
                      <Form.Control type="number" min="0" max="200" value={paddingRight} onKeyPress={this.setCustompaddingright} onChange={this.setCustompaddingright} />
                      <InputGroup.Append>
                        <InputGroup.Text>px</InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                  </Col>
                </Form.Row>
              </Form.Group>
            </Form>
          </Card.Body>
        </Accordion.Collapse>
      </React.Fragment>
    );
    let dropzoneArea= (
      <React.Fragment>
        <Accordion.Toggle className="font-weight-bold" as={Card.Header} eventKey="2" onClick={() => this.toggleCollapse("collapse3")}>
          <i className={collapseID === "collapse3" ? "fa fa-caret-down" : "fa fa-caret-right"}></i> Dropzone Area
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="2">
          <Card.Body className="subheader_bottom">
            <Form>
              <Form.Group>
                <label>Background Color</label>
                <InputGroup size="sm">
                  <FormControl
                    value={this.props.dropzoneBgColor}
                    onChange={this.changeDropzoneBackgroundColor}
                    maxLength="7"
                  />
                  <InputGroup.Append>
                    <Button variant="outline-secondary" onClick={this.colorSubmit.bind(this,"background")} style={{ backgroundColor: dropzoneBgColor }}><i className="fa fa-eye-dropper"></i></Button>
                  </InputGroup.Append>
                </InputGroup>
                {this.state.displayColorPicker ? <SketchPicker disableAlpha color={dropzoneBgColor} onChange={this.changeDropzoneBackgroundColor}></SketchPicker> : null}
                {dropzoneOpacity === "0" && <small className="text-secondary">The color will not be reflected if opacity is 0.</small>}
              </Form.Group> 
              <Form.Group>
                <Form.Label>Opacity</Form.Label>
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                  </InputGroup.Prepend>
                  <Form.Control type="number" min="0" max="1" name="opacity" step="0.1" size="md" value={dropzoneOpacity} onKeyPress={this.dropzoneOpacity} onChange={this.dropzoneOpacity} />
                </InputGroup>
              </Form.Group>
            </Form>
          </Card.Body>
        </Accordion.Collapse>
      </React.Fragment>
    );
    return (
      <React.Fragment>
        <Col sm={4} className="float-right">
          <Card>
            <Card.Header className="h4 font-weight-bold header">Customize</Card.Header>
            <Card.Body className="p-0">
              <Accordion defaultActiveKey={!content.match.params.id ? "0" : "1"}>
                {( !content.match.params.id ? 
                  <>
                    <Accordion.Toggle className="font-weight-bold" as={Card.Header} eventKey="0" onClick={() => this.toggleCollapse("collapse1")}>
                      <i className={collapseID === "collapse1" ? "fa fa-caret-down" : "fa fa-caret-right"}></i> Drop Zone Layout
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body className="subheader_bottom">
                        <div>
                          <label className="layout_container">Custom
                            <input type="radio"  value = "CustomOverlay" checked = {(dndLayout === "CustomOverlay") ? true : false} name="radio"  
                              onChange={e => this.setLayout(e)} />
                            <span className="checkmark"></span>
                          </label>
                          <label className="layout_container">Fixed
                            <input type="radio" name="radio"  value = "Fixed"
                              checked = {(dndLayout === "Fixed") ? true : false}  onChange={e => this.setLayout(e)}/>
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                    {draggableArea} {dropzoneArea}
                  </> : <React.Fragment>{draggableArea} {dropzoneArea} </React.Fragment>
                )}
              </Accordion>
            </Card.Body>
          </Card>
        </Col>
      </React.Fragment>
    );
  }
}

//state from redux
const mapStateToProps = ({ form }) => ({
  type: form.type,    
  dndLayout: form.dndLayout,
  fontHeadings: form.fontHeadings,
  fontColor: form.fontColor,
  fontFamily: form.fontFamily,
  fontSize: form.fontSize,
  fontWeight: form.fontWeight,
  bgcolor: form.bgcolor,
  errors: form.errors,
  dragAreaType: form.dragAreaType,
  paddingTop:form.paddingTop,
  paddingLeft:form.paddingLeft,
  paddingBottom:form.paddingBottom,
  paddingRight:form.paddingRight,
  dropzoneBgColor: form.dropzoneBgColor,
  dropzoneOpacity: form.dropzoneOpacity,
});

//props to redux
const mapDispatchToProps = dispatch => ({
  Layout: form => dispatch(setDragandDropLayout(form)),
  HandleError: form => dispatch(handleErrors(form)),
  HandleFontFamily: form => dispatch(fontType(form)),
  HandleFontSize: form => dispatch(fontSize(form)),
  HandleFontWeight: form => dispatch(fontWeight(form)),
  BgColor: form => dispatch(setBgColor(form)),
  FontColor: form => dispatch(fontColor(form)),
  DragAreaType: data => dispatch(setDragAreaType(data)),
  SetPaddingtop: form => dispatch(paddingTop(form)),
  SetPaddingleft: form => dispatch(paddingLeft(form)),
  SetPaddingbottom: form => dispatch(paddingBottom(form)),
  SetPaddingright: form => dispatch(paddingRight(form)),
  DropzoneBgColor : form => dispatch(setDropzoneBgColor(form)),
  DropzoneOpacity: form => dispatch(setDropzoneOpacity(form)),
});


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DnDCustomize);
