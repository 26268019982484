/* eslint-disable no-undefined */
import React from "react";
import { Form, FormControl, Button, Card, Col, Accordion, InputGroup, CardDeck } from "react-bootstrap";
import {setBgColor ,backColor, height, noOfCard, radius, borderType, setBorderSize, setBorderColor, setBorderStyle, flip} from "../../redux/flipCard/form.action";
import { connect } from "react-redux";
import { SketchPicker} from "react-color";
import { flipcardValue } from "../../middleware/defaultValue";
import styles from "../../assets/customstyles";

class Customize extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      displayColorPicker: false,
      displayBackColorPicker: false,
      collapseID: "collapse1"
    };
  }
    
  // toggle the collapse ID for changing the accordion icon
  toggleCollapse(collapseID) {
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : ""
    }));
    this.setState({displayBackColorPicker: false});
    this.setState({displayColorPicker: false});
  }


  //set background color
  bgcolor = (event) => {
    const { BgColor } = this.props;
    BgColor({
      BgColor: event.target.value
    });
  }
    
  // Toggle BG color picker view
  colorSubmit = () => {
    this.setState({displayColorPicker: !this.state.displayColorPicker});
  }

  // Toggle font color picker view
  backColorSubmit = () => {
    this.setState({displayBackColorPicker: !this.state.displayBackColorPicker});
  }

  //change background color
  changeColor = (color) => {
    const { BgColor } = this.props;
    BgColor({
      bgcolor:color.hex
    });
  }

  //set background color
  setColor = (e) => {
    const { BgColor } = this.props;
    BgColor({
      bgcolor:e.target.value
    });
  }
    
  //set font color
  BackColor = (event) => {
    const { BackColor } = this.props;
    BackColor({
      backColor: event.target.value
    });
  }

  //change font color
  changeBackColor = (color) => {
    const { BackColor } = this.props;
    BackColor({
      backColor:color.hex,
    });
  }

  //set font color
  setBackColor = (event) => {
    const { BackColor } = this.props;
    BackColor({
      backColor: event.target.value
    });
  }

  Height = (event) => {
    const { Height } = this.props;
    Height({
      height: event.target.value
    });
  }

  NoOfCard = (event) => {
    const {NoOfCard} = this.props;
    NoOfCard({
      noOfCard: event.target.value
    });
  }

  radius = (event) => {
    const { Radius } = this.props;
    Radius({
      radius: event.target.value
    });
  }
  
  //set background color
  setborderColor = (event) => {
    const {BorderColor} = this.props;
    BorderColor({
      borderColor: event.target.value
    });
  }

  borderSize = (e) => {
    const { HandleBorderSize } = this.props;
    HandleBorderSize({
      borderSize: e.target.value
    });
  }

  //set font color
  borderType = (e) => {
    const { SetBorder } = this.props;
    SetBorder({
      borderType: e.target.value
    });
  }

  borderStyle = (e) => {
    const { HandleBorderStyle } = this.props;
    HandleBorderStyle({
      borderStyle: e.target.value
    });
  }
  
  //change background color
 changeborderColor = (color) => {
   const { BorderColor } = this.props;
   BorderColor({
     borderColor: color.hex
   });
 }

 flip = (event) => {
   const { Flip } = this.props;
   Flip({
     flip: event.target.value
   });
 }

 render(){
   const { collapseID } = this.state;
   const {  bgcolor, backColor, borderType, borderColor, borderSize, borderStyle } = this.props;
   
   //to set the default values in all the fields of customisation while editing the interactives which were created at earlier days
   const { id } = this.props.content.match.params;
   if(id !== undefined){flipcardValue(this.props);}
   const radius = ((this.props.radius !== "") ? this.props.radius : "rounded-0");
   const noOfCard =((this.props.noOfCard !== "") ? this.props.noOfCard : "4" );
   const flip =((this.props.flip === undefined || this.props.flip === "") ? "0" : this.props.flip);
   return(
     <React.Fragment>
       <Col sm={4} className="float-right">
         <Card>
           <Card.Header className="h4 font-weight-bold header">Customize</Card.Header>
           <Card.Body className="p-0">
             <Accordion defaultActiveKey="0">
               <Card className="border-0">
                 <Accordion.Toggle className="font-weight-bold" as={Card.Header} eventKey="0" onClick={() => this.toggleCollapse("collapse1")}>
                   <i className={collapseID === "collapse1" ? "fa fa-caret-down" : "fa fa-caret-right"}></i> Section Header
                 </Accordion.Toggle>
                 <Accordion.Collapse eventKey="0">
                   <Card.Body className="subheader_bottom">
                     <Form>
                       <Form.Group>
                         <label>Front Background Fill</label>
                         <InputGroup size="sm">
                           <FormControl
                             value={this.props.bgcolor}
                             onChange={this.setColor}
                             maxLength="7"
                           />
                           <InputGroup.Append>
                             <Button variant="outline-secondary" onClick={this.colorSubmit} style={{backgroundColor:bgcolor}}><i className="fa fa-eye-dropper"></i></Button>
                           </InputGroup.Append>
                         </InputGroup>
                         {this.state.displayColorPicker ? <SketchPicker disableAlpha color={bgcolor} onChange={ this.changeColor }></SketchPicker> : null}
                       </Form.Group>
                       <Form.Group>
                         <label>Back Background Fill</label>
                         <InputGroup size="sm">
                           <FormControl
                             value={backColor}
                             onChange={this.setBackColor}
                             maxLength="7"
                           />
                           <InputGroup.Append>
                             <Button variant="outline-secondary" onClick={this.backColorSubmit} style={{backgroundColor:backColor}}><i className="fa fa-eye-dropper"></i></Button>
                           </InputGroup.Append>
                         </InputGroup>
                         {this.state.displayBackColorPicker ? <SketchPicker disableAlpha color={backColor} onChange={ this.changeBackColor }></SketchPicker> : null}
                       </Form.Group>
                       {/* <Form.Group>
                         <label>Height</label>
                         <InputGroup size="sm">
                           <FormControl
                             placeholder="Height"
                             value={height}
                             onChange={this.Height}
                             maxLength="7"
                           />
                           <InputGroup.Prepend>
                             <InputGroup.Text>px</InputGroup.Text>
                           </InputGroup.Prepend>
                         </InputGroup>
                       </Form.Group> */}
                       <Form.Group>
                         <Form.Label>No of card in row</Form.Label>
                         <Form.Control as="select" value={noOfCard} onChange={this.NoOfCard}>
                           <option value="7">1</option>
                           <option value="6">2</option>
                           <option value="4">3</option>
                           <option value="3">4</option>
                         </Form.Control>
                       </Form.Group>
                       <Form.Group>
                         <label>Event</label>
                         <Form.Check 
                           custom
                           type="radio"
                           id="1"
                           label="Click"
                           name="event"
                           value="0"
                           onClick={this.flip.bind(this)}
                           defaultChecked={flip === "0"}
                         />
                         <Form.Check 
                           custom
                           type="radio"
                           id="2"
                           label="Hover"
                           name="event"
                           value="1"
                           onClick={this.flip.bind(this)}
                           defaultChecked={flip === "1"}
                         />
                       </Form.Group>
                     </Form>
                   </Card.Body>
                 </Accordion.Collapse>
                 <Accordion.Toggle className="font-weight-bold" as={Card.Header} eventKey="5" onClick={() => this.toggleCollapse("collapse4")}>
                   <i className={collapseID === "collapse4" ? "fa fa-caret-down" : "fa fa-caret-right"}></i> Borders
                 </Accordion.Toggle>
                 <Accordion.Collapse eventKey="5">
                   <Card.Body className="subheader_bottom">
                     <Form>
                       <label>Outline-Border</label>
                       <CardDeck className="m-1">
                         <label>
                           <input type="radio" name="btn-border" value="rounded-0" onChange={this.radius} checked={radius === "rounded-0"} className="card-input-element" />
                           <Card className="small-card card-input">
                             <span className="border border-dark"></span>
                             <small>Sharp</small>
                           </Card>
                         </label>
                         <label>
                           <input type="radio" name="btn-border" value="rounded-sm" onChange={this.radius} checked={radius === "rounded-sm"} className="card-input-element" />
                           <Card className="small-card card-input">
                             <span className="border rounded-lg border-dark"></span>
                             <small>Round</small>
                           </Card>
                         </label>
                       </CardDeck>
                       <label>Inline-Border</label>
                       <CardDeck>
                         {Object.entries(styles.borderType).map(([key,value], idx) => {
                           return (
                             <label key={idx}>
                               <input type="radio" name="border-type" value={value} onChange={this.borderType} checked={borderType === value} className="card-input-element" />
                               <Card className="small-card card-input">
                                 <span className={value} size="sm" style={{ marginTop: 15, height: 30}}>{key}</span>
                               </Card>
                             </label>
                           );
                         })}
                       </CardDeck>
                       <Form.Group>
                         <label>Border Color</label>
                         <InputGroup size="sm">
                           <FormControl
                             value={this.props.borderColor}
                             onChange={this.setborderColor}
                             maxLength="7"
                           />
                           <InputGroup.Append>
                             <Button variant="outline-secondary" onClick={this.colorSubmit} style={{ backgroundColor: borderColor }}><i className="fa fa-eye-dropper"></i></Button>
                           </InputGroup.Append>
                         </InputGroup>
                         {this.state.displayColorPicker ? <SketchPicker disableAlpha color={borderColor} onChange={this.changeborderColor}></SketchPicker> : null}
                       </Form.Group> 
                       <Form.Row>
                         <Form.Group as={Col}>
                           <Form.Label>Border Size</Form.Label>
                           <Form.Control as="select" onChange={this.borderSize} size="sm">
                             <option style={{ display: "none" }}>{(borderSize) ? borderSize : "Select"}</option>
                             {
                               styles.borderSize.map((borderlist, i) => {
                                 return <option key={i}>{borderlist}</option>;
                               })
                             }
                           </Form.Control>
                         </Form.Group>
                         <Form.Group as={Col}>
                           <Form.Label>Border Style</Form.Label>
                           <Form.Control as="select"  defaultValue={borderStyle} className="text-capitalize" onChange={this.borderStyle} size="sm">
                             <option style={{ display: "none" }}>{(borderStyle) ? borderStyle : "Select"}</option>
                             {
                               Object.entries(styles.borderStyle).map(([key,value], idx) => {
                                 return <option value = {value}  key={idx}>{key}</option>;
                               })
                             }
                           </Form.Control>
                         </Form.Group>
                       </Form.Row>
                     </Form>
                   </Card.Body>
                 </Accordion.Collapse>
               </Card>
             </Accordion>
              
           </Card.Body>
         </Card>
       </Col>
     </React.Fragment>
   );
 }
}

//state from redux
const mapStateToProps = ({ flipform }) => ({
  type: flipform.type,
  color: flipform.color,
  bgcolor: flipform.bgcolor,
  backColor: flipform.backColor,
  height: flipform.height,
  noOfCard: flipform.noOfCard,
  radius: flipform.radius,
  borderType: flipform.borderType,
  borderColor: flipform.borderColor,
  borderSize: flipform.borderSize,
  borderStyle: flipform.borderStyle,
  flip: flipform.flip,
});
  
//props to redux
const mapDispatchToProps = dispatch => ({
  BgColor: form => dispatch(setBgColor(form)),
  BackColor: form => dispatch(backColor(form)),
  Height: form => dispatch(height(form)),
  NoOfCard: form => dispatch(noOfCard(form)),
  Radius: form => dispatch(radius(form)),
  SetBorder: form => dispatch(borderType(form)),
  BorderColor: form => dispatch(setBorderColor(form)),
  HandleBorderSize: form => dispatch(setBorderSize(form)),
  HandleBorderStyle: form => dispatch(setBorderStyle(form)),
  Flip: form => dispatch(flip(form)),
});
  
//connecting form with redux
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Customize);
