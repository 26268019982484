/* eslint-disable no-undefined */
/* eslint-disable no-useless-concat */
import React, { Component } from "react";
import { Modal, Button, Carousel } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import "../../../assets/scss/hotspot.scss";
class HotspotPreview extends Component {

  constructor(props) {
    super(props);
    this.state = {
      show: false,
      label: "",
      text: null
    };
  }

  handleClose() {
    this.setState({ 
      show: false,
      label: "",
      text: ""
    });
  }

  handleShow(label, text) {
    this.setState({ 
      show: true,
      label: label,
      text: text
    });
  }
  render() {
    const { dndParameters } = this.props.content;
    const icon = this.props.content.hotspotIcon;
    return (
      <React.Fragment>     
        <Modal
          {...this.props}
          size="lg"
          dialogClassName="modal-90w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title
              id="contained-modal-title-vcenter"
              style={{ wordBreak: "break-word" }}
            >
              {this.props.content.title}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="text-center mt-2">
              <Carousel id="hotspot" controls={(dndParameters.length===1) ? false : true} indicators={false} interval={ null }>
                {
                  dndParameters.map((content, idx) => {
                    let outerElement = document.createElement("div");
                    outerElement.innerHTML = content.background;
                    let image = document.getElementsByTagName("img");
                    return (
                      <Carousel.Item key={idx}
                        eventkey={idx}>
                        <div className="hotspot" id={`hotspot_${idx}`}>
                          <img className="image-uploading" src={image[idx] ? image[idx].src : ""} />
                          {
                            content.dropZone.map((dropArea, index)=>{
                              if(image[idx]){
                                const   top = (dropArea.data.y/image[idx].height)*100 +"%",
                                  left = (dropArea.data.x/image[idx].width)*100 +"%";
                                return<div key={`drop${index}`} className="hotspotIcon" title={dropArea.data.label} style={{left: `${left}`, top: `${top}`}}
                                  onClick={this.handleShow.bind(this,dropArea.data.label, ReactHtmlParser(dropArea.data.text))}
                                  id={dropArea.data.label} >
                                  <svg xmlns="http://www.w3.org/2000/svg" width={this.props.content.fontSize} height={this.props.content.fontSize} viewBox="0 0 30 30" >
                                    <defs>
                                      <clipPath>
                                        <rect width={this.props.content.fontSize} height={this.props.content.fontSize}/>
                                      </clipPath>
                                    </defs>
                                    <g>
                                      <path d={icon.draw} 
                                        transform = { icon.iconType === "Information" ? "translate(65)" :  icon.iconType === "Question" ? "translate(120 -10)"  : ""} 
                                        fill={this.props.content.bgcolor} stroke="rgba(0,0,0,0)" strokeMiterlimit="10" strokeWidth="50"
                                      />
                                    </g>
                                  </svg>
                                </div>;
                              }
                            })
                          }
                        </div>
                      </Carousel.Item>
                    );}
                  )}
              </Carousel>
              <Modal  id="hotspotModal" show={this.state.show} onHide={this.handleClose.bind(this)} centered>
                <Modal.Header closeButton>
                  <Modal.Title>{this.state.label}</Modal.Title>
                </Modal.Header>
                <Modal.Body data-backdrop="static" data-keyboard="false" >{this.state.text}</Modal.Body>
              </Modal>
            </div>        
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="btn btn-dark btn-sm btn-pill btn btn-primary"
              onClick={this.props.onHide}
            >
              Back to Editor
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
    
  }
}

export default HotspotPreview;
