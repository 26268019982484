/* eslint-disable no-undefined */
import React from "react";
import { Redirect } from "react-router-dom";
import {
  Container,
  Card,
  Form,
  FormControl,
  Button,
  Row,
  Col,
} from "react-bootstrap";
import { connect } from "react-redux";
import swal from "sweetalert2";
import {
  setName,
  setLink,
  setTitle,
  setCheck,
  makeEdit,
  errorsFetch
} from "../../redux/button/button.actions";
import { createContent, getContent, editContent } from "../../middleware/api";
import { initializeReactGA } from "../../middleware/googleAnalytics";
import ButtonPreview from "../button/buttonPreview";
import Header from "../layouts/navbar";
import Customize from "./customize";
import { validateForm } from "./validate";
import "../form/form.scss";

let content;
class AddButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: false,
      Modelshow: false,
      displayColorPicker: false,
      displayFontColorPicker: false,
      pathName : "",
      alreadyExist: null
    };
  }

  async componentDidMount() {
    const { MakeEdit, SetType } = this.props;
    const { type, id } = this.props.match.params;

    this.setState({pathName: this.props.location.pathname});

    initializeReactGA(this.props.location.pathname);

    if (type) {
      SetType({
        type: type,
      });
    }
    if (id) {
      if (
        this.props.match.params.user === undefined ||
        this.props.match.params.user === ""
      ) {
        content = sessionStorage.getItem("user_id");
      } else {
        content = this.props.match.params.user;
      }

      const org_id = sessionStorage.getItem("org_id");
      if(this.props.match.params.orgId !== undefined && (org_id !== undefined || org_id !== "") && this.props.match.params.orgId !== org_id){
        sessionStorage.setItem("org_id", this.props.match.params.orgId);
      }
      
      let data = await getContent(id, content);
      if (data.message === "Unauthorized User or Invalid Content")
        return this.props.history.push("/401");
      let parameters = {
        title: data.title,
        name: data.parameters.name,
        link: data.parameters.link,
        isChecked: data.parameters.isChecked,
      };
      this.setState({alreadyExist: this.state.pathName.includes("duplicate") ? "* DUPLICATED INTERACTIVE - Please enter a new title" : null});
      MakeEdit({
        title:this.state.pathName.includes("duplicate") ? "" : parameters.title,
        name: parameters.name,
        link: parameters.link,
        isChecked: parameters.isChecked,
        color: data.customisations.bgColor,
        btnType: data.customisations.btnType,
        fontFamily: data.customisations.fontFamily,
        fontSize: data.customisations.fontSize !== "" ? data.customisations.fontSize : "16",
        alignment: data.customisations.alignment,
        fontColor: data.customisations.fontColor,
        customPaddingtop: data.customisations.customPaddingtop,
        customPaddingleft: data.customisations.customPaddingleft,
        customPaddingbottom: data.customisations.customPaddingbottom,
        customPaddingright: data.customisations.customPaddingright
      });
    }
  }

  //customisation togglebox
  toggleBox() {
    const { opened } = this.state;
    this.setState({
      opened: !opened,
      collapseID: "collapse1",
    });
  }

  //To fetch checkbox value
  handleChecked = () => {
    const { isChecked, SetCheck } = this.props;
    SetCheck({
      isChecked: !isChecked,
    });
  };

  //to fetch the button title value
  handleTitle = e => {
    const { errors, SetTitle } = this.props;
    const { value } = e.target;
    let fail = errors;
    delete fail["title"];
    if (this.state.pathName.includes("/duplicate")){
      let data = this.props.location.state.contentList.filter(function(el) { return el.title === e.target.value;});
      this.setState({ 
        alreadyExist :  value === "" ? "* DUPLICATED INTERACTIVE - Please enter a new title" : data.length !== 0 ? 
          "* This title already exists. Please enter a new title" :null 
      });
    }
    if (value.length < 100) {
      SetTitle({
        title: !value.trim() ? "" : value ,
        errors: fail,
      });
    }
  };

  //to fetch the button link value
  handleLink = e => {
    const { errors, SetLink } = this.props;
    const { value } = e.target;
    let fail = errors;
    delete fail["link"];

    SetLink({
      link: value,
      errors: fail,
    });
  };

  //to fetch the button name value
  handleName = e => {
    const { errors, SetName } = this.props;
    const { value } = e.target;
    let fail = errors;
    delete fail["name"];
    if (value.length < 100) {

      SetName({
        name: !value.trim() ? "" : value ,
        errors: fail,
      });
    }
  };

  //To Create and Update funtionality for button component
  handleSubmit = async event => {
    const {
      title,
      link,
      name,
      isChecked,
      color,
      btnType,
      ErrorsFetch,
      fontFamily,
      fontSize,
      fontColor,
      alignment,
      customPaddingtop,
      customPaddingleft,
      customPaddingbottom,
      customPaddingright
    } = this.props;
    let id = this.props.match.params;
    let response = {};
    let user_id;
    if (
      this.props.match.params.user === undefined ||
      this.props.match.params.user === ""
    ) {
      user_id = sessionStorage.getItem("user_id");
    } else {
      user_id = this.props.match.params.user;
    }
    event.preventDefault();
    const fail = validateForm(title, link, name, fontSize, ErrorsFetch, this.state.pathName);
    let dupicatetitle = fail.title === "* DUPLICATED INTERACTIVE - Please enter a new title"  ? delete fail["title"]  : null; 
    if (Object.keys(fail).length === 0 && this.state.alreadyExist === null) {
      let button = {
        user_id: user_id,
        library_id: 3,
        organization_id: sessionStorage.getItem("org_id"),
        title,
        parameters: {
          name,
          link,
          isChecked,
        },
        customisations: {
          bgColor: color,
          btnType: btnType,
          fontFamily: fontFamily,
          fontSize: fontSize,
          fontColor: fontColor,
          alignment: alignment,
          customPaddingtop:customPaddingtop !== "" ? customPaddingtop : "0",
          customPaddingleft:customPaddingleft !== "" ? customPaddingleft : "0",
          customPaddingbottom:customPaddingbottom !== "" ? customPaddingbottom : "0",
          customPaddingright:customPaddingright !== "" ? customPaddingright : "0"
        },
        active: true,
        private: true
      };
      const data = JSON.parse(JSON.stringify(button));

      if (id && id.id) {
        const { pathName } = this.state;
        if (pathName.includes("duplicate") && dupicatetitle === null) {
          response = await createContent(data);
          swal.fire({
            allowOutsideClick: false,
            title: "Duplicated",
            text: "Content Duplicated Successfully.",
            icon: "success",
            showConfirmButton: true
          });
          this.props.history.push({
            pathname: `/contentview/button/${this.props.match.params.user}/${response.id}`,
            state: {
              user: this.props.match.params.orgId,
              user_id: this.props.match.params.user
            }
          });
        } else {
          response = id;
          id = response.id;
          await editContent({
            id,
            body: data,
          });
          swal.fire({
            allowOutsideClick: false,
            title: "Updated",
            text: "Content updated Successfully.",
            icon: "success",
            showConfirmButton: true,
          });
          if (
            this.props.match.params.user === undefined ||
          this.props.match.params.user === ""
          ) {
            this.props.history.push({
              pathname: `/create/button/${response.id}`,
              state: {
                user:
                this.props.location.state !== undefined
                  ? this.props.location.state.user
                  : "",
                user_id:
                this.props.location.state !== undefined
                  ? this.props.location.state.user_id
                  : "",
              },
            });
          } else {
            this.props.history.push({
              pathname: `/contentview/button/${this.props.match.params.user}/${response.id}`,
              state: {
                user:
                this.props.location.state !== undefined
                  ? this.props.location.state.user
                  : "",
                user_id:
                this.props.location.state !== undefined
                  ? this.props.location.state.user_id
                  : "",
              },
            });
          }
        }
      } else {
        response = await createContent(data);
        this.props.history.push({
          pathname: `/create/button/${response.id}`,
          state: {
            user:
              this.props.location.state !== undefined
                ? this.props.location.state.user
                : "",
            user_id:
              this.props.location.state !== undefined
                ? this.props.location.state.user_id
                : "",
          },
        });
      }
    }
  };

  //toogle button for show the preview page
  modalToggle = event => {
    const { title, link, name, fontSize, ErrorsFetch } = this.props;
    event.preventDefault();
    const fail = validateForm(title, link, name, fontSize, ErrorsFetch, this.state.pathName);
    if (Object.keys(fail).length === 0) {
      this.setState({ Modelshow: true });
    }
  };

  //functionality for Modalclose
  Modelclose = () => {
    this.setState({ Modelshow: false });
  };

  render() {
    //user view area input form and customisation
    const { opened } = this.state;
    let { title, name, link, errors } = this.props;
    let { id } = this.props.match.params;
    let pathName = this.props.location.pathname;

    //To display the customise and preview button based on the version
    let loreeVersion = sessionStorage.getItem("loreeVersion");
    let rowClass = (loreeVersion === "1") ? "flex-row-reverse" : "flex-row";
   
    if(id){
     
      //To display the customise and preview button based on the version
      if (loreeVersion === null) {
        sessionStorage.setItem("loreeVersion", this.props.match.params.version);
        loreeVersion = sessionStorage.getItem("loreeVersion");
        rowClass = (loreeVersion === "1") ? "flex-row-reverse" : "flex-row";
      } 

      // to find the parent tab to restrict the user to access interactive outside loree
      if(window.location !== window.parent.location){
        const user_id = localStorage.getItem("user_id");
        sessionStorage.setItem("user_id", user_id);
      }
    }
    
    // eslint-disable-next-line no-undefined
    if (sessionStorage.getItem("user_id") === null) return <Redirect to="/401" />;
    else {
      return (
        <React.Fragment>
          <Header
            user={localStorage.getItem("user")}
            user_id={sessionStorage.getItem("user_id")}
            data={this.props}
          />
          <Container className="margin">
            <div className={`d-flex ${rowClass} my-3`}>
              <Button
                className="m-1 btn-pill mw-100"
                onClick={this.toggleBox.bind(this)}
                size="sm"
                variant="dark"
              >
                Customize{" "}
                <i className={!opened ? "fa fa-plus" : "fa fa-minus"}></i>
              </Button>
              <Button
                className="m-1 btn-pill mw-100"
                onClick={this.modalToggle}
                size="sm"
                variant="light"
              >
                Preview
              </Button>
              <ButtonPreview
                content={this.props}
                show={this.state.Modelshow}
                onHide={this.Modelclose}
              />
            </div>
            <Row>
              {opened && (loreeVersion === "2") && (
                <Customize content={this.props} />
              )}
              <Col>
                <Card>
                  <Card.Header className="h4 font-weight-bold">
                    Interactive Content Builder <span className="h4"> | </span>
                    <span style={{ color: "#0B099C" }}>Button</span>
                  </Card.Header>
                  <Card.Body>
                    <Form>
                      <Form.Group controlId="formBasictitle">
                        <Form.Label>Title</Form.Label>
                        <FormControl
                          type="text"
                          placeholder="Enter Title"
                          name="title"
                          value={title}
                          onChange={this.handleTitle.bind(this)}
                        />
                        {pathName.includes("/duplicate") && this.state.alreadyExist ? <Form.Text className="text-danger">{this.state.alreadyExist}</Form.Text> : null}
                        {errors.title ? (
                          <small className="text-danger">{errors.title}</small>
                        ) : null}
                        <Form.Text className="text-muted">
                          For admin use only, not visible to students (Character limit of 100 characters applies)
                        </Form.Text>
                      </Form.Group>
                      <Form.Group controlId="formBasicname">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter button name"
                          value={name}
                          onChange={this.handleName.bind(this)}
                        />
                        {errors.name ? (
                          <small className="text-danger">{errors.name}</small>
                        ) : null}
                        <Form.Text className="text-muted">
                          Text that display in button (Character limit of 100 characters applies)
                        </Form.Text>
                      </Form.Group>
                      <Form.Group controlId="formBasiclink">
                        <Form.Label>Link</Form.Label>
                        <Form.Control
                          type="url"
                          pattern="https?://.+"
                          placeholder="Enter link"
                          value={link}
                          onChange={this.handleLink.bind(this)}
                          required
                        />
                        {errors.link ? (
                          <small className="text-danger">{errors.link}</small>
                        ) : null}
                        <Form.Text className="text-muted">
                          URL needs to be in format : https://www.example.com
                        </Form.Text>
                      </Form.Group>
                      <Form.Group controlId="formBasicCheckbox">
                        <Form.Check
                          type="checkbox"
                          label="Open in new Tab"
                          checked={this.props.isChecked}
                          onChange={this.handleChecked.bind(this)}
                        />
                        <Form.Text className="text-muted">
                          Uncheck this for open button link in same browser tab.
                        </Form.Text>
                      </Form.Group>
                    </Form>
                  </Card.Body>
                  <Card.Footer>
                    <Button
                      className="btn btn-dark btn-sm btn-pill"
                      type="submit"
                      onClick={this.handleSubmit.bind(this)}
                    >
                      {(id && pathName.includes("/duplicate")) ? "Create" : id ? "Update" :"Create"}
                    </Button>
                  </Card.Footer>
                </Card>
              </Col>
              {opened && (loreeVersion === "1") && (
                <Customize content={this.props} />
              )}
            </Row>
          </Container>
        </React.Fragment>
      );
    }
  }
}

//state from redux
const mapStateToProps = ({ button }) => ({
  title: button.title,
  name: button.name,
  link: button.link,
  isChecked: button.isChecked,
  errors: button.errors,
  color: button.color,
  btnType: button.btnType,
  fontFamily: button.fontFamily,
  fontSize: button.fontSize,
  alignment: button.alignment,
  fontColor: button.fontColor,
  customPaddingtop:button.customPaddingtop,
  customPaddingleft:button.customPaddingleft,
  customPaddingbottom:button.customPaddingbottom,
  customPaddingright:button.customPaddingright
});

//props from redux
const mapDispatchToProps = dispatch => ({
  SetTitle: data => dispatch(setTitle(data)),
  SetName: data => dispatch(setName(data)),
  SetLink: data => dispatch(setLink(data)),
  SetCheck: data => dispatch(setCheck(data)),
  MakeEdit: data => dispatch(makeEdit(data)),
  ErrorsFetch: data => dispatch(errorsFetch(data))
});

//connecting from with redux
export default connect(mapStateToProps, mapDispatchToProps)(AddButton);
