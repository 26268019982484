import { combineReducers } from "redux";
import formReducer from "./form/form.reducer";
import componentReducer from "./components/component.reducer";
import buttonReducer from "./button/button.reducer";
import mcqReducer from "../redux/multipleChoice/mcq.reducer";
import flipReducer from "../redux/flipCard/form.reducer";

export default combineReducers({
  //combined reducers with actions
  form: formReducer,
  component: componentReducer,
  button:buttonReducer,
  mcqform: mcqReducer,
  flipform: flipReducer,
});
