export const validateForm = (parameters, title, ErrorsFetch, contentArr, pathName) => {
  let values, fail = {};
  parameters.forEach((parameters_el, parameters_index) => {
    contentArr.forEach((contentArr_el, contentArr_index) => {
      if (parameters_index === contentArr_index) {
        values = Object.values(parameters_el);
        if (values[0] === "") fail[contentArr_el[0]] = "* Section Header is required";
        if (values[1] === "") fail[contentArr_el[1]] = "* Section Body is required";
        if (values[2] === "") fail[contentArr_el[2]] = "* Section Body is required";
      }
    });
  });
  if (title === "") {
    fail["title"] = pathName.includes("/duplicate") ? "* DUPLICATED INTERACTIVE - Please enter a new title":  "* Interactive Title is required";
  }

  ErrorsFetch({
    errors: fail
  });

  return fail;
};
