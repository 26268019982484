/* eslint-disable react/jsx-key */
/* eslint-disable no-useless-concat */
import React from "react";
import ReactHtmlParser from "react-html-parser";
import { Modal, Button } from "react-bootstrap";
import "./flipcard.scss";

class flipcardPreview extends React.Component {

  flip = (idx) => {
    var element = document.getElementById(`flip-${idx}`);
    element.classList.toggle("flipped");
  }

  render() { //preview page render for flipcard using modal


    const borderleftStyle = (this.props.content.borderType === "border-left border-dark") ? true : false;

    const borderrightStyle = (this.props.content.borderType === "border-right border-dark") ? true : false;

    const bordertopStyle = (this.props.content.borderType === "border-top border-dark") ? true : false;  

    const borderbottomStyle = (this.props.content.borderType === "border-bottom border-dark") ? true : false;  

    const borderfullStyle = (this.props.content.borderType === "border border-dark") ? true : false; 

    const flipcardborderleftStyle = {
      borderLeft: `${this.props.content.borderSize}px ` + `${this.props.content.borderStyle} ` + `${this.props.content.borderColor}`
    };

    const flipcardborderrightStyle = {
      borderRight: `${this.props.content.borderSize}px ` + `${this.props.content.borderStyle} ` + `${this.props.content.borderColor}`
    };

    const flipcardbordertopStyle = {
      borderTop: `${this.props.content.borderSize}px ` + `${this.props.content.borderStyle} ` + `${this.props.content.borderColor}`
    };

    const flipcardborderbottomtStyle = {
      borderBottom: `${this.props.content.borderSize}px ` + `${this.props.content.borderStyle} ` + `${this.props.content.borderColor}`
    };

    const flipcardborderfullStyle = {
      border: `${this.props.content.borderSize}px ` + `${this.props.content.borderStyle} ` + `${this.props.content.borderColor}`
    };
    
    const noofcard= this.props.content.noOfCard === "" ? 4 : this.props.content.noOfCard;

    const frontcolor = {
      background : this.props.content.bgcolor,
      height: this.props.content.height + "px"
    };

    const flipcolor = {
      background : this.props.content.backColor,
      height: this.props.content.height + "px"
    };

    return (
      <div className="m-2">
        <Modal
          {...this.props}
          size="lg"
          dialogClassName="modal-90w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter" style={{wordBreak:"break-word"}}>
              {this.props.content.title}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <React.Fragment>
              <div className="container">
                { this.props.content.flip === "1" ? 
                  <div className="row">
                    {
                      this.props.content.parameters.map((content, idx) => (
                        <div className={`my-3 flip-card col-sm-${noofcard}`} key={idx}>
                          <div className={`flip-card-inner ${this.props.content.radius}`}>
                            <div className={`flip-card-front shadow ${this.props.content.radius} overflow-auto`} 
                              style = { (borderleftStyle) ? {...flipcardborderleftStyle,...frontcolor} : (borderrightStyle) ? 
                                {...flipcardborderrightStyle,...frontcolor} : (bordertopStyle) ? {...flipcardbordertopStyle,...frontcolor} : 
                                  (borderbottomStyle) ? {...flipcardborderbottomtStyle,...frontcolor} : (borderfullStyle) ? 
                                    {...flipcardborderfullStyle,...frontcolor} : frontcolor}>
                              {ReactHtmlParser(content["fronttext"])}
                            </div>
                            <div className={`flip-card-back shadow ${this.props.content.radius} overflow-auto`} style = { (borderleftStyle) ? {...flipcardborderleftStyle,...flipcolor} : (borderrightStyle) ? 
                              {...flipcardborderrightStyle,...flipcolor} : (bordertopStyle) ? {...flipcardbordertopStyle,...flipcolor} : 
                                (borderbottomStyle) ? {...flipcardborderbottomtStyle,...flipcolor} : (borderfullStyle) ? 
                                  {...flipcardborderfullStyle,...flipcolor} : flipcolor}>
                              {ReactHtmlParser(content["backtext"])}
                            </div>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                  :
                  <div className="row">
                    {
                      this.props.content.parameters.map((content, idx) => (
                        <div className={`my-3 flip col-sm-${noofcard}`} key={idx}>
                          <div id={`flip-${idx}`} className={`inner ${this.props.content.radius}`} onClick={this.flip.bind(this,idx)} > 
                            <div className={`shadow front ${this.props.content.radius}`}
                              style = { (borderleftStyle) ? {...flipcardborderleftStyle,...frontcolor} : (borderrightStyle) ? 
                                {...flipcardborderrightStyle,...frontcolor} : (bordertopStyle) ? {...flipcardbordertopStyle,...frontcolor} : 
                                  (borderbottomStyle) ? {...flipcardborderbottomtStyle,...frontcolor} : (borderfullStyle) ? 
                                    {...flipcardborderfullStyle,...frontcolor} : frontcolor}> 
                              {ReactHtmlParser(content["fronttext"])}
                            </div> 
                            <div className={`shadow back ${this.props.content.radius}`}  
                              style = { (borderleftStyle) ? {...flipcardborderleftStyle,...flipcolor} : (borderrightStyle) ? 
                                {...flipcardborderrightStyle,...flipcolor} : (bordertopStyle) ? {...flipcardbordertopStyle,...flipcolor} : 
                                  (borderbottomStyle) ? {...flipcardborderbottomtStyle,...flipcolor} : (borderfullStyle) ? 
                                    {...flipcardborderfullStyle,...flipcolor} : flipcolor}> 
                              {ReactHtmlParser(content["backtext"])}
                            </div>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                }
              </div>
            </React.Fragment>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="btn btn-dark btn-sm btn-pill btn btn-primary" onClick={this.props.onHide}>Back to Editor</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default flipcardPreview;
