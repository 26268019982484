/* eslint-disable no-useless-concat */
import React from "react";
import ReactHtmlParser from "react-html-parser";
import { Card, Accordion, Modal, Button } from "react-bootstrap";

class AccordionPreview extends React.Component {

  //Customisation functionality for plus/minus else up-arrow/down-arrow icon
  iconClick = (e) => {
    const collapsed = e.target.parentNode.children[1].className === "collapse";
    if (this.props.content.icon === "plus/minus") {
      for (const x of e.target.parentNode.parentNode.children) {
        x.children[0].className = x.children[0].className.replace("icon-minus", "icon-plus");
      }
      if (collapsed) {
        e.target.className = e.target.className.replace("icon-plus", "icon-minus");
      }
      else {
        e.target.className = e.target.className.replace("icon-minus", "icon-plus");
      }

    }
    else {
      for (const x of e.target.parentNode.parentNode.children) {
        x.children[0].className = x.children[0].className.replace("icon-down-arrow", "icon-right-arrow");
      }
      if (collapsed) {
        e.target.className = e.target.className.replace("icon-right-arrow", "icon-down-arrow");
      }
      else {
        e.target.className = e.target.className.replace("icon-down-arrow", "icon-right-arrow");
      }
    }
  }
  
  render() { //preview page render for accordion using modal
    const fontHeadings = ((this.props.content.fontHeadings !== "" ) ? this.props.content.fontHeadings : "div");
    const accordionPreviewstyle = {
      fontFamily: `${this.props.content.fontFamily}`, 
      backgroundColor: `${this.props.content.bgcolor}`, 
      fontWeight: `${this.props.content.fontWeight}`, 
      fontSize: `${this.props.content.fontSize}px`, 
      color: `${this.props.content.fontColor}`, 
      textAlign: `${this.props.content.alignment}`
    };

    const borderleftStyle = (this.props.content.borderType === "border-left border-dark") ? true : false;

    const borderrightStyle = (this.props.content.borderType === "border-right border-dark") ? true : false;

    const bordertopStyle = (this.props.content.borderType === "border-top border-dark") ? true : false;  

    const borderbottomStyle = (this.props.content.borderType === "border-bottom border-dark") ? true : false;  

    const borderfullStyle = (this.props.content.borderType === "border border-dark") ? true : false; 

    const accordionborderleftStyle = {
      borderLeft: `${this.props.content.borderSize}px ` + `${this.props.content.borderStyle} ` + `${this.props.content.borderColor}`
    };

    const accordionborderrightStyle = {
      borderRight: `${this.props.content.borderSize}px ` + `${this.props.content.borderStyle} ` + `${this.props.content.borderColor}`
    };

    const accordionbordertopStyle = {
      borderTop: `${this.props.content.borderSize}px ` + `${this.props.content.borderStyle} ` + `${this.props.content.borderColor}`
    };

    const accordionborderbottomtStyle = {
      borderBottom: `${this.props.content.borderSize}px ` + `${this.props.content.borderStyle} ` + `${this.props.content.borderColor}`
    };

    const accordionborderfullStyle = {
      border: `${this.props.content.borderSize}px ` + `${this.props.content.borderStyle} ` + `${this.props.content.borderColor}`
    };

    return (
      <div className="m-2">
        <Modal
          {...this.props}
          size="lg"
          dialogClassName="modal-90w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter" style={{wordBreak:"break-word"}}>
              {this.props.content.title}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <React.Fragment>
              <Accordion>
                {
                  this.props.content.parameters.map((content, idx) => (
                    <Card key={idx} className={this.props.content.radius}>
                      <Accordion.Toggle as={fontHeadings}
                        style={accordionPreviewstyle} 
                        className={`card-header ${(this.props.content.icon === "plus/minus" ? "icon-plus" : "icon-right-arrow")}`} 
                        onClick={this.iconClick} id={idx} eventKey={idx}>
                        {content["title"]}
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey={idx} style = {borderleftStyle ? accordionborderleftStyle : (borderrightStyle) ? accordionborderrightStyle : (bordertopStyle) ? accordionbordertopStyle : (borderbottomStyle) ? accordionborderbottomtStyle : (borderfullStyle) ? accordionborderfullStyle : null }>
                        <Card.Body className="ql-editor" border="primary">{ReactHtmlParser(content["text"])}</Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  ))
                }
              </Accordion>
            </React.Fragment>
          </Modal.Body>
          <Modal.Footer>
            <Button variant= "btn btn-dark btn-sm btn-pill btn btn-primary" onClick={this.props.onHide}>Back to Editor</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default AccordionPreview;
