/* eslint-disable no-useless-concat */
import React from "react";
import ReactHtmlParser from "react-html-parser";
import { Row, Modal, Button, Container, Toast } from "react-bootstrap";
import Carousel from "./carouselView";
import "../../../assets/scss/dragAndDrop.scss";

let score = 0;
class AccordionPreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkAnswered:false,
      getResult: false,
      tryAgain: false,
      finalResults: false,
      score: 0,
      index:0,
      next: false,
      check: true,
      hover: false
    };
  }

  componentDidUpdate(nextprops){
    if (this.props !== nextprops) {
      this.setState({
        finalResults:false,
        getResult: false,
        tryAgain: false,
        score: 0,
        check: true,
        next: false,
        index: 0
      });
    }
  }

  onDragStart = (event) => {
    const {content} = this.props;
    event.dataTransfer.setData("text", event.target.id);
    const layout = (content.dndLayout === "Fixed") ? "droptarget" : "dropZone";
    const dropArea = document.getElementsByClassName(layout);
    Array.from(dropArea).forEach((element) => {
      element.style.border = "2px dashed grey";
    });
  }
  onDragOver = (event) => {
    event.preventDefault();
  };

  onDragEnter = (event) => {
    if(event.target.type !== "button"){event.target.style.border = "3px dashed grey";}
  };

  onDragLeave = (event) => {
    event.target.style.border = "";
  };

  onDrop = (event) => {
    const { content } = this.props;
    event.preventDefault();
    const layout = (content.dndLayout === "Fixed") ? "droptarget" : "dropZone";
    const dropArea = document.getElementsByClassName(layout);
    Array.from(dropArea).forEach((element) => {
      element.style.border = "";
    });
    let target = event.target;
    let btnsHolder = document.getElementById("buttonsHolder");
    let currentBtn;
    var data = event.dataTransfer.getData("text");
    
    //check if there's already a button inside
    if (target.children.length > 0){
      currentBtn = target.children[0];
      btnsHolder.appendChild(currentBtn);
    }
    if(target !== document.getElementById(data) && target.type !== "button") {
      target.appendChild(document.getElementById(data));
      event.target.style.border = "";
    }
  }

  checkAnswer = () => {
    const { content } = this.props;
    let dropValues = [], dragArea = content.dndParameters[this.state.index].dropZone ;
    
    if(content.dndLayout === "CustomOverlay"){
      dragArea.map((element)=>{
        let component = document.getElementById(element.data.text);
        let innerText = component.textContent;
        if(innerText === ""){
          this.setState({checkAnswered:true});
          component.style.border = "3px dotted #9dd8bb";
        }
        else {
          dropValues.push(innerText);
        }
        return null;
      });
      if(dropValues.length === dragArea.length){
        this.checkCorrectAnswer();
      }
    }else{
      content.parameters.map((data)=>{
        let component = document.getElementById(data.title);
        let innerText = component.textContent;
        if(innerText === ""){
          this.setState({checkAnswered:true});
          component.style.border = "3px dotted #9dd8bb";
        }
        else {
          dropValues.push(innerText);
        }
        return null;
      });
      if(dropValues.length === content.parameters.length){
        this.checkCorrectAnswer();
      }
    }
  }
  
  checkCorrectAnswer = () => {
    const {content} = this.props;
    let correct = [], wrong = [], dragArea=content.dndParameters[this.state.index].dropZone;
    const element = document.getElementById("dragdrop");
    element.style.pointerEvents = "none";
    const droptarget = document.getElementById("buttonsHolder");
    droptarget.style.border = "none";
  
    // To check the correct Answer for CustomOverlay Layout
    if(content.dndLayout === "CustomOverlay"){
      dragArea.map((element)=>{
        let component = document.getElementById(element.data.text);
        let innerText = component.textContent;
        if(innerText === component.id){
          correct.push(innerText);
          let icon = `<i class="fas fa-check ${content.dndLayout === "Fixed" ? "fixed-tick-circle": "overlay-tick-circle"}"></i>`;
          component.children[0].style.background="#9dd8bb";
          component.children[0].innerHTML += icon;
        } else{
          wrong.push(innerText);
          let icon = `<i class="fas fa-times ${content.dndLayout === "Fixed" ? "fixed-cross-circle" : "overlay-cross-circle"}"></i>`;
          component.children[0].style.background="#f7d0d0";
          component.children[0].innerHTML += icon;          
        }
        return null;
      });
      // score calculation
      let currentScore = (correct.length/dragArea.length * 100).toFixed();
      score = score + parseInt(currentScore);
      if(this.state.index+1 === content.dndParameters.length){
        let finalScore =( score/content.dndParameters.length).toFixed();
        finalScore = parseInt(finalScore);
        this.setState({getResult: true, check: false, score: finalScore});
      }
      else{this.setState({next:true, check: false});}
    }else{
      content.parameters.map((data)=>{
        let component = document.getElementById(data.title);
        let innerText = component.textContent;
        if(innerText === data.title){
          correct.push(data.title);
          let icon = `<i class="fas fa-check ${content.dndLayout === "Fixed" ? "fixed-tick-circle": "overlay-tick-circle"}"></i>`;
          if(content.dndLayout === "Fixed") {
            component.style.background="#9dd8bb";
            component.innerHTML += icon;
            component.children[0].classList.add("viewAnswers");
          }else {
            component.children[0].style.background = "#9dd8bb";
            component.children[0].classList.add("position-absolute");
            component.children[0].innerHTML += icon;
          }  
        } else{
          wrong.push(innerText);
          let icon = `<i class="fas fa-times ${content.dndLayout === "Fixed" ? "fixed-cross-circle" : "overlay-cross-circle"}"></i>`;
          if(content.dndLayout === "Fixed") {
            component.style.background="#f7d0d0";
            component.innerHTML += icon;
            component.children[0].classList.add("viewAnswers");
          } else {
            component.children[0].style.background = "#f7d0d0";
            component.children[0].classList.add("position-absolute");
            component.children[0].innerHTML += icon;
          }
        }
        return null;
      });
      let score = (correct.length/content.parameters.length * 100).toFixed();
      score = parseInt(score);
      this.setState({score: score, check:false,  getResult: true});
    }
  }
  
  getResult = () => {
    if(this.state.score === 100){
      this.setState({finalResults: true});
    }else{
      score = 0;
      this.setState({tryAgain: true});
    }
  }
  
  tryAgain = () => {
    this.setState({
      index: 0,
      tryAgain: false,
      getResult: false,
      finalResults: false,
      check: true
    });
  }

  hoverOn=(id)=>{
    let element = document.getElementById(id);
    element.style.background = this.props.content.bgcolor;
    element.style.color = this.props.content.fontColor;
    this.setState({ hover: true });
  }

  //mouse leave from button
  hoverOff=(id)=>{ 
    let element = document.getElementById(id);
    element.style.background = "white";
    element.style.color = "#000000";
    this.setState({ hover: false });    
  }

  // move to next slide functionality
  nextSlide = () =>{
    const element = document.getElementById("dragdrop");
    const droptarget = document.getElementById("buttonsHolder");
    element.removeAttribute("style");
    droptarget.removeAttribute("style");
    this.setState({ 
      index: this.state.index+1,
      next: false,
      check: true
    });
  }

  render() {
    //preview page render for accordion using modal
    const { content, data } = this.props;
    const { getResult, tryAgain, score, finalResults, check, next } = this.state;
    const mouseover= this.props.content.dragAreaType === "btn btn-outline-dark" ? true : false;
    const buttonStyle = {
      background:`${content.bgcolor}`, 
      fontSize: `${content.fontSize}px`,
      border:"2px solid"+`${content.bgcolor}`,
      fontFamily: `${content.fontFamily}`, 
      color: `${content.fontColor}`,
      paddingTop: (content.paddingTop === "") ? 0 : `${content.paddingTop}px`,
      paddingLeft: (content.paddingLeft === "") ? 0 : `${content.paddingLeft}px`,
      paddingBottom: (content.paddingBottom === "") ? 0 : `${content.paddingBottom}px`,
      paddingRight: (content.paddingRight === "") ? 0 : `${content.paddingRight}px`
    };

    const buttonUnhover ={
      background: "white",
      fontSize: `${content.fontSize}px`,
      border:"2px solid"+`${content.bgcolor}`,
      fontFamily: `${content.fontFamily}`, 
      color: "#000000",
      paddingTop: (content.paddingTop === "") ? 0 : `${content.paddingTop}px`,
      paddingLeft: (content.paddingLeft === "") ? 0 : `${content.paddingLeft}px`,
      paddingBottom: (content.paddingBottom === "") ? 0 : `${content.paddingBottom}px`,
      paddingRight: (content.paddingRight === "") ? 0 : `${content.paddingRight}px`
    };

    return (
      <div className="m-2">
        <Modal
          {...this.props}
          size="lg"
          dialogClassName="modal-90w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title
              id="contained-modal-title-vcenter"
              style={{ wordBreak: "break-word" }}
            >
              {this.props.content.title}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <React.Fragment>
              <Toast
                onClose={() => this.setState({ checkAnswered: false })}
                show={this.state.checkAnswered}
                delay={5000}
                autohide
                className="toaster-alert"
              >
                <Toast.Header>
                  <strong className="mr-auto">
                    Please fill the all answers
                  </strong>
                </Toast.Header>
              </Toast>
              <Container>
                {(tryAgain === true) ?
                  <Row>
                    <div className="prevTryAgain container col-md-12">
       You have scored {score}%
                      <br/>
                      <Button className="mt-3" onClick={this.tryAgain}>Try again <i className="fas fa-redo"></i></Button>
                    </div> 
                  </Row>: 
                  (finalResults === true) ?
                    <div className="preview">
                  Congratulations
                      <br/>
                  You have Scored 100%
                    </div> :
                    <>
                      {(content.dndLayout !== "CustomOverlay") ? 
                        <div id = "dragdrop">
                          <Row>
                            {
                              (content.dndLayout === "Fixed") ?
                                content.parameters.map((content,idx) => (
                                  <div className="col-md-3 mt-2 drag" key={`img_${idx}`}>
                                    {ReactHtmlParser(content["text"])}
                                    <div id={content["title"]} className="droptarget mt-n2" onDrop={this.onDrop} onDragOver={this.onDragOver} 
                                      onDragEnter={this.onDragEnter} ></div>
                                  </div>
                                )) :
                                data.imagesrc.map((content,idx) => (
                                  <div id={content.title} key={`img_${idx}`}  
                                    style={{backgroundImage:`url(${content.src})`, backgroundSize:"contain", backgroundRepeat:"no-repeat"}} 
                                    className="dropZone col-md-2 m-2" onDrop={this.onDrop} onDragOver={this.onDragOver} onDragEnter={this.onDragEnter} 
                                  >
                                  </div>
                                ))
                            }
                          </Row>
                          <div className={`row ${content.dndLayout === "Fixed" ? "mt-4" : "col-md-11 mt-4"}`} id="buttonsHolder" 
                            onDrop={this.onDrop} onDragOver={this.onDragOver} onDragEnter={this.onDragEnter} 
                            onDragLeave={this.onDragLeave}>
                            {data.dragText.map((content,idx) => (
                              mouseover ? <Button type="button" className={`${this.props.content.dragAreaType} drop-btn mr-2`} 
                                style={buttonUnhover}  key={`key_${idx}`} id={`key_${idx}`} 
                                draggable="true" onDragStart={this.onDragStart} onMouseEnter={this.hoverOn.bind(this,`key_${idx}`)} 
                                onMouseLeave={this.hoverOff.bind(this,`key_${idx}`)} 
                              >{content}</Button>:
                                <Button type="button" key={`key_${idx}`} id={`key_${idx}`}  className={`${this.props.content.dragAreaType} drop-btn mr-2`} 
                                  style={buttonStyle} draggable="true" onDragStart={this.onDragStart} >{content}
                                </Button>
                            ))}
                          </div>
                        </div> :
                        <div id = "dragdrop" className="text-center">
                          <Carousel idx={this.state.index} length={content.dndParameters.length} data ={content.dndParameters[this.state.index]} events={{hoverOn: this.hoverOn, hoverOff: this.hoverOff}}
                            customisations={{dragAreaType: this.props.content.dragAreaType, buttonStyle: buttonStyle, buttonUnhover: buttonUnhover, 
                              mouseover: mouseover, dropZoneBgColor: this.props.content.dropzoneBgColor, dropZoneOpacity: this.props.content.dropzoneOpacity}}/>
                        </div>
                      }
                      {(check ? <Button id = "checkAnswer" className="mt-5" onClick={this.checkAnswer}>Check Answer</Button> : "")}
                      {(next === true)?
                        <div id = "next">
                          <Button id = "checkAnswer" className="mt-5" onClick={this.nextSlide}>Next</Button>
                        </div> : ""
                      }
                      {(getResult === true)?
                        <div id = "tryAgain">
                          <Button className="mt-5 results" onClick={this.getResult}>Get Results </Button>
                        </div> : ""
                      }
                    </>
                }
              </Container>
            </React.Fragment>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="btn btn-dark btn-sm btn-pill btn btn-primary"
              onClick={this.props.onHide}
            >
              Back to Editor
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default AccordionPreview;
