/* eslint-disable no-undefined */
import React from "react";
import { Row, Button } from "react-bootstrap";
class Carousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data:[],
      labels:[],
      zone:[],
      index:0,
      element:"",
      length: this.props.length,
      nextStatus: true
    };
  }

  //duplicate the props.data
  componentDidMount(){
    let data = [this.props.data], components=[];
    data.map(element=>{
      let obj = {background: element.background, dropZone: element.dropZone};
      components.push(obj);
      return components;
    });
    this.setState({data: components});
    this.setComponent(components, this.state.index);
  }
  
  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data){
      //duplicate the props.data
      let data = [this.props.data], components=[];
      data.map(element=>{
        let obj = {background: element.background, dropZone: element.dropZone};
        components.push(obj);
        return components;
      });
      this.setState({ data: components, index:this.props.idx });
      this.setComponent(components, prevProps.idx);
    }
  }

  setComponent = (component, index) =>{
    let styles = this.props.customisations;
    let bgColor = styles.dropZoneBgColor ? styles.dropZoneBgColor : styles.customisation.dropZoneBgColor;
    let opacity = styles.dropZoneOpacity ? styles.dropZoneOpacity : styles.customisation.dropZoneOpacity;
    component.map((content) => {
      const element = document.createElement("div");
      element.innerHTML = content.background;
      const image = element.getElementsByClassName("image-uploading");
      let source = image[0].src;
      let html = new Image();
      html.src = source; html.id = `img_${this.state.index}`; html.className = "image-uploading";
      let imageDiv = document.getElementById(`dragDrop_${this.state.index}`);
      let img = document.getElementById(`img_${index}`);
      if(img) img.remove();

      content.dropZone.map((value)=>{
        html.addEventListener("load", function () {
          let dropZones = document.getElementById(value.data.text),
            top = (value.data.y/image[0].height)*100 +"%",
            left = (value.data.x/image[0].width)*100 +"%";
          dropZones.setAttribute("style", `width:${value.data.width}; height:${value.data.height}; left:${left}; top:${top};
            background-color: ${opacity !== 0 && opacity !== "0" ? bgColor: "transparent"};
            opacity: ${opacity !== 0 && opacity !== "0" ? opacity: null} `);
        });
        return null;
      });
      imageDiv.appendChild(html);
      return imageDiv;
    });
  }
  start = (ev, id) => {
    ev.dataTransfer.setData("id", id);
    this.setState({
      element: id
    });
  }
  
  drag = (ev) => {
    ev.preventDefault();
  }
  
  drop = (ev) => {
    const target = ev.target.getAttribute("id");
    const copiedData =[];
    const dropArea = document.getElementById(target);

    // copy of state.data 
    this.state.data.map(element => {
      let obj = {background: element.background, dropZone: element.dropZone};
      copiedData.push(obj);
      return copiedData;
    });

    let zone = [];
    copiedData.forEach((el)=> {
      zone.push(...el.dropZone);
    });
    const index = zone.findIndex(area => area.data.text === target);
    let newArray = [...zone];
    newArray[index] = {...newArray[index], dropped:true, element: this.state.element};
    const holder = copiedData;
    let id = copiedData[0].dropZone.findIndex(btn => btn.data.text === this.state.element);
    newArray[id]={...newArray[id], dragged: true};

    // to change the button inside the drop zone if a button is already present
    if(dropArea.firstChild){
      id = copiedData[0].dropZone.findIndex(btn => btn.data.text === dropArea.firstChild.innerText);
      newArray[id]={...newArray[id], dragged: false};
    }
    copiedData[0].dropZone = newArray;
    this.setState({data:holder});
  }

  render() {
    let styles = this.props.customisations;
    let btntype = styles.dragAreaType ? styles.dragAreaType : styles.customisation.dragAreaType;
    const label = [];
    const zone =  [];
    this.state.data.forEach((t) => {
      t.dropZone.forEach((text, idx) => {
        if(!text.dragged) {
          label.push(
            //outline button type
            styles.mouseover ? <Button type="button" className={`${btntype} drop-btn mr-2`} 
              style={styles.buttonUnhover}  key={text.data.text}  id = {`key_${idx}`} 
              draggable="true"  onDragStart = {(e) => this.start(e, text.data.text)} onMouseEnter={this.props.events.hoverOn.bind(this,`key_${idx}`)} 
              onMouseLeave={this.props.events.hoverOff.bind(this,`key_${idx}`)} 
            >{text.data.text}</Button> : 
              // square and pill button type
              <Button type="button" key={text.data.text} className={`${btntype} drop-btn mr-2`} 
                style={styles.buttonStyle} draggable="true" onDragStart = {(e) => this.start(e, text.data.text)}>{text.data.text}
              </Button>
          );
        }
        zone.push(text);
      });
    });

    return (
      <>
        <div id={`dragDrop_${this.state.index}`} className="dndImage">
          {
            zone.map((area) => {
              return (<div key={area.id} className="dropZone" onDragOver={(e)=>this.drag(e)}
                onDrop={(e)=>this.drop(e)}
                id={area.data.text}
              >
                {area.dropped ? styles.mouseover ? <Button type="button" className={`${btntype} drop-btn mr-2`} id={area.id}
                  style={styles.buttonUnhover}  key={area.element} onMouseEnter={this.props.events.hoverOn.bind(this, area.id)} 
                  onMouseLeave={this.props.events.hoverOff.bind(this, area.id)} 
                >{area.element}</Button> : 
                  <Button type="button" key={area.element} className={`${btntype} drop-btn mr-2`}
                    style={styles.buttonStyle}  draggable="true"  onDragStart = {(e) => this.dragBack(e, area.element)} >{area.element}
                  </Button> : ""}
              </div>);

            })
          }
        </div>
        <Row className="mt-3" id="buttonsHolder" onDragOver={(e)=>this.drag(e)}
          onDrop={(e)=>{this.drop(e);}}>
          {label}
        </Row>
      </>
    );
  }
}

export default Carousel;
