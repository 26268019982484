/* eslint-disable no-undefined */
import React, { Component } from "react";
import { Card, Col, Accordion, Form, InputGroup, Button, FormControl } from "react-bootstrap";
import { connect } from "react-redux";
import {  handleErrors, fontSize, setBgColor, setHotspotIcon } from "../../../redux/form/form.actions";
import { SketchPicker } from "react-color";
import styles from "../../../assets/customstyles";


class HotspotCustomize extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseID: "collapse1", 
    };
  }

  // toggle the collapse ID for changing the accordion icon
  toggleCollapse(collapseID) {
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : ""
    }));
  }

  // to set the size of the icon
  fontsize = (e) => {
    const { HandleFontSize, errors } = this.props;
    const {value} = e.target;
    let fail = errors;
    delete fail["fontSize"];
    var charCode = (e.which) ? e.which : e.keyCode;
    if ((value <= 40) === true ) {
      if (charCode === 8 || charCode === 13 || charCode === 99 || charCode === 118 || charCode === 46 || charCode === 101 || charCode === 45 || charCode === 43) {
        e.preventDefault();
      }
      HandleFontSize({
        fontSize: e.target.value,
        errors: fail
      });
    }
  }

  //change background color
  changeBackgroundColor = (color) => {
    const { BgColor } = this.props;
    BgColor({
      bgcolor: color.hex ? color.hex : color.target.value
    });
  }

  // to open and close the color picker
  colorSubmit = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  }

  // set the type of icon
  setIcon = (key,event) => {
    const { Icon } = this.props;
    let iconValue = this.props.hotspotIcon;
    iconValue = {...iconValue, draw: event.target.value, iconType: key};
    Icon({
      hotspotIcon: iconValue
    });
  }

  render() {
    const { collapseID } = this.state;
    const { fontSize, errors, bgcolor } = this.props;
    return (
      <React.Fragment>
        <Col sm={4} className="float-right">
          <Card>
            <Card.Header className="h4 font-weight-bold header">Customize</Card.Header>
            <Card.Body className="p-0">
              <Accordion defaultActiveKey= "0">
                <React.Fragment>
                  <Accordion.Toggle className="font-weight-bold" as={Card.Header} eventKey="0" onClick={() => this.toggleCollapse("collapse1")}>
                    <i className={collapseID === "collapse1" ? "fa fa-caret-down" : "fa fa-caret-right"}></i> Icons
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body className="subheader_bottom">
                      <Form>
                        <Form.Row >
                          <Form.Label>Type</Form.Label>
                        </Form.Row>
                        <Form.Group>
                          {
                            Object.entries(styles.icons).map(([key, value], i) => {
                              return (
                                <label className="mr-3" key={i} >
                                  <input type="radio" name="icon" value={value} onChange={this.setIcon.bind(this, key)} checked={this.props.hotspotIcon.draw === value} className="card-input-element" />
                                  <Card className="card-input">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 30 30" >
                                      <defs>
                                        <clipPath>
                                          <rect width="25" height="25"/>
                                        </clipPath>
                                      </defs>
                                      <g>
                                        <path d={value} 
                                          transform = { key === "Information" ? "translate(65)" : key === "Question" ? "translate(120 -10)"  : ""} 
                                          fill="#000d9c" stroke="rgba(0,0,0,0)" strokeMiterlimit="10" strokeWidth="100"
                                        />
                                      </g>
                                    </svg>
                                  </Card>
                                </label>
                              );
                            })
                          }
                        </Form.Group>
                        <Form.Row>
                          <Form.Group as={Col}>
                            <Form.Label>Size</Form.Label>
                            <InputGroup className="mb-3">
                              <InputGroup.Prepend>
                              </InputGroup.Prepend>
                              <Form.Control type="number" min="10" max="40" name="fontSize" size="md" value={fontSize} onKeyPress={this.fontsize} 
                                onChange={this.fontsize} />
                              <InputGroup.Append>
                                <InputGroup.Text>px</InputGroup.Text>
                              </InputGroup.Append>
                            </InputGroup>
                            {errors.fontSize ? (
                              <small className="text-danger">{errors.fontSize}</small>
                            ) : null}
                          </Form.Group>
                        </Form.Row>
                        <Form.Group>
                          <label>Color</label>
                          <InputGroup size="sm">
                            <FormControl
                              value={this.props.bgcolor}
                              onChange={this.changeBackgroundColor}
                              maxLength="7"
                            />
                            <InputGroup.Append>
                              <Button variant="outline-secondary" onClick={this.colorSubmit.bind(this,"background")} style={{ backgroundColor: bgcolor }}>
                                <i className="fa fa-eye-dropper"></i></Button>
                            </InputGroup.Append>
                          </InputGroup>
                          {this.state.displayColorPicker ? <SketchPicker disableAlpha color={bgcolor} onChange={this.changeBackgroundColor}></SketchPicker> : null}
                        </Form.Group> 
                      </Form>
                    </Card.Body>
                  </Accordion.Collapse>
                </React.Fragment>
              </Accordion>
            </Card.Body>
          </Card>
        </Col>
      </React.Fragment>
    );
  }
}

//state from redux
const mapStateToProps = ({ form }) => ({
  type: form.type,    
  fontSize: form.fontSize,
  bgcolor: form.bgcolor,
  errors: form.errors,
  hotspotIcon: form.hotspotIcon
});

//props to redux
const mapDispatchToProps = dispatch => ({
  HandleError: form => dispatch(handleErrors(form)),
  HandleFontSize: form => dispatch(fontSize(form)),
  BgColor: form => dispatch(setBgColor(form)),
  Icon: form => dispatch(setHotspotIcon(form))
});


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HotspotCustomize);
