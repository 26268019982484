/* eslint-disable no-constant-condition */
import React from "react";
import { Form, FormControl, Button, Card, CardDeck, Col, Accordion, ButtonToolbar, ButtonGroup, InputGroup } from "react-bootstrap";
import { fontHeadings, fontType, fontSize, fontWeight, radius, setIcon, setBgColor, fontColor, setAlignment, borderType, setBorderSize, setBorderColor, setBorderStyle, setcaptionAlignment, errorsFetch, setIconColor, setIconSize, setIconAlignment, setIconType } from "../../redux/form/form.actions";
import { connect } from "react-redux";
import { SketchPicker } from "react-color";
import { defaultValue } from "../../middleware/defaultValue";
import styles from "../../assets/customstyles";

class Customize extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      displayColorPicker: false,
      displayFontColorPicker: false,
      displayIconColorPicker: false,
      collapseID: "collapse1"
    };
  }

  // to set the default value while editing interactives which were created on older days 
  async componentDidMount(){
    const { type, id } = this.props.content.match.params;
    const { FontColor } = this.props;

    if (type === "imageslider"&&(!id)){
      FontColor({
        fontColor: "#000000"
      });
    }
    // eslint-disable-next-line no-undefined
    if ( id !== undefined ){
      defaultValue(this.props);
    }
  }

  // toggle the collapse ID for changing the accordion icon
  toggleCollapse(collapseID) {
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : ""
    }));
    this.setState({ displayFontColorPicker: false });
    this.setState({ displayColorPicker: false });
  }

  fontHeadings = (e) => {
    const { HandleFontHeadings, HandleFontWeight, HandleFontSize } = this.props;
    HandleFontHeadings({
      fontHeadings: e.target.value
    });

    HandleFontWeight({
      fontWeight: "Bold"
    });

    if(e.target.value === "h1"){
      HandleFontSize({
        fontSize: 32
      });
    }
    else if(e.target.value === "h2"){
      HandleFontSize({
        fontSize: 24
      });
    }
    else if(e.target.value === "h3"){
      HandleFontSize({
        fontSize: 21
      });
    }
    else if(e.target.value === "h4"){
      HandleFontSize({
        fontSize: 16
      });
    }
    else if(e.target.value === "h5"){
      HandleFontSize({
        fontSize: 12
      });
    }
    else if(e.target.value === "h6"){
      HandleFontSize({
        fontSize: 11
      });
    }
    else{
      HandleFontWeight({
        fontWeight: "Normal"
      });
      HandleFontSize({
        fontSize: 16
      });
    }
  }

  //font customisation
  fontType = (e) => {
    const { HandleFontFamily } = this.props;
    HandleFontFamily({
      fontFamily: e.target.value
    });
  }

  fontsize = (e) => {
    const { HandleFontSize, errors } = this.props;
    const {value} = e.target;
    let fail = errors;
    delete fail["fontSize"];
    var charCode = (e.which) ? e.which : e.keyCode;
    if ((value <= 40) === true ) {
      if (charCode === 8 || charCode === 13 || charCode === 99 || charCode === 118 || charCode === 46 || charCode === 101 || charCode === 45 || charCode === 43) {
        e.preventDefault();
      }
      HandleFontSize({
        fontSize: e.target.value,
        errors: fail
      });
    }
  }

  borderSize = (e) => {
    const { HandleBorderSize } = this.props;
    HandleBorderSize({
      borderSize: e.target.value
    });
  }

  fontweight = (e) => {
    const { HandleFontWeight } = this.props;
    HandleFontWeight({
      fontWeight: e.target.value
    });
  }

  borderStyle = (e) => {
    const { HandleBorderStyle } = this.props;
    HandleBorderStyle({
      borderStyle: e.target.value
    });
  }

  setIcon = (event) => {
    const { Icon } = this.props;
    Icon({
      icon: event.target.value
    });
  }

  radius = (event) => {
    const { Radius } = this.props;
    Radius({
      radius: event.target.value
    });
  }

  //set background color
  bgcolor = (event) => {
    const { BgColor } = this.props;
    BgColor({
      BgColor: event.target.value
    });
  }

  // Toggle BG color picker view
  colorSubmit = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  }

  // Toggle font color picker view
  fontColorSubmit = () => {
    this.setState({ displayFontColorPicker: !this.state.displayFontColorPicker });
  }

  //change background color
  changeColor = (color) => {
    const { BgColor } = this.props;
    BgColor({
      bgcolor: color.hex
    });
  }

 //change the border color in the color pallet
 changeborderColor = (color) => {
   const { BorderColor } = this.props;
   BorderColor({
     borderColor: color.hex
   });
 }

  //set background color in the input field
  setColor = (e) => {
    const { BgColor } = this.props;
    BgColor({
      bgcolor: e.target.value
    });
  }

  //set the border color in the input field
  setborderColor = (event) => {
    const {BorderColor} = this.props;
    BorderColor({
      borderColor: event.target.value
    });
  }

  //set header alignment
  setAlignment = (e) => {
    const { Setalignment } = this.props;
    Setalignment({
      alignment: e.target.value
    });
  }
  //set font color
  FontColor = (event) => {
    const { FontColor } = this.props;
    FontColor({
      fontColor: event.target.value
    });
  }

  //change font color
  changeFontColor = (color) => {
    const { FontColor } = this.props;
    FontColor({
      fontColor: color.hex,
    });
  }

  //set font color
  borderType = (e) => {
    const { SetBorder } = this.props;
    SetBorder({
      borderType: e.target.value
    });
  }

  //set captionalignment
  setcaptionAlignment = (event) => {
    const {SetcaptionAlignment} = this.props;
    SetcaptionAlignment({
      setcaptionAlignment: event.target.value
    });
  }

   //set font color
   setFontColor = (event) => {
     const { FontColor } = this.props;
     FontColor({
       fontColor: event.target.value
     });
   }


  // Toggle BG color picker view
  iconColorSubmit = () => {
    this.setState({ displayIconColorPicker: !this.state.displayIconColorPicker });
  }
  
  //change background color
  changeIconColor = (color) => {
    const { IconColor } = this.props;
    IconColor({
      iconColor: color.hex
    });
  }

  //set background color in the input field
  setIconColor = (e) => {
    const { IconColor } = this.props;
    IconColor({
      iconColor: e.target.value
    });
  }

   //value for iconSize
   iconSize = (event) => {
     const { IconSize } = this.props;
     IconSize({
       iconSize: event.target.value
     });
   }

    //set header alignment
    iconAlignment = (e) => {
      const { iconalignment } = this.props;
      iconalignment({
        iconAlignment: e.target.value
      });
    }

  //value for iconSize
  iconType = (event) => {
    const { IconType } = this.props;
    IconType({
      iconType: event.target.value
    });
  }

  render() {
    const type = this.props.content.type;
    const { collapseID } = this.state;
    const { fontHeadings, fontSize, borderColor, bgcolor, fontColor, borderType, borderSize, borderStyle, errors, iconColor, iconSize, iconAlignment, iconType } = this.props;

    // to set the default value while editing interactives which were created on older days
    const radius = ((this.props.radius !== "") ? this.props.radius : "rounded-0");
    const icon =((this.props.icon !== "") ? this.props.icon : "arrow");
    const fontFamily = ((this.props.fontFamily !== "") ? this.props.fontFamily : "Helvetica");
    const fontWeight = ((this.props.fontWeight !== "") ? this.props.fontWeight : "Normal");
    const alignment = ((this.props.alignment !== "") ? this.props.alignment : "left");
    // eslint-disable-next-line no-undefined
    const setcaptionAlignment = ((this.props.setcaptionAlignment === "" || this.props.setcaptionAlignment === undefined) ? "bottom" : this.props.setcaptionAlignment);

    let tabview = null,
      alignments = null,
      captionAlignments = null,
      iconSlider = null;

      
    if (type === "Accordion") {
      tabview = (
        <React.Fragment>
          <Accordion.Toggle className="font-weight-bold" as={Card.Header} eventKey="4" onClick={() => this.toggleCollapse("collapse3")}>
            <i className={collapseID === "collapse3" ? "fa fa-caret-down" : "fa fa-caret-right"}></i> Icons
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="4">
            <CardDeck className="m-1">
              <label>
                <input type="radio" name="icon" value="plus/minus" onChange={this.setIcon} checked={icon === "plus/minus"} className="card-input-element" />
                <Card className="small-card card-input">
                  <span className="border border-dark"><i className="fa fa-plus"></i> / <i className="fa fa-minus"></i></span>
                  <small>Plus/Minus</small>
                </Card>
              </label>
              <label>
                <input type="radio" name="icon" value="arrow" onChange={this.setIcon} checked={icon === "arrow"} className="card-input-element" />
                <Card className="small-card card-input">
                  <span className="border border-dark"><i className="fa fa-angle-right"></i> / <i className="fa fa-angle-down"></i></span>
                  <small>Arrow</small>
                </Card>
              </label>
            </CardDeck>
          </Accordion.Collapse>
        </React.Fragment>
      );    
      //Text alignment for Accordion-header
      alignments = (
        <Form.Group>
          <label>Text Alignment</label>
          <ButtonToolbar>
            <ButtonGroup style={{ width: "100%" }} size="sm">
              <Button variant="secondary" active={alignment === "left"} onClick={this.setAlignment} value="left" className="iconleft border-right border-white"></Button>
              <Button variant="secondary" active={alignment === "center"} onClick={this.setAlignment} value="center" className="iconcenter border-right border-white"></Button>
              <Button variant="secondary" active={alignment === "right"} onClick={this.setAlignment} value="right" className="iconright border-right border-white"></Button>
            </ButtonGroup>
          </ButtonToolbar>
        </Form.Group>
      );
    }
    // Caption Alignment for imageslider
    else if (type === "imageslider") {
      captionAlignments = (
        <Form.Group>
          <label>Background Color</label>
          <InputGroup size="sm">
            <FormControl
              value={this.props.bgcolor}
              onChange={this.setColor}
              maxLength="7"
            />
            <InputGroup.Append>
              <Button variant="outline-secondary" onClick={this.colorSubmit} style={{ backgroundColor: bgcolor }}><i className="fa fa-eye-dropper"></i></Button>
            </InputGroup.Append>
          </InputGroup>
          {this.state.displayColorPicker ? <SketchPicker disableAlpha color={bgcolor} onChange={this.changeColor}></SketchPicker> : null} 
          <label className="w-100 mt-2">Caption Alignment</label>
          <label className="w-50">
            <input type="radio" name="caption" value="top" onChange={this.setcaptionAlignment} className="card-input-element" checked={ setcaptionAlignment === "top" }/>
            <Card className="small-card card-input">
              <span className="border border-dark"></span>
              <small>Top</small>
            </Card>
          </label>
          <label className="w-50">
            <input type="radio" name="caption" value="bottom" onChange={this.setcaptionAlignment} className="card-input-element" checked={ setcaptionAlignment === "bottom" }/>
            <Card className="small-card card-input">
              <span className="border border-dark"></span>
              <small>Bottom</small>
            </Card>
          </label>
        </Form.Group>
      );
      iconSlider = (
        <React.Fragment>
          <Accordion.Toggle className="font-weight-bold" as={Card.Header} eventKey="9" onClick={() => this.toggleCollapse("collapse12")}>
            <i className={collapseID === "collapse12" ? "fa fa-caret-down" : "fa fa-caret-right"}></i> Icons
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="9">
            <Card.Body className="subheader_bottom">
              <Form>
                <Form.Row>
                  <Form.Group as={Col}>
                    <label>Icon Color</label>
                    <InputGroup size="sm">
                      <FormControl
                        value={iconColor}
                        onChange={this.setIconColor}     
                        maxLength="7"
                      />
                      <InputGroup.Append>
                        <Button variant="outline-secondary" onClick={this.iconColorSubmit} style={{ backgroundColor: iconColor }}><i className="fa fa-eye-dropper"></i></Button>
                      </InputGroup.Append>
                    </InputGroup>
                    {this.state.displayIconColorPicker ? <SketchPicker disableAlpha color={iconColor} onChange={this.changeIconColor}></SketchPicker> : null} 
                  </Form.Group>
                </Form.Row>
              </Form>
              <label>Icon Size</label>
              <CardDeck>
                {Object.entries(styles.iconSize).map(([key,value], idx) => {
                  return (
                    <label key={idx}>
                      <input type="radio" name="btn-type" value={value} onChange={this.iconSize} checked={iconSize === value} className="card-input-element" />
                      <Card className="small-card card-input">
                        <Button variant="btn btn-dark btn-sm" size="sm" style={{ marginTop: 15, height: 30 }}>{key}</Button>
                      </Card>
                    </label>
                  );
                })}
              </CardDeck><hr></hr>
              <Form.Group>
                <label>Icon Alignment</label>
                <ButtonToolbar>
                  <ButtonGroup style={{ width: "100%" }} size="sm">
                    <Button variant="secondary" active={iconAlignment === "Top"} onClick={this.iconAlignment} value="Top" className="iconTop border-right border-white"></Button>
                    <Button variant="secondary" active={iconAlignment === "Default"} onClick={this.iconAlignment} value="Default" className="iconDefault border-right border-white"></Button>
                    <Button variant="secondary" active={iconAlignment === "Bottom"} onClick={this.iconAlignment} value="Bottom" className="iconBottom border-right border-white"></Button>
                  </ButtonGroup>
                </ButtonToolbar>
              </Form.Group>
              <Form.Group>
                <label>Icon type</label>
                <CardDeck className="m-1">
                  <label>
                    <input type="radio" name="btn-border" value="50%" onChange={this.iconType} checked={iconType === "50%"} className="card-input-element" />
                    <Card className="small-card card-input">
                      <span className="border rounded-circle border-dark"></span>
                      <small>Round</small>
                    </Card>
                  </label>
                  <label>
                    <input type="radio" name="btn-border" value="0%" onChange={this.iconType} checked={iconType === "0%"} className="card-input-element" />
                    <Card className="small-card card-input">
                      <span className="border border-dark"></span>
                      <small>Square</small>
                    </Card>
                  </label>
                </CardDeck>
              </Form.Group>
            </Card.Body>
          </Accordion.Collapse>   
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <Col sm={4} className="float-right">
          <Card>
            <Card.Header className="h4 font-weight-bold header">Customize</Card.Header>
            <Card.Body className="p-0">
              <Accordion defaultActiveKey="0">
                <Card className="border-0">
                  <Accordion.Toggle className="font-weight-bold" as={Card.Header} eventKey="0" onClick={() => this.toggleCollapse("collapse1")}>
                    <i className={collapseID === "collapse1" ? "fa fa-caret-down" : "fa fa-caret-right"}></i> Section Header
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body className="subheader_bottom">
                      <Form>
                        <Form.Row>
                          <Form.Group as={Col}>
                            <Form.Label>Font Header</Form.Label>
                            <Form.Control as="select" value={fontHeadings} onChange={this.fontHeadings} size="md">
                              <option style={{ display: "none" }}>{(fontHeadings) ? (fontHeadings.toUpperCase()) : "Normal"}</option>
                              {
                                styles.fontHeadings.map((fontlist, i) => {
                                  const value = ((fontlist === "Normal") ? "" :`h${i + 1}`);
                                  const data =  ((fontlist === "Normal") ? fontlist :fontlist.toUpperCase());
                                  return (<option key={i} value={value} className={`h${i + 1}`}>{data}</option>);
                                })
                              }
                            </Form.Control>
                          </Form.Group>
                          <Form.Group as={Col}>
                            <Form.Label>Font Family</Form.Label>
                            <Form.Control as="select" value = {fontFamily} onChange={this.fontType} size="md">
                              {/* Looping for font family */}
                              {
                                Object.entries(styles.fontFamily).map(([key, value], i) => {
                                  return <option key={i} style={{ fontFamily: value }} value = {value} >{key}</option>;
                                })
                              }
                            </Form.Control>
                          </Form.Group>
                        </Form.Row>
                        <Form.Row>
                          <Form.Group as={Col}>
                            <Form.Label>Font Size</Form.Label>
                            <InputGroup className="mb-3">
                              <InputGroup.Prepend>
                              </InputGroup.Prepend>
                              <Form.Control type="number" min="10" max="40" name="fontSize" size="md" value={fontSize} onKeyPress={this.fontsize} onChange={this.fontsize} />
                              <InputGroup.Append>
                                <InputGroup.Text>px</InputGroup.Text>
                              </InputGroup.Append>
                              {errors.fontSize ? (
                                <small className="text-danger">{errors.fontSize}</small>
                              ) : null}
                            </InputGroup>
                          </Form.Group>
                          <Form.Group as={Col}>
                            <Form.Label>Font Weight</Form.Label>
                            <Form.Control as="select"  value = {fontWeight} onChange={this.fontweight} size="md">
                              {
                                styles.fontWeight.map((fontlist, i) => {
                                  return <option key={i} value = {fontlist}>{fontlist}</option>;
                                })
                              }
                            </Form.Control>
                          </Form.Group>
                        </Form.Row>
                        <Form.Group>
                          <label>Font Color</label>
                          <InputGroup size="sm">
                            <FormControl
                              placeholder="Font Color"
                              value={fontColor}
                              onChange={this.setFontColor}
                              maxLength="7"
                            />
                            <InputGroup.Append>
                              <Button variant="outline-secondary" onClick={this.fontColorSubmit} style={{ backgroundColor: fontColor }}><i className="fa fa-eye-dropper"></i></Button>
                            </InputGroup.Append>
                          </InputGroup>
                          {this.state.displayFontColorPicker ? <SketchPicker disableAlpha color={fontColor} onChange={this.changeFontColor}></SketchPicker> : null}
                        </Form.Group>
                        {
                          type === "Accordion" || type === "Tabs" ?
                            <Form.Group>
                              <label>Background Color</label>
                              <InputGroup size="sm">
                                <FormControl
                                  value={this.props.bgcolor}
                                  onChange={this.setColor}
                                  maxLength="7"
                                />
                                <InputGroup.Append>
                                  <Button variant="outline-secondary" onClick={this.colorSubmit} style={{ backgroundColor: bgcolor }}><i className="fa fa-eye-dropper"></i></Button>
                                </InputGroup.Append>
                              </InputGroup>
                              {this.state.displayColorPicker ? <SketchPicker disableAlpha color={bgcolor} onChange={this.changeColor}></SketchPicker> : null}
                            </Form.Group> : ""
                        }
                        {alignments}
                        {captionAlignments}
                      </Form>
                    </Card.Body>
                  </Accordion.Collapse>
                  {iconSlider}
                  {
                    type === "Accordion" || type === "Tabs" || type === "ClickAndRevealInteractions" ? <>
                      <Accordion.Toggle className="font-weight-bold" as={Card.Header} eventKey="3" onClick={() => this.toggleCollapse("collapse2")}>
                        <i className={collapseID === "collapse2" ? "fa fa-caret-down" : "fa fa-caret-right"}></i> Outline-Border
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="3" className="subheader_bottom">
                        <CardDeck className="m-1">
                          <label>
                            <input type="radio" name="btn-border" value="rounded-0" onChange={this.radius} checked={radius === "rounded-0"} className="card-input-element" />
                            <Card className="small-card card-input">
                              <span className="border border-dark"></span>
                              <small>Sharp</small>
                            </Card>
                          </label>
                          <label>
                            <input type="radio" name="btn-border" value="rounded-lg" onChange={this.radius} checked={radius === "rounded-lg"} className="card-input-element" />
                            <Card className="small-card card-input">
                              <span className="border rounded-lg border-dark"></span>
                              <small>Round</small>
                            </Card>
                          </label>
                        </CardDeck>
                      </Accordion.Collapse>
                      {tabview}
                      <Accordion.Toggle className="font-weight-bold" as={Card.Header} eventKey="5" onClick={() => this.toggleCollapse("collapse4")}>
                        <i className={collapseID === "collapse4" ? "fa fa-caret-down" : "fa fa-caret-right"}></i> Borders
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="5">
                        <Card.Body className="subheader_bottom">
                          <Form>
                            <CardDeck>
                              {Object.entries(styles.borderType).map(([key,value], idx) => {
                                return (
                                  <label key={idx}>
                                    <input type="radio" name="border-type" value={value} onChange={this.borderType} checked={borderType === value} className="card-input-element" />
                                    <Card className="small-card card-input">
                                      <span className={value} size="sm" style={{ marginTop: 15, height: 30}}>{key}</span>
                                    </Card>
                                  </label>
                                );
                              })}
                            </CardDeck>
                            <Form.Group>
                              <label>Border Color</label>
                              <InputGroup size="sm">
                                <FormControl
                                  value={this.props.borderColor}
                                  onChange={this.setborderColor}
                                  maxLength="7"
                                />
                                <InputGroup.Append>
                                  <Button variant="outline-secondary" onClick={this.colorSubmit} style={{ backgroundColor: borderColor }}><i className="fa fa-eye-dropper"></i></Button>
                                </InputGroup.Append>
                              </InputGroup>
                              {this.state.displayColorPicker ? <SketchPicker disableAlpha color={borderColor} onChange={this.changeborderColor}></SketchPicker> : null}
                            </Form.Group>
                            <Form.Row>
                              <Form.Group as={Col}>
                                <Form.Label>Border Size</Form.Label>
                                <Form.Control as="select" onChange={this.borderSize} size="sm">
                                  <option style={{ display: "none" }}>{(borderSize) ? borderSize : "Select"}</option>
                                  {
                                    styles.borderSize.map((borderlist, i) => {
                                      return <option key={i}>{borderlist}</option>;
                                    })
                                  }
                                </Form.Control>
                              </Form.Group>
                              <Form.Group as={Col}>
                                <Form.Label>Border Style</Form.Label>
                                <Form.Control as="select"  defaultValue={borderStyle} className="text-capitalize" onChange={this.borderStyle} size="sm">
                                  <option style={{ display: "none" }}>{(borderStyle) ? borderStyle : "Select"}</option>
                                  {
                                    Object.entries(styles.borderStyle).map(([key,value], idx) => {
                                      return <option value = {value}  key={idx}>{key}</option>;
                                    })
                                  }
                                </Form.Control>
                              </Form.Group>
                            </Form.Row>
                          </Form>
                        </Card.Body>
                      </Accordion.Collapse> </> : ""
                  }
                </Card>
              </Accordion>
            </Card.Body>
          </Card>
        </Col>
      </React.Fragment>
    );
  }
}

//state from redux
const mapStateToProps = ({ form }) => ({
  type: form.type,
  fontHeadings: form.fontHeadings,
  fontFamily: form.fontFamily,
  fontSize: form.fontSize,
  fontWeight: form.fontWeight,
  radius: form.radius,
  icon: form.icon,
  color: form.color,
  bgcolor: form.bgcolor,
  alignment: form.alignment,
  fontColor: form.fontColor,
  borderType: form.borderType,
  borderColor: form.borderColor,
  borderSize: form.borderSize,
  borderStyle: form.borderStyle,
  setcaptionAlignment: form.setcaptionAlignment,
  errors: form.errors,
  iconColor: form.iconColor,
  iconSize: form.iconSize,
  iconAlignment: form.iconAlignment,
  iconType: form.iconType

});

//props to redux
const mapDispatchToProps = dispatch => ({
  HandleFontHeadings: form => dispatch(fontHeadings(form)),
  HandleFontFamily: form => dispatch(fontType(form)),
  HandleFontSize: form => dispatch(fontSize(form)),
  HandleFontWeight: form => dispatch(fontWeight(form)),
  Radius: form => dispatch(radius(form)),
  Icon: form => dispatch(setIcon(form)),
  BgColor: form => dispatch(setBgColor(form)),
  Setalignment: form => dispatch(setAlignment(form)),
  FontColor: form => dispatch(fontColor(form)),
  SetBorder: form => dispatch(borderType(form)),
  BorderColor: form => dispatch(setBorderColor(form)),
  HandleBorderSize: form => dispatch(setBorderSize(form)),
  HandleBorderStyle: form => dispatch(setBorderStyle(form)),
  SetcaptionAlignment: form => dispatch(setcaptionAlignment(form)),
  ErrorsFetch: data => dispatch(errorsFetch(data)),
  IconColor: form => dispatch(setIconColor(form)),
  iconalignment: form => dispatch(setIconAlignment(form)),
  IconSize: form => dispatch(setIconSize(form)),
  IconType: form => dispatch(setIconType(form)),

});

//connecting form with redux
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Customize);
