export const validateForm = (title, link, name, fontSize, ErrorsFetch, pathName) => {
  let fail = {};
  let regexp =  /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;

  if (title === "") {
    fail["title"] = pathName.includes("/duplicate") ? "* DUPLICATED INTERACTIVE - Please enter a new title":  "* This Title is required";
  } else if (title.length > 100) { 
    fail["title"] = "* Title limit upto 100 characters only";
  }

  if (name === "") {
    fail["name"] = "* This Name field is required";
  } else if (name.length > 100) { 
    fail["name"] = "* Name limit upto 100 characters only";
  }

  if (link === "") {
    fail["link"] = "* This Link field is required";
  } else if (link.length > 256) {
    fail["link"] = "* Link exceeded limit";
  } else if(!regexp.test(link)===true){
    fail["link"] = "* Please Enter Valid URL";
  }

  if (fontSize <= 10) {
    fail["fontSize"] = "* Value must be 10-40";
  } 

  ErrorsFetch({
    errors: fail
  });
  return fail;
};
