//initial state for redux
const INITIALSTATE = {
  title:"",
  name:"",
  link:"",
  isChecked: true,
  errors:{},
  color: "#007bff",
  btnType:"btn btn-square btn-dark",
  btnSize:"",
  fontFamily:"Helvetica",
  fontSize:"16",
  fontColor: "#ffffff",
  alignment: "left",
  customPaddingtop:"10",
  customPaddingleft:"10",
  customPaddingbottom:"10",
  customPaddingright:"10"
};
  
//Action creator with actions
const buttonReducer = (state = INITIALSTATE, data) => {
  switch (data.type) {
  case "SET_NAME":
    return {
      ...state,
      name:data.name,
      errors: data.errors
    };
  case "SET_LINK":
    return {
      ...state,
      link:data.link,
      errors: data.errors
    };
  case "SET_TITLE":
    return {
      ...state,
      title: data.title,
      errors: data.errors
    };

  //Checkbox action true or false  
  case "SET_CHECK":
    return {
      ...state,
      isChecked: data.isChecked
    };

  //Validation action
  case "ERRORS": 
    return {
      ...state,
      errors: data.errors
    };

  //Edit action
  case "MAKE_EDIT": 
    return {
      ...state,
      title: data.title,
      name: data.name,
      link: data.link,
      isChecked: data.isChecked,
      color: data.color,
      btnType: data.btnType,
      btnSize: data.btnSize,
      fontFamily:data.fontFamily,
      fontSize:data.fontSize,
      fontColor: data.fontColor,
      alignment: data.alignment,
      customPaddingtop:data.customPaddingtop,
      customPaddingleft:data.customPaddingleft,
      customPaddingbottom:data.customPaddingbottom,
      customPaddingright:data.customPaddingright
    };

  //Cutom_color action 
  case "BG_COLOR":
    return {
      ...state,
      color: data.color
    };
  case "BTN_TYPE":
    return {
      ...state,
      btnType: data.btnType
    };
  case "BTN_SIZE":
    return {
      ...state,
      btnSize: data.btnSize
    };
  case "FONT_TYPE":
    return {
      ...state,
      fontFamily:data.component
    };

  case "FONT_SIZE":
    return {
      ...state,
      fontSize:data.component
    };
  case "CUSTOM_FONTCOLOR":
    return {
      ...state,
      fontColor: data.fontColor
    };

  case "CUSTOM_ALIGNMENT":
    return {
      ...state,
      alignment: data.alignment
    };
    
  case "CUSTOM_PADDINGTOP":
    return {
      ...state,
      customPaddingtop: data.customPaddingtop
    };

  case "CUSTOM_PADDINGLEFT":
    return {
      ...state,
      customPaddingleft: data.customPaddingleft
    };

  case "CUSTOM_PADDINGBOTTOM":
    return {
      ...state,
      customPaddingbottom: data.customPaddingbottom
    };
  
  case "CUSTOM_PADDINGRIGHT":
    return {
      ...state,
      customPaddingright: data.customPaddingright
    };
  
  default: 
    return state;
  }
};
  
export default buttonReducer;
