/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-undefined */
import React from "react";
import ReactHtmlParser from "react-html-parser";
import { putItems } from "../../redux/components/reducer.action";
import { Container, Button, Row, Col, Carousel, Card } from "react-bootstrap";
import { fetchContent, viewContent } from "../../middleware/api";
import { initializeReactGA } from "../../middleware/googleAnalytics";
import { connect } from "react-redux";
import Header from "../layouts/navbar";
import { Redirect } from "react-router-dom";
import PostHeightIframe from "../../middleware/sendHeight";
import "../../assets/scss/imageslider.scss";

let content, response;

class ImageSlider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewpage: this.props.location.pathname,
    };
  }
  //Getting data from the Redux-state
  async componentDidMount() {
    const { PutItems } = this.props;
    const { id } = this.props.match.params;
    if (
      this.props.location.pathname === `Imageslider/${id}` ||
      `imageslider/${id}`
    ) {
      response = await viewContent(id);
    } else {
      if (
        this.props.match.params.user === undefined ||
        this.props.match.params.user === ""
      ) {
        content = sessionStorage.getItem("user_id");
      } else {
        content = this.props.match.params.user;
      }
      response = await fetchContent(id, content);
    }

    if (!response.message && response.library_id === 6) {

      //To check the tracking id of GA
      if(this.state.viewpage !== `/imageslider/${id}` ) initializeReactGA(this.props.location.pathname);

      PutItems({
        title: response.title,
        parameters: JSON.parse(response.parameters),
        customisations: response.customisations
      });
    } else {
      this.props.history.push("/*");
    }
    if(this.state.viewpage === `/imageslider/${id}` ) PostHeightIframe();
    var carouselPrevIcon = document.getElementsByClassName("carousel-control-prev");
    var carouselnextIcon = document.getElementsByClassName("carousel-control-next"); 
    var carouselprevControlIcon = document.getElementsByClassName("carousel-control-prev-icon"); 
    var carouselnextControlIcon = document.getElementsByClassName("carousel-control-next-icon"); 

    if(carouselPrevIcon && carouselnextIcon){
      carouselPrevIcon[0].setAttribute("style", `background-color: ${response.customisations.iconColor};`); 
      carouselnextIcon[0].setAttribute("style", `background-color: ${response.customisations.iconColor};`); 
      carouselprevControlIcon[0].setAttribute("style", `padding: ${response.customisations.iconSize};`); 
      carouselnextControlIcon[0].setAttribute("style", `padding: ${response.customisations.iconSize};`); 
    }
    if(document.getElementById !== "prev-id" && document.getElementById !== "next-id" ){
      carouselPrevIcon[0].setAttribute("id", "prev-id");
      carouselnextIcon[0].setAttribute("id", "next-id");
    }
    if(response.customisations.iconAlignment === "Top"){
      document.getElementById("prev-id").className = "carousel-control-prev-top";
      document.getElementById("next-id").className = "carousel-control-next-top";
    }
    if(response.customisations.iconAlignment === "Bottom"){
      document.getElementById("prev-id").className = "carousel-control-prev-bottom";
      document.getElementById("next-id").className = "carousel-control-next-bottom";
    }
    if(response.customisations.iconType === "50%"){
      document.getElementById("prev-id").setAttribute("style", document.getElementById("prev-id").getAttribute("style")+`; border-radius: ${response.customisations.iconType};`);
      document.getElementById("next-id").setAttribute("style", document.getElementById("next-id").getAttribute("style")+`; border-radius: ${response.customisations.iconType};`);
    }
  }

  //Edit functionality for imageslider
  handleEdit = () => {
    let path;
    if (this.props.match.params.user === undefined) {
      path = "/edit/imageslider";
    } else {
      path = `/content/imageslider/${this.props.match.params.user}`;
    }
    const { id } = this.props.match.params;
    if (id) {
      this.props.history.push({
        pathname: `${path}/${id}/${sessionStorage.getItem("org_id")}/${sessionStorage.getItem("loreeVersion")}`,
        state: {
          user:
            this.props.location.state !== undefined
              ? this.props.location.state.user
              : "",
          user_id:
            this.props.location.state !== undefined
              ? this.props.location.state.user_id
              : "",
        },
      });
    }
  };

  render() { // render imageslider view
    
    const { title, parameters, customisations } = this.props;
    const { id } = this.props.match.params;
    const viewpage = this.props.location.pathname;
    const caption = customisations.setcaptionAlignment;
    const fontHeadings = ((this.props.customisations.fontHeadings !== "") ? this.props.customisations.fontHeadings : "div");
    const sliderStyle = {
      fontFamily: `${customisations.fontFamily}`,
      fontWeight: `${customisations.fontWeight}`,
      fontSize: `${customisations.fontSize}px`,
      color: `${customisations.fontColor}`,
      backgroundColor: `${customisations.bgcolor}`
    };
    let params, videoVal;
    for(params of parameters){
      videoVal = params["text"].includes("iframe");
    }
    if (viewpage === `/imageslider/${id}`) {
      return (
        <React.Fragment>
          <Container>
            <Row className="d-block">
              <Carousel className="carousel-view" controls={(parameters.length===1) ? false : true} pause={"hover"} interval={videoVal === true ? null : 5000}>
                {
                  parameters.map((content, idx) =>     {
                    let extraTag =  content["text"].replace("<p><br></p>", "");
                    content["text"] = extraTag;
                    return (
                      <Carousel.Item key={idx}
                        eventkey={idx}>
                        <Card className="text-center" style={{display: caption === "bottom" ? "none" : " "}}>
                          <Card.Body as={fontHeadings} style={sliderStyle}>{content["title"]}</Card.Body>
                        </Card>
                        {ReactHtmlParser(content["text"])}
                        <Card className="text-center" style={{display: caption === "top" ? "none" : " "}}>
                          <Card.Body as={fontHeadings} style={sliderStyle}>{content["title"]}</Card.Body>
                        </Card>
                      </Carousel.Item>
                    );}
                  )}
              </Carousel>
            </Row>
          </Container>
        </React.Fragment>
      );
    }
    else if (viewpage === `/create/new/imageslider/${id}` ||
      `contentview/imageslider/${this.props.match.params.user}/${id}`
    ) {
      if (this.props.location.state !== undefined) {
        return (
          <React.Fragment>
            <Header user={localStorage.getItem("user")}
              user_id={sessionStorage.getItem("user_id")} 
              data={this.props}  
            />
            <Container className="margin">
              <Row className="d-flex justify-content-between mt-3">
                <Col style={{wordBreak:"break-word"}}>
                  <h2>{title}</h2>
                </Col>
                <Col xs lg="1">
                  <Button className="btn btn-dark" onClick={this.handleEdit}>Edit</Button>
                </Col>
              </Row>
              <Row className="d-block">
                <Col className="m-3">
                  <Carousel className="carousel-view" controls={(parameters.length===1) ? false : true} pause={"hover"} interval={videoVal === true ? null : 5000}>
                    {
                      parameters.map((content, idx) =>     {
                        let extraTag =  content["text"].replace("<p><br></p>", "");
                        content["text"] = extraTag;
                        return (
                          <Carousel.Item key={idx}
                            eventkey={idx}>
                            <Card className="text-center" style={{display: caption === "bottom" ? "none" : " "}}>
                              <Card.Body as={fontHeadings} style={sliderStyle}>{content["title"]}</Card.Body>
                            </Card>
                            {ReactHtmlParser(content["text"])}
                            <Card className="text-center" style={{display: caption === "top" ? "none" : " "}}>
                              <Card.Body as={fontHeadings} style={sliderStyle}>{content["title"]}</Card.Body>
                            </Card>
                          </Carousel.Item>
                        );}
                      )}
                  </Carousel>
                </Col>
              </Row>
            </Container>
          </React.Fragment>
        );
      } else {
        return <Redirect to="/401" />;
      }
    }
  }
}

//state from redux
const mapStateToProps = ({ component }) => ({
  title: component.title,
  parameters: component.parameters,
  customisations: component.customisations
});

//props to redux
const mapDispatchToProps = dispatch => ({
  PutItems: component => dispatch(putItems(component))
});

//connecting form with redux
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImageSlider);
