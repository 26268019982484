import React from "react";
import Routing from "./routes";
import "./App.scss";
import "../src/assets/font/customFont.css";
import "../src/assets/scss/fontawesome.min.css";

function App() {
  return (
    <React.Fragment>
      <Routing />
    </React.Fragment>
  );
}

export default App;

