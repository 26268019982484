/* eslint-disable no-useless-concat */
import React from "react";
import ReactHtmlParser from "react-html-parser";
import { Card, Col, Modal, Button, Row, Container } from "react-bootstrap";
import "../../assets/scss/interactiveCard.scss";

class InteractiveCardPreview extends React.Component {

  handleClick = (idx) => {
    document.getElementById(`sub_card${idx}`).className="w-50";
  }
  
  render() { //preview page render for interactivecard using modal
    const cardPreviewStyle = {
      fontFamily: `${this.props.content.fontFamily}`,
      fontWeight: `${this.props.content.fontWeight}`,
      fontSize: `${this.props.content.fontSize}px`,
      textAlign: `${this.props.content.alignment}`,
      color: `${this.props.content.fontColor}`,
    };
    const fontHeadings = ((this.props.content.fontHeadings !== "" ) ? this.props.content.fontHeadings : "div");

    const borderleftStyle = (this.props.content.borderType === "border-left border-dark") ? true : false;

    const borderrightStyle = (this.props.content.borderType === "border-right border-dark") ? true : false;

    const bordertopStyle = (this.props.content.borderType === "border-top border-dark") ? true : false;  

    const borderbottomStyle = (this.props.content.borderType === "border-bottom border-dark") ? true : false;  

    const borderfullStyle = (this.props.content.borderType === "border border-dark") ? true : false; 

    const interactivecardborderleftStyle = {
      borderLeft: `${this.props.content.borderSize}px ` + `${this.props.content.borderStyle} ` + `${this.props.content.borderColor}`
    };

    const interactivecardborderrightStyle = {
      borderRight: `${this.props.content.borderSize}px ` + `${this.props.content.borderStyle} ` + `${this.props.content.borderColor}`
    };

    const interactivecardbordertopStyle = {
      borderTop: `${this.props.content.borderSize}px ` + `${this.props.content.borderStyle} ` + `${this.props.content.borderColor}`
    };

    const interactivecardborderbottomtStyle = {
      borderBottom: `${this.props.content.borderSize}px ` + `${this.props.content.borderStyle} ` + `${this.props.content.borderColor}`
    };

    const interactivecardborderfullStyle = {
      border: `${this.props.content.borderSize}px ` + `${this.props.content.borderStyle} ` + `${this.props.content.borderColor}`
    };

    return (
      <div className="m-2">
        <Modal
          {...this.props}
          size="lg"
          dialogClassName="modal-90w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter" style={{wordBreak:"break-word"}}>
              {this.props.content.title}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <React.Fragment>
                {
                  this.props.content.parameters.map ((content, idx)=> (
                    <Row className="my-3" key={idx}>
                      <Col xs={6} id="interactive-image" className="interactive-image-PreView">                  
                        <Card key={idx} id={`card${idx}`} style = {borderleftStyle ? interactivecardborderleftStyle : (borderrightStyle) ? interactivecardborderrightStyle : (bordertopStyle) ? interactivecardbordertopStyle : (borderbottomStyle) ? interactivecardborderbottomtStyle : (borderfullStyle) ? interactivecardborderfullStyle : null} className={`w-75 cardHeight shadow ${this.props.content.radius}`} onClick={()=>this.handleClick(idx)}>
                          <Card.Body className="interactive-textstyle">
                            {ReactHtmlParser(content["text"])}
                          </Card.Body>
                        </Card>
                                    
                      </Col>
                      <Col xs={6} key={idx} id={`sub_card${idx}`}  className="d-none">
                        <Card style={borderleftStyle ? interactivecardborderleftStyle : (borderrightStyle) ? interactivecardborderrightStyle : (bordertopStyle) ? interactivecardbordertopStyle : (borderbottomStyle) ? interactivecardborderbottomtStyle : (borderfullStyle) ? interactivecardborderfullStyle : null} className={`w-75 cardHeight shadow ${this.props.content.radius}`} id="interactive-content" >
                          <Card.Body as={fontHeadings} className="interactive-textstyle" style={cardPreviewStyle }>
                            {content["title"]}
                          </Card.Body>
                        </Card>
                      
                      </Col>
                    </Row>
                  ))
                }
              </React.Fragment>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button variant= "btn btn-dark btn-sm btn-pill btn btn-primary" onClick={this.props.onHide}>Back to Editor</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default InteractiveCardPreview;
