import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import Store from "./redux/store";
import "bootstrap/scss/bootstrap.scss";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
  <Provider store={Store}>
    <App />
  </Provider>, document.getElementById("root"));

serviceWorker.unregister();
