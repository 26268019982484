import React, { Component } from "react";
import { connect } from "react-redux";
import { setBtnColor, setBtnType, fontType, fontSize, fontColor, setAlignment, customPaddingtop, customPaddingleft,customPaddingbottom, customPaddingright, errorsFetch } from "../../redux/button/button.actions";
import { Card, Form, FormControl, Button, Accordion, Col, ButtonToolbar, ButtonGroup, CardDeck, InputGroup } from "react-bootstrap";
import { SketchPicker } from "react-color";
import { customizeValue } from "../../middleware/defaultValue";
import styles from "../../assets/customstyles";

class Customize extends Component {

  constructor(props) {
    super(props);
    this.state = {
      displayColorPicker: false,
      displayFontColorPicker: false,
      collapseID: "collapse1"
    };
  }

  // toggle the collapse ID for changing the accordion icon
  toggleCollapse(collapseID) {
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : ""
    }));
    this.setState({displayFontColorPicker: false});
    this.setState({displayColorPicker: false});
  }

   //font-Family customisation
   fontType = (e) => {
     const { HandleFontFamily } = this.props;
     HandleFontFamily({
       fontFamily: e.target.value
     });
   }

  //set font-size
  fontsize = (e) => {
    const { HandleFontSize, errors } = this.props;
    const {value} = e.target;
    let fail = errors;
    delete fail["fontSize"];
    var charCode = (e.which) ? e.which : e.keyCode;

    if ((value <= 40) === true  ) {

      if (charCode === 8 || charCode === 13 || charCode === 99 || charCode === 118 || charCode === 46 || charCode === 101 || charCode === 45 || charCode === 43) {
        e.preventDefault();
      }
      HandleFontSize({
        fontSize: e.target.value,
        errors: fail
      });
    }
  }

  //set font color
  FontColor = (event) => {
    const { FontColor } = this.props;
    FontColor({
      fontColor: event.target.value
    });
  }

  //Set Background color
  color = (event) => {
    const { Color } = this.props;
    Color({
      color: event.target.value
    });
  }

  //change color background after submit
  colorSubmit = () => {
    this.setState({displayColorPicker: !this.state.displayColorPicker});
  }

  //change color background
  changeColor = (color) => {
    const { Color } = this.props;
    Color({
      color:color.hex
    });
  }

  //Set color background
  setColor = (e) => {
    const { Color } = this.props;
    Color({
      color:e.target.value
    });
  }
  
  //dislay font color picker
  fontColorSubmit = () => {
    this.setState({displayFontColorPicker: !this.state.displayFontColorPicker});
  }

  //change font color
  changeFontColor = (color) => {
    const { FontColor } = this.props;
    FontColor({
      fontColor:color.hex,
    });
  }

  //set font color
  setFontColor = (event) => {
    const { FontColor } = this.props;
    FontColor({
      fontColor: event.target.value
    });
  }

  //value for buttontype
  btnType = (event) => {
    const { BtnType } = this.props;
    BtnType({
      btnType: event.target.value
    });
  }

  //set header alignment
  setAlignment = (e) => {
    const { Setalignment } = this.props;
    Setalignment({
      alignment:e.target.value
    });
  }

  setCustompaddingtop = (e) => {
    const { SetcustomPaddingtop } = this.props;
    const {value} = e.target;
    var charCode = (e.which) ? e.which : e.keyCode;

    if ((value <= 200) === true ) {
      if (charCode === 8 || charCode === 13 || charCode === 99 || charCode === 118 || charCode === 46 || charCode === 101 || charCode === 45 || charCode === 43) {
        e.preventDefault();
      }
      SetcustomPaddingtop({
        customPaddingtop:e.target.value
      });
    }
  }

  //set custom css
  setCustompaddingleft = (e) => {
    const { SetcustomPaddingleft } = this.props;
    const {value} = e.target;
    var charCode = (e.which) ? e.which : e.keyCode;

    if ((value <= 200) === true ) {
      if (charCode === 8 || charCode === 13 || charCode === 99 || charCode === 118 || charCode === 46 || charCode === 101 || charCode === 45 || charCode === 43) {
        e.preventDefault();
      }
      SetcustomPaddingleft({
        customPaddingleft:e.target.value
      });
    }
  }

  setCustompaddingbottom = (e) => {
    const { SetcustomPaddingbottom } = this.props;
    const {value} = e.target;
    var charCode = (e.which) ? e.which : e.keyCode;

    if ((value <= 200) === true ) {
      if (charCode === 8 || charCode === 13 || charCode === 99 || charCode === 118 || charCode === 46 || charCode === 101 || charCode === 45 || charCode === 43) {
        e.preventDefault();
      }
      SetcustomPaddingbottom({
        customPaddingbottom:e.target.value
      });
    }
  }

  setCustompaddingright = (e) => {
    const { SetcustomPaddingright } = this.props;
    const {value} = e.target;
    var charCode = (e.which) ? e.which : e.keyCode;

    if ((value <= 200) === true ) {
      if (charCode === 8 || charCode === 13 || charCode === 99 || charCode === 118 || charCode === 46 || charCode === 101 || charCode === 45 || charCode === 43) {
        e.preventDefault();
      }
      SetcustomPaddingright({
        customPaddingright:e.target.value
      });
    }
  }

  render() {
    const { color, fontSize, fontColor, errors, customPaddingbottom, customPaddingtop, customPaddingleft, customPaddingright  } = this.props.content;
    const { collapseID } = this.state;

    //to set the default customisation value while editng the interatives which were created on older days
    const fontFamily = ((this.props.content.fontFamily !== "") ? this.props.content.fontFamily : "Helvetica");
    const alignment = ((this.props.content.alignment !== "") ? this.props.content.alignment : "left");
    const btnType = ((this.props.content.btnType !== "") ? this.props.content.btnType : "btn btn-square btn-dark");
    const { id } = this.props.content.match.params;
    // eslint-disable-next-line no-undefined
    if ( id !== undefined ){
      customizeValue(this.props);
    }

    return (
      <React.Fragment>
        <Col sm={4} className="float-right">
          <Card>
            <Card.Header className="h4 font-weight-bold header">Customize</Card.Header>
            <Card.Body className="p-0">
              <Accordion defaultActiveKey="0">
                <Card className="border-0"> 
                  <Accordion.Toggle className="font-weight-bold" as={Card.Header} eventKey="0" onClick={() => this.toggleCollapse("collapse1")}>
                    <i className={collapseID === "collapse1" ? "fa fa-caret-down" : "fa fa-caret-right"}></i> Font
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <Form.Row>
                        <Form.Group as={Col}>
                          <Form.Label>Font Family</Form.Label>
                          <Form.Control as="select" value={fontFamily} onChange={this.fontType} size="md">
                            {/* Looping for font family */}
                            {
                              Object.entries(styles.fontFamily).map(([key,value] , i) => {
                                return <option key={i} style={{ fontFamily: value }}>{key}</option>;
                              })
                            }
                          </Form.Control>
                        </Form.Group>
                        <Form.Group as={Col}>
                          <Form.Label>Font Size</Form.Label>
                          <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                            </InputGroup.Prepend>
                            <Form.Control type="number" name="fontSize" min="10" max="40" onKeyPress={this.fontsize} value={fontSize} onChange={this.fontsize} />
                            <InputGroup.Append  >
                              <InputGroup.Text >px</InputGroup.Text>
                            </InputGroup.Append>
                            
                            {errors.fontSize ? (
                              <small className="text-danger">{errors.fontSize}</small>
                            ) : <Form.Text className="text-muted">Value must be 10-40px</Form.Text>}
                          </InputGroup>
                      
                        </Form.Group>
                      </Form.Row>
                      <Form.Group>
                        <label>Font Color</label>
                        <InputGroup size="sm">
                          <FormControl
                            placeholder="Font Color"
                            value={fontColor}
                            onChange={this.setFontColor}
                            maxLength="7"
                          />
                          <InputGroup.Append>
                            <Button variant="outline-secondary" onClick={this.fontColorSubmit} style={{backgroundColor:fontColor}}><i className="fa fa-eye-dropper"></i></Button>
                          </InputGroup.Append>
                        </InputGroup>
                        {this.state.displayFontColorPicker ? <SketchPicker disableAlpha color={fontColor} onChange={ this.changeFontColor }></SketchPicker> : null}
                      </Form.Group>
                    </Card.Body>
                  </Accordion.Collapse>
                  <Accordion.Toggle className="font-weight-bold" as={Card.Header} eventKey="4" onClick={() => this.toggleCollapse("collapse2")}>
                    <i className={collapseID === "collapse2" ? "fa fa-caret-down" : "fa fa-caret-right"}></i> Button
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="4">
                    <Card.Body>
                      <label>Type</label>
                      <CardDeck>
                        {Object.entries(styles.btnType).map(([key,value], idx) => {
                          return (
                            <label key={idx}>
                              <input type="radio" name="btn-type" value={value} onChange={this.btnType} checked={btnType === value} className="card-input-element" />
                              <Card className="small-card card-input">
                                <Button variant={value} size="sm" style={{ marginTop: 15, height: 30 }}>{key}</Button>
                              </Card>
                            </label>
                          );
                        })}
                      </CardDeck><hr></hr>
                      <Form.Group>
                        <label>Background Color</label>
                        <InputGroup size="md">
                          <FormControl
                            placeholder="Background Color"
                            value={color}
                            onChange={this.setColor}
                            maxLength="7"
                          />
                          <InputGroup.Append>
                            <Button variant="outline-secondary" onClick={this.colorSubmit} style={{backgroundColor:color}}><i className="fa fa-eye-dropper"></i></Button>
                          </InputGroup.Append>
                        </InputGroup>
                        {this.state.displayColorPicker ? <SketchPicker disableAlpha color={color} onChange={ this.changeColor }></SketchPicker> : null}
                      </Form.Group>
                      <hr></hr>
                      <label>Alignment</label>
                      <ButtonToolbar>
                        <ButtonGroup style={{width: "100%"}} size="sm">
                          <Button variant="secondary" active={alignment === "left"} onClick={this.setAlignment} value="left" className="iconleft border-right border-white"></Button>
                          <Button variant="secondary" active={alignment === "center"} onClick={this.setAlignment} value="center" className="iconcenter border-right border-white"></Button>
                          <Button variant="secondary" active={alignment === "right"} onClick={this.setAlignment} value="right" className="iconright border-right border-white"></Button>
                        </ButtonGroup>
                      </ButtonToolbar>
                      <Form.Group className="mt-3">
                        <Form.Label>Padding <span style={{ fontSize: 13 }} className="text-muted"> (Value must be 0-200px) </span> </Form.Label>
                        <Form.Row>
                          <Col xs={6}>                    
                            <Form.Label>Top</Form.Label>
                            <InputGroup className="mb-3">
                              <InputGroup.Prepend>
                              </InputGroup.Prepend>
                              <Form.Control type="number" min="0" max="200" value={customPaddingtop} onKeyPress={this.setCustompaddingtop} onChange={this.setCustompaddingtop} />
                              <InputGroup.Append>
                                <InputGroup.Text>px</InputGroup.Text>
                              </InputGroup.Append>
                            </InputGroup>
                          </Col>
                          <Col>
                            <Form.Label>Left</Form.Label>
                            <InputGroup className="mb-3">
                              <InputGroup.Prepend>
                              </InputGroup.Prepend>
                              <Form.Control type="number" min="0" max="200" value={customPaddingleft} onKeyPress={this.setCustompaddingleft} onChange={this.setCustompaddingleft} />
                              <InputGroup.Append>
                                <InputGroup.Text>px</InputGroup.Text>
                              </InputGroup.Append>
                            </InputGroup>
                          </Col>
                          <Col xs={6}>
                            <Form.Label>Bottom</Form.Label>
                            <InputGroup className="mb-3">
                              <InputGroup.Prepend>
                              </InputGroup.Prepend>
                              <Form.Control type="number" min="0" max="200" value={customPaddingbottom} onKeyPress={this.setCustompaddingbottom} onChange={this.setCustompaddingbottom} />
                              <InputGroup.Append>
                                <InputGroup.Text>px</InputGroup.Text>
                              </InputGroup.Append>
                            </InputGroup> </Col>
                          <Col>
                            <Form.Label>Right</Form.Label>
                            <InputGroup className="mb-3">
                              <InputGroup.Prepend>
                              </InputGroup.Prepend>
                              <Form.Control type="number" min="0" max="200" value={customPaddingright} onKeyPress={this.setCustompaddingright} onChange={this.setCustompaddingright} />
                              <InputGroup.Append>
                                <InputGroup.Text>px</InputGroup.Text>
                              </InputGroup.Append>
                            </InputGroup>
                          </Col>
                        </Form.Row>
                      </Form.Group>
                    </Card.Body>
                  </Accordion.Collapse>
                    
                </Card>
              </Accordion>
            </Card.Body>
          </Card>
        </Col>
            
      </React.Fragment>
    );
  }
}


const mapStateToProps = ({ button }) => ({
  color: button.color,
  btnType: button.btnType,
  fontFamily: button.fontFamily,
  fontSize: button.fontSize,
  alignment: button.alignment,
  fontColor: button.fontColor,
  errors: button.errors,
  customPaddingtop:button.customPaddingtop,
  customPaddingleft:button.customPaddingleft,
  customPaddingbottom:button.customPaddingbottom,
  customPaddingright:button.customPaddingright,
});


//props from redux
const mapDispatchToProps = dispatch => ({
  Color: data => dispatch(setBtnColor(data)),
  BtnType: data => dispatch(setBtnType(data)),
  HandleFontFamily: form => dispatch(fontType(form)),
  HandleFontSize: form => dispatch(fontSize(form)),
  Setalignment: form => dispatch(setAlignment(form)),
  FontColor: form => dispatch(fontColor(form)),
  SetcustomPaddingtop: form => dispatch(customPaddingtop(form)),
  SetcustomPaddingleft: form => dispatch(customPaddingleft(form)),
  SetcustomPaddingbottom: form => dispatch(customPaddingbottom(form)),
  SetcustomPaddingright: form => dispatch(customPaddingright(form)),
  ErrorsFetch: data => dispatch(errorsFetch(data))
});


//connecting from with redux
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Customize);
