/* eslint-disable no-undefined */
/* eslint-disable no-useless-concat */
import React, { Component } from "react";
import {Container, Button, Row, Col, Modal, Carousel } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import { putItems } from "../../../redux/components/reducer.action";
import { fetchContent, viewContent } from "../../../middleware/api";
import { initializeReactGA } from "../../../middleware/googleAnalytics";
import { connect } from "react-redux";
import Header from "../../layouts/navbar";
import { Redirect } from "react-router-dom";
import CircleLoader from "../../loader/loader";
import PostHeightIframe from "../../../middleware/sendHeight";
import "../../../assets/scss/hotspot.scss";
class Hotspot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewpage: this.props.location.pathname,
      show: false,
      label: "",
      text: null,
      libraryId: ""
    };
  }
  async componentDidMount(){
    const { PutItems } = this.props;
    const { id } = this.props.match.params;
    const { viewpage } = this.state;
    let content, response;

    if (
      viewpage === `/Hotspot/${id}` ||
      `/hotspot/${id}`
    ) {
      response = await viewContent(id);
    } else {
      if (
        this.props.match.params.user === undefined ||
        this.props.match.params.user === ""
      ) {
        content = sessionStorage.getItem("user_id");
      } else {
        content = this.props.match.params.user;
      }
      response = await fetchContent(id, content);
    }
    if (!response.message && response.library_id === 9) {
      //To check the tracking id of GA
      if(this.state.viewpage !== `/Hotspot/${id}` ) initializeReactGA(viewpage);
      PutItems({
        title: response.title,
        parameters: JSON.parse(response.parameters),
        customisations: response.customisations,
      });
      this.setState({libraryId: response.library_id});
    } else {
      this.props.history.push("/*");
    }
    this.props.parameters.map((content, index) => {
      const element = document.createElement("div");
      element.innerHTML = content.background;
      const image = element.getElementsByClassName("image-uploading");
      let source = image[0].src;
      let html = new Image();
      html.src = source;
      let imageDiv = document.getElementById(`hotspot_${index}`);
      content.dropZone.map((value)=>{
        html.addEventListener("load", function () {
          let hotspots = document.getElementById(value.data.label),
            top = (value.data.y/image[0].height)*100 +"%",
            left = (value.data.x/image[0].width)*100 +"%";
          hotspots.setAttribute("style", `left:${left};top:${top}`);
        });
        return null;
      });
      imageDiv.appendChild(html);
      return imageDiv;
    });
    if(this.state.viewpage === `/Hotspot/${id}` ) PostHeightIframe(response.library_id);
  }
//Edit functionality for accordion
handleEdit = () => {
  let path;
  if (this.props.match.params.user === undefined) {
    path = "/edit/Hotspot";
  } else {
    path = `/content/Hotspot/${this.props.match.params.user}`;
  }
  const { id } = this.props.match.params;
  if (id) {
    this.props.history.push({
      pathname: `${path}/${id}/${sessionStorage.getItem("org_id")}/${sessionStorage.getItem("loreeVersion")}`,
      state: {
        user:
          this.props.location.state !== undefined
            ? this.props.location.state.user
            : "",
        user_id:
          this.props.location.state !== undefined
            ? this.props.location.state.user_id
            : "",
      },
    });
  }
};
// To close the Hotspot modal
handleClose = () => {
  this.setState({ 
    show: false,
    label: "",
    text: ""
  });
}
// To open the Hotspot modal
handleShow = (label, text) => {
  this.setState({ 
    show: true,
    label: label,
    text: text
  });
}

handleSelect = (id) =>{
  if(this.state.viewpage === `/Hotspot/${id}` ) PostHeightIframe(this.state.libraryId);
}

render() {
  const { title, parameters, customisations } = this.props;
  const { id } = this.props.match.params;
  const icon = customisations.hotspotIcon;
  if (this.props.location.pathname === `/contentview/Hotspot/${this.props.match.params.user}/${id}` || 
    this.props.location.pathname === `/create/new/Hotspot/${id}` ){
    if(this.props.location.state ){
      return (
        <React.Fragment>
          <Header
            user={localStorage.getItem("user")}
            user_id={sessionStorage.getItem("user_id")}
            data={this.props}
          />        
          <Container className="margin">
            <Row className="d-flex justify-content-between mt-3">
              <Col style={{wordBreak:"break-word"}}>
                <h2>{title}</h2>
              </Col>
              <Col xs lg="1">
                <Button className="btn btn-dark" onClick={this.handleEdit}>
                    Edit
                </Button>
              </Col>
            </Row>
            { parameters === undefined || parameters.length === 0  ? <CircleLoader width={100} height={100} color={"black"}/> : 
              <div className="text-center m-5">
                <Carousel id="hotspot" controls={(parameters.length===1) ? false : true} indicators={false} interval={ null }>
                  {
                    parameters.map((content, idx) => {
                      return (
                        <Carousel.Item key={idx} 
                          eventkey={idx}>
                          <div className="hotspot" id={`hotspot_${idx}`}>
                            {
                              content.dropZone.map((dropArea, index)=>{
                                return <div key={`drop${index}`} className="hotspotIcon" title={dropArea.data.label}
                                  onClick={this.handleShow.bind(this,dropArea.data.label, ReactHtmlParser(dropArea.data.text))}
                                  id={dropArea.data.label} >
                                  <svg xmlns="http://www.w3.org/2000/svg" width={customisations.fontSize} height={customisations.fontSize} viewBox="0 0 30 30" >
                                    <defs>
                                      <clipPath>
                                        <rect width={customisations.fontSize} height={customisations.fontSize}/>
                                      </clipPath>
                                    </defs>
                                    <g>
                                      <path d={icon.draw} 
                                        transform = { icon.iconType === "Information" ? "translate(65)" :  icon.iconType === "Question" ? "translate(120 -10)"  : ""} 
                                        fill={customisations.bgcolor} stroke="rgba(0,0,0,0)" strokeMiterlimit="10" strokeWidth="50"
                                      />
                                    </g>
                                  </svg>
                                </div>;
                              })
                            }
                          </div>
                        </Carousel.Item>
                      );}
                    )}
                </Carousel>
                <Modal id="hotspotModal" show={this.state.show} onHide={this.handleClose.bind(this)} centered>
                  <Modal.Header closeButton>
                    <Modal.Title>{this.state.label}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body data-backdrop="static" data-keyboard="false" >{this.state.text}</Modal.Body>
                </Modal>
              </div>
            }
          </Container> 
        </React.Fragment>
      );
    }else {
      return <Redirect to="/401" />;
    }
  }else if (this.props.location.pathname === `/hotspot/${id}` || this.props.location.pathname === `/Hotspot/${id}`) {
    return(
      <React.Fragment>
        <Container>
          <div className="text-center m-5">
            <Carousel id="hotspot" controls={(parameters.length===1) ? false : true} indicators={false} interval={ null } onSelect={this.handleSelect.bind(this, id)}>
              {
                parameters.map((content, idx) => {
                  return (
                    <Carousel.Item key={idx}
                      eventkey={idx}>
                      <div className="hotspot" id={`hotspot_${idx}`}>
                        {
                          content.dropZone.map((dropArea, index)=>{
                            return <div key={`drop${index}`} className="hotspotIcon" title={dropArea.data.label}
                              onClick={this.handleShow.bind(this,dropArea.data.label, ReactHtmlParser(dropArea.data.text))}
                              id={dropArea.data.label} >
                              <svg xmlns="http://www.w3.org/2000/svg" width={customisations.fontSize} height={customisations.fontSize} viewBox="0 0 30 30" >
                                <defs>
                                  <clipPath>
                                    <rect width={customisations.fontSize} height={customisations.fontSize}/>
                                  </clipPath>
                                </defs>
                                <g>
                                  <path d={icon.draw} 
                                    transform = { icon.iconType === "Information" ? "translate(65)" :  icon.iconType === "Question" ? "translate(120 -10)"  : ""} 
                                    fill={customisations.bgcolor} stroke="rgba(0,0,0,0)" strokeMiterlimit="10" strokeWidth="50"
                                  />
                                </g>
                              </svg>
                            </div>;
                          })
                        }
                      </div>
                    </Carousel.Item>
                  );}
                )}
            </Carousel>
            <Modal id="hotspotModal" show={this.state.show} onHide={this.handleClose.bind(this)}>
              <Modal.Header closeButton>
                <Modal.Title>{this.state.label}</Modal.Title>
              </Modal.Header>
              <Modal.Body data-backdrop="static" data-keyboard="false" >{this.state.text}</Modal.Body>
            </Modal>
          </div>
        </Container> 
      </React.Fragment>
    );
  }
  else {
    return <Redirect to="/401" />;
  }
}
}

//state from redux
const mapStateToProps = ({ component }) => ({
  title: component.title,
  parameters: component.parameters,
  customisations: component.customisations
});

const mapDispatchToProps = dispatch => ({
  PutItems: component => dispatch(putItems(component))
});

//connecting form with redux
export default connect(mapStateToProps, mapDispatchToProps)(Hotspot);
