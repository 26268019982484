import React from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import Componentlist from "../components/library";
import InputForm from "../components/form";
import ContentList from "../components/contentList";
import AddButton from "../components/button/addButton";
import MultipleChoiceForm from "../components/multipleChoice/index";
import MultipleChoiceView from "../components/Interactives/multipleChoiceView";
import AccordionView from "../components/Interactives/accordionView";
import TabView from "../components/Interactives/tabView";
import ButtonView from "../components/button/buttonView";
import ImageSlider from "../components/Interactives/imagesliderView";
import FlipCard from "../components/flipCard/index";
import FlipCardView from "../components/flipCard/flipcard";
import HealthCheck from "../healthCheck";
import NotFoundPage from "../404";
import AccessDeniedPage from "../401";
import ICView from "../components/Interactives/interactiveCardView";
import DragandDrop from "../components/Interactives/draganddrop/index";
import Hotspot from "../components/Interactives/hotspot/index";
import S3Upload from "../middleware/s3Upload";

const Routing = () => {
  return (
    <React.Fragment>
      <BrowserRouter>
        <Switch>
          {/* Dashboard */}
          <Route exact path="/interactives/:user/:id/:version" component={Componentlist} />
          <Route path="/contents/:user/:id" component={ContentList} />
          {/* Component - Accordion/Tabs/imageslider*/}
          <Route exact path="/form/:type" component={InputForm} />
          <Route path="/edit/:type/:id/:orgId/:version" component={InputForm} />
          <Route path="/duplicate/:type/:user/:id/:orgId/:version" component={InputForm} />
          <Route path="/content/:type/:user/:id/:orgId/:version" component={InputForm} />
          <Route path="/create/new/accordion/:id" component={AccordionView} />
          <Route path="/contentview/Accordion/:user/:id" component={AccordionView} />
          <Route exact path="/accordion/:id" component={AccordionView} />
          <Route exact path="/tabs/:id" component={TabView} />
          <Route exact path="/tabs/:user/:id" component={TabView} />
          <Route path="/create/new/tabs/:id" component={TabView} />
          <Route path="/contentview/tabs/:user/:id" component={TabView} />
          <Route exact path="/imageslider/:id" component={ImageSlider} />
          <Route exact path="/imageslider/:user/:id" component={ImageSlider} />
          <Route path="/create/new/imageslider/:id" component={ImageSlider} />
          <Route path="/contentview/imageslider/:user/:id" component={ImageSlider} />
          <Route exact path="/ClickAndRevealInteractions/:id" component={ICView} />
          <Route exact path="/ClickAndRevealInteractions/:user/:id" component={ICView} />
          <Route path="/create/new/ClickAndRevealInteractions/:id" component={ICView} />
          <Route path="/contentview/ClickAndRevealInteractions/:user/:id" component={ICView}/>
          {/* Component - Button */}
          <Route exact path="/addButton" component={AddButton} />
          <Route path="/editButton/:id/:orgId/:version" component={AddButton} />
          <Route path="/button/edit/:user/:id/:orgId/:version" component={AddButton} />
          <Route path="/button/duplicate/:user/:id/:orgId/:version" component={AddButton} />
          <Route path="/create/button/:id" component={ButtonView} />
          <Route path="/create/tabs/:id" component={TabView} />
          <Route path="/contentview/button/:user/:id" component={ButtonView} />
          <Route exact path="/button/:id" component={ButtonView} />
          {/* Component - MCQ */}
          <Route exact path="/multiplechoice" component={MultipleChoiceForm} />
          <Route exact path="/editmcq/multiplechoice/:id/:orgId/:version" component={MultipleChoiceForm} />
          <Route exact path="/multiplechoice/edit/:user/:id/:orgId/:version" component={MultipleChoiceForm} />
          <Route exact path="/multiplechoice/duplicate/:user/:id/:orgId/:version" component={MultipleChoiceForm} />
          <Route exact path="/contentview/multiplechoice/:user/:id" component={MultipleChoiceView} />
          <Route path="/create/multiplechoice/:id" component={MultipleChoiceView} />
          <Route path="/multiplechoice/:id" component={MultipleChoiceView} />
          {/* Component - FlipCard */}
          <Route exact path="/flipcard" component={FlipCard} />
          <Route exact path="/editflipcard/:id/:orgId/:version" component={FlipCard} />
          <Route exact path="/flipcard/edit/:user/:id/:orgId/:version" component={FlipCard}/>
          <Route exact path="/flipcard/duplicate/:user/:id/:orgId/:version" component={FlipCard}/>
          <Route exact path="/create/flipcard/:id" component={FlipCardView} />
          <Route exact path="/contentview/flipcard/:user/:id" component={FlipCardView} />
          <Route exact path="/flipcard/:id" component={FlipCardView} />
          {/* Component - Drag and Drop */}
          <Route path="/create/new/DragandDrop/:id" component={DragandDrop} />
          <Route path="/contentview/DragandDrop/:user/:id" component={DragandDrop} />
          <Route exact path="/DragandDrop/:id" component={DragandDrop} />

          {/* Component - Hotspot*/}
          <Route path="/create/new/Hotspot/:id" component={Hotspot} />
          <Route path="/contentview/Hotspot/:user/:id" component={Hotspot} />
          <Route exact path="/Hotspot/:id" component={Hotspot} />

          {/* to upload images into s3 for existing content */}
          <Route path="/s3upload/:id" component={S3Upload} />

          {/* Access */}
          <Route path="/healthcheck" component={HealthCheck} />
          <Route path="/401" component={AccessDeniedPage} />
          <Route path="*" component={NotFoundPage} />
        </Switch>
      </BrowserRouter>
    </React.Fragment>
  );
};

export default Routing;
