/* eslint-disable no-undefined */
import React, {Component} from "react";
import { Button, Container, Table} from "react-bootstrap";
import { getComponentById, migrateImagesTos3, saveUpdatedContent} from "./api";
import swal from "sweetalert2";


class S3Upload extends Component {

  constructor(props) {
    super(props);
    this.state = {
      index: "",
      upload: false,
      contents: []
    };
  }

  async componentDidMount(){
    let data = await getComponentById(this.props.match.params.id);
    this.setState({contents:data});
  }
  handleMigrate = async (id, userId, orgId, parameters, customisations, index) =>{
    this.setState({
      index: index,
      upload: true
    });
    let migratedContent;
    if(this.props.match.params.id !== "8" && this.props.match.params.id !== "9" && this.props.match.params.id !== "5" && this.props.match.params.id !== "4" ) migratedContent =  await this.uploadBase64ToS3(userId, orgId, parameters);
    else if(this.props.match.params.id === "8" && (customisations.dndLayout === "Overlay" || customisations.dndLayout === "Fixed")) migratedContent = await this.uploadBase64ToS3(userId, orgId, parameters);
    else if(this.props.match.params.id === "8" || this.props.match.params.id === "9" ) migratedContent = await this.uploadImagetoS3(userId, orgId, parameters);
    else if(this.props.match.params.id === "4") migratedContent = await this.migrateMcqImages(userId, orgId, parameters);
    else migratedContent = await this.uploadFlipCardImages(userId, orgId, parameters);
    if(migratedContent !== null && migratedContent !== undefined){
      let inputDetails={
        id: id,
        parameters: migratedContent
      };
      const updatedContent = await saveUpdatedContent(inputDetails);
      if(updatedContent !== undefined){
        swal.fire({
          icon: "success",
          title: "Updated",
          text: "Images migrated to s3 sucessfully",
        });
        this.setState({
          index: "",
          upload: false
        });
      }
    }else{
      swal.fire({
        allowOutsideClick: false,
        icon: "info",
        text: "There are no images in this content",
      });
      this.setState({
        index: "",
        upload: false
      });
    }
  }

 uploadBase64ToS3 = async (userId, orgId, parameters) => {
   console.log("acc, tab, c&r, i/m, fixed"); /*will remove once the migration completed */
   const htmlContent = document.createElement("div");
   for (const element of parameters) {
     htmlContent.innerHTML = element.text;
     let imgWithBase64 = htmlContent.getElementsByTagName("img");
     imgWithBase64= Array.from(imgWithBase64);
     const replaceUrls = [];
     if(imgWithBase64.length > 0){
       for (const url of imgWithBase64) {
         if (url.hasAttribute("src")) {
           const path = url.getAttribute("src");
           if(path.startsWith("data:")){
             replaceUrls.push({url : path });
           }
         }
       }
     }
     if(replaceUrls.length > 0) {
       const inputDetails = {
         params: replaceUrls,
         userId: userId,
         orgId: orgId
       };
       let updatedUrls= await migrateImagesTos3(inputDetails);
       updatedUrls.forEach((s3Url, index ) => {
         const urlValue= imgWithBase64[index].getAttribute("src");
         if (urlValue && urlValue.startsWith("data:")) {
           imgWithBase64[index].setAttribute("src", s3Url);
         }
       });
       element.text = htmlContent.innerHTML;
     }
   }
   return parameters;
 };

 uploadImagetoS3 = async (userId, orgId, parameters) => {
   console.log("d&d, hotspot"); /*will remove once the migration completed */
   const htmlContent = document.createElement("div");
   for (const element of parameters) {
     htmlContent.innerHTML = element.background;
     let imgWithBase64 = htmlContent.getElementsByTagName("img");
     imgWithBase64= Array.from(imgWithBase64);
     const replaceUrls = [];
     if(imgWithBase64.length > 0){
       for (const url of imgWithBase64) {
         if (url.hasAttribute("src")) {
           const path = url.getAttribute("src");
           if(path.startsWith("data:")){
             replaceUrls.push({url : path });
           }
         }
       }
     }
     if(replaceUrls.length > 0) {
       const inputDetails = {
         params: replaceUrls,
         userId: userId,
         orgId: orgId
       };
       let updatedUrls= await migrateImagesTos3(inputDetails);
       updatedUrls.forEach((s3Url, index ) => {
         const urlValue= imgWithBase64[index].getAttribute("src");
         if (urlValue && urlValue.startsWith("data:")) {
           imgWithBase64[index].setAttribute("src", s3Url);
         }
       });
       element.background = htmlContent.innerHTML;
     }
   }
   return parameters;
 };

 uploadFlipCardImages = async (userId, orgId, parameters) => {
   console.log("flip"); /*will remove once the migration completed */
   const frontCardContent = document.createElement("div");
   const backCardContent = document.createElement("div");
   for (const element of parameters) {
     frontCardContent.innerHTML = element.fronttext;
     backCardContent.innerHTML = element.backtext;
     let imgInFontCard = frontCardContent.getElementsByTagName("img");
     imgInFontCard= Array.from(imgInFontCard);
     let imgInBackCard = backCardContent.getElementsByTagName("img");
     imgInBackCard= Array.from(imgInBackCard);

     const srcInFrontCard = [], srcInBackCard = [];
     if(imgInFontCard.length > 0){
       for (const url of imgInFontCard) {
         if (url.hasAttribute("src")) {
           const path = url.getAttribute("src");
           if(path.startsWith("data:")){
             srcInFrontCard.push({url : path });
           }
         }
       }
     }
     if(imgInBackCard.length > 0){
       for (const url of imgInBackCard) {
         if (url.hasAttribute("src")) {
           const path = url.getAttribute("src");
           if(path.startsWith("data:")){
             srcInBackCard.push({url : path });
           }
         }
       }
     }
     if(srcInFrontCard.length > 0 || srcInBackCard.length>0 ) {
       if(srcInFrontCard.length > 0 ){
         const inputDetails = {
           params: srcInFrontCard,
           userId: userId,
           orgId: orgId
         };
         let updatedUrls= await migrateImagesTos3(inputDetails);
         updatedUrls.forEach((s3Url, index ) => {
           const urlValue= imgInFontCard[index].getAttribute("src");
           if (urlValue && urlValue.startsWith("data:")) {
             imgInFontCard[index].setAttribute("src", s3Url);
           }
         });
         element.fronttext = frontCardContent.innerHTML;
       }
       if(srcInFrontCard.length > 0 ){
         const inputDetails = {
           params: srcInBackCard,
           userId: userId,
           orgId: orgId
         };
         let updatedUrls= await migrateImagesTos3(inputDetails);
         updatedUrls.forEach((s3Url, index ) => {
           const urlValue= imgInBackCard[index].getAttribute("src");
           if (urlValue && urlValue.startsWith("data:")) {
             imgInBackCard[index].setAttribute("src", s3Url);
           }
         });
         element.backtext = backCardContent.innerHTML;
       }
     }
   }
   return parameters;
 };

 migrateMcqImages = async (userId, orgId, parameters) => {
   console.log("mcq"); /*will remove once the migration completed */
   for (const element of parameters) {
     // TO migrate the images in question
     let questionContent = document.createElement("div");
     questionContent.innerHTML = element.question;
     let imgInQuestion = questionContent.getElementsByTagName("img");
     imgInQuestion = Array.from(imgInQuestion);
     const srcInQuestion = [];
     if(imgInQuestion.length > 0){
       for (const url of imgInQuestion) {
         if (url.hasAttribute("src")) {
           const path = url.getAttribute("src");
           if(path.startsWith("data:")){
             srcInQuestion.push({url : path });
           }
         }
       }
       if(srcInQuestion.length > 0){
         const inputDetails = {
           params: srcInQuestion,
           userId: userId,
           orgId: orgId
         };
         let updatedUrls= await migrateImagesTos3(inputDetails);
         updatedUrls.forEach((s3Url, index ) => {
           const urlValue= imgInQuestion[index].getAttribute("src");
           if (urlValue && urlValue.startsWith("data:")) {
             imgInQuestion[index].setAttribute("src", s3Url);
           }
         });
         element.question = questionContent.innerHTML;
       }
     }
    
     // TO migrate the images in options
     const options = element.options;
     for(const content of options){
       const optionContent = document.createElement("div");
       optionContent.innerHTML = content.option;
       let imgInoption = optionContent.getElementsByTagName("img");
       imgInoption = Array.from(imgInoption);
       const srcInoption = [];
       if(imgInoption.length > 0){
         for (const url of imgInoption) {
           if (url.hasAttribute("src")) {
             const path = url.getAttribute("src");
             if(path.startsWith("data:")){
               srcInoption.push({url : path });
             }
           }
         }
         if(srcInoption.length > 0){
           const inputDetails = {
             params: srcInoption,
             userId: userId,
             orgId: orgId
           };
           let updatedUrls= await migrateImagesTos3(inputDetails);
           updatedUrls.forEach((s3Url, index ) => {
             const urlValue= imgInoption[index].getAttribute("src");
             if (urlValue && urlValue.startsWith("data:")) {
               imgInoption[index].setAttribute("src", s3Url);
             }
           });
           content.option = optionContent.innerHTML;
         }
       }
     }
   }
   return parameters;
 }

 render(){
   return(  
     <React.Fragment>
       <Container className = "margin text-center">
         <Table striped bordered hover>
           <thead>
             <tr>
               <th>S.No</th>
               <th>OrgId</th>
               <th>ID</th>
               <th>Title</th>
               <th>Action</th>
             </tr>
           </thead>
           <tbody>
             {this.state.contents.map((list, index)=>{
               return(
                 <tr key={index}>
                   <td>{index+1}</td>
                   <td>{list.orgId}</td>
                   <td>{list.id}</td>
                   <td>{list.title}</td>
                   <td>
                     <Button disabled={this.state.upload} onClick = {this.handleMigrate.bind(this, list.id, list.userId, list.orgId, list.parameters, list.customisations, index)}>Migrate Images</Button>
                     {this.state.index===index ? <small className="ml-5 text-danger">Uploading <i className="fas fa-sync fa-spin"></i></small> : ""}
                   </td>
                 </tr>
               );
             })}
           </tbody>
         </Table>
       </Container>
     </React.Fragment>     
   );
 }
}

export default S3Upload;
