//handles add panel functionality
export const addPanel = data => ({
  type: "ADD_PANEL",
  parameters: data.parameters,
  contentArr: data.contentArr,
  num: data.num
});

//handles title value within the panel
export const handleChange = data => ({
  type: "HANDLE_CHANGE",
  parameters: data.parameters,
  errors: data.errors
});

export const rearangeArray = data => ({
  type: "REARANGE_ARRAY",
  parameters: data.parameters,
  contentArr: data.contentArr
});

//handles the value of main-title of the form
export const handleTitle = data => ({
  type: "HANDLE_TITLE",
  title: data.title
});

//handles the remove functionality for selected panel
export const removeClick = data => ({
  type: "REMOVE_CLICK",
  parameters: data.parameters,
  contentArr: data.contentArr,
  errors: data.errors
});

//validation for empty feildssta
export const errorsFetch = data =>({
  type:"ERRORS",
  errors: data.errors
});

export const makeEdit = data => ({
  type: "MAKE_EDIT",
  title: data.title,
  parameters: data.parameters, 
  contentArr: data.contentArr,
  component: data.type,
  fontHeadings:data.fontHeadings,
  fontFamily:data.fontFamily,
  fontSize:data.fontSize,
  fontWeight:data.fontWeight,
  radius: data.radius,
  bgcolor : data.bgcolor,
  icon : data.icon,
  alignment: data.alignment,
  fontColor: data.fontColor,
  borderType: data.borderType,
  borderColor: data.borderColor,
  borderSize: data.borderSize,
  borderStyle: data.borderStyle,
  setcaptionAlignment: data.setcaptionAlignment,
  iconColor:data.iconColor,
  iconSize: data.iconSize,
  iconAlignment: data.iconAlignment,
  iconType: data.iconType,
  dndLayout:data.dndLayout,
  dndParameters: data.dndParameters,
  dndArray: data.dndArray,
  dragAreaType: data.dragAreaType,
  paddingTop:data.paddingTop,
  paddingLeft:data.paddingLeft ,
  paddingBottom:data.paddingBottom,
  paddingRight:data.paddingRight,
  dropzoneBgColor: data.dropZoneBgColor,
  dropzoneOpacity: data.dropZoneOpacity,
  hotspotIcon: data.hotspotIcon
});

export const setType = data => ({
  type: "SET_TYPE",
  component: data.type
});

//To set the ID for the panels
export const setValue = data => ({
  type: "SET_VALUE",
  num: data.num,
  option_id: data.option_id
});

// fetching the values for customization
export const fontHeadings = data => ({
  type:"FONT_HEADINGS",
  component:data.fontHeadings
});

export const fontType = data => ({
  type:"FONT_TYPE",
  component:data.fontFamily
});

export const fontSize = data => ({
  type:"FONT_SIZE",
  component:data.fontSize
});

export const borderType = data => ({
  type:"CUSTOM_BORDER",
  borderType: data.borderType
});

export const setBorderColor = data => ({
  type:"BORDER_COLOR",
  borderColor: data.borderColor
});

export const setBorderSize = data => ({
  type:"BORDER_SIZE",
  component: data.borderSize
});

export const setBorderStyle = data => ({
  type:"BORDER_STYLE",
  component: data.borderStyle
});

export const fontWeight = data => ({
  type:"FONT_WEIGHT",
  component: data.fontWeight
});
export const setIcon = data => ({
  type: "SET_ICON",
  icon: data.icon
});
export const color1 = data => ({
  type: "CUSTOM_COLOR",
  color: data.color
});
export const setBgColor = data => ({
  type: "CUSTOM_BGCOLOR",
  bgcolor: data.bgcolor
});

export const radius = data => ({
  type: "CUSTOM_RADIUS",
  radius: data.radius
});
export const setAlignment = data => ({
  type: "CUSTOM_ALIGNMENT",
  alignment: data.alignment
});

export const fontColor = data => ({
  type: "CUSTOM_FONTCOLOR",
  fontColor: data.fontColor
});

export const setcaptionAlignment = data => ({
  type: "CUSTOM_CAPTIONALIGNMENT",
  setcaptionAlignment: data.setcaptionAlignment
});

export const setIconAlignment = data => ({
  type: "CUSTOM_ICON_ALIGNMENT",
  iconAlignment: data.iconAlignment
}); 

export const setIconColor = data => ({
  type:"ICON_COLOR",
  iconColor: data.iconColor
});

export const setIconSize = data => ({
  type: "ICON_SIZE",
  iconSize: data.iconSize
});

export const setIconType = data => ({
  type: "ICON_TYPE",
  iconType: data.iconType
});

export const setDragandDropLayout = data => ({
  type: "DRAG_AND_DROP_LAYOUT",
  dndLayout: data.dndLayout,
});

export const setParameter = data => ({
  type: "SET_BACKGROUND_IMG",
  dndParameters: data.dndParameters,
  errors: data.errors
});

export const handleErrors = data => ({
  type: "SET_ERROR",
  errors: data.errors
});

//for draganddrop
export const addSection = data => ({
  type: "ADD_SECTION",
  dndParameters: data.dndParameters,
  dndArray: data.dndArray,
  num: data.num
});

export const sortArray = data => ({
  type: "SORT_ARRAY",
  dndParameters: data.dndParameters,
  dndArray: data.dndArray
});

export const removeElement = data => ({
  type: "REMOVE_ELEMENT",
  dndParameters: data.dndParameters,
  dndArray: data.dndArray,
  errors: data.errors
});

export const setDragAreaType = data => ({
  type: "DRAG_AREA_TYPE",
  dragAreaType: data.dragAreaType
});

export const paddingTop = data => ({
  type: "CUSTOM_PADDINGTOP",
  paddingTop: data.paddingTop
});

export const paddingLeft = data => ({
  type: "CUSTOM_PADDINGLEFT",
  paddingLeft: data.paddingLeft
});

export const paddingBottom = data => ({
  type: "CUSTOM_PADDINGBOTTOM",
  paddingBottom: data.paddingBottom
});

export const paddingRight = data => ({
  type: "CUSTOM_PADDINGRIGHT",
  paddingRight: data.paddingRight
});

export const setDropzoneBgColor = data => ({
  type: "DROPZONE_BGCOLOR",
  dropzoneBgColor: data.dropzoneBgColor
});

export const setDropzoneOpacity = data => ({
  type:"DROPZONE_OPACITY",
  dropzoneOpacity:data.dropzoneOpacity
});

//for hotspot
export const setHotspotIcon = data => ({
  type: "SET_HOTSPOT_ICON",
  hotspotIcon: data.hotspotIcon
});
