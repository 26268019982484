// validation for the multiple choice input form
export const validateForm = (parameters, title, ErrorsFetch, contentArr, pathName) => {
  const fail = {};
  if (!title) {
    fail["title"] =  pathName.includes("/duplicate") ? "* DUPLICATED INTERACTIVE - Please enter a new title":  "* Interactive Title is required";
  }
  parameters.forEach((param, paramIndex) => {
    contentArr.forEach((contentArr_el, contentArr_index) => {
      // Need one option selected - removing this fail when one check is true at line:23
      if (paramIndex === contentArr_index) {
        fail[`opt_${contentArr_el[0]}`] = "* Atleast one correct option need to checked";
        if (param.question === "") {
          fail[`${contentArr_el[0]}`] = "* Question field is required";
        }
        param.options.forEach((option, optionIndex) => {
          if (!option.option) {
            fail[`${contentArr[paramIndex][1].options[optionIndex]}`] = "* Option field is required";
          }
          // Remove correct validation fail if anyone checkbox selected
          if (option.correct === true) {
            delete fail[`opt_${contentArr_el[0]}`];
          }
        });
      }
    });
  });
  ErrorsFetch({
    errors: fail
  });
  return fail;
};
