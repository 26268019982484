const styles = {
  // Font-headings for customization
  "fontHeadings": [
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "Normal"
  ],

  // Font-family for customization
  "fontFamily": {
    "Arial":"Arial",
    "Arial Black":"Arial Black",
    "Brush Script MT":"Brush Script MT",
    "Comic New Courier":"Comic New Courier",
    "Georgia":"Georgia",
    "Helvetica":"Helvetica",
    "Lucida":"Lucida",
    "Open Sans":"open Sans, sans-serif",
    "Sans Unicode":"Sans Unicode",
    "Tahoma":"Tahoma",
    "Times New Roman":"Times New Roman",
    "Trebuchet MS":"Trebuchet MS",
    "Verdana":"Verdana"
  },

  // Font-size for customization
  "fontSize": [
    "10",
    "12",
    "14",
    "16",
    "18",
    "20",
    "22",
    "24",
    "26",
    "28",
    "30",
    "32",
    "36",
    "40"
  ],

  // Font-weight for customisation
  "fontWeight": [
    "Bold",
    "Normal",
    "Lighter"
  ],

  // Accordion and Tabs Border-type for customization

  "borderType":{
    "border-left":"border-left border-dark",
    "border-right":"border-right border-dark",
    "border-top":"border-top border-dark",
    "border-bottom":"border-bottom border-dark",
    "border":"border border-dark"
  },

  // Accordion and Tabs bordersize for customization
  "borderSize": [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15"   
  ],

  // Accordion and Tabs borderstyle for customization
  "borderStyle": {
    "Solid" :"solid",
    "Dotted" :"dotted",
    "Dashed" :"dashed",
    "Double" :"double",
    "Groove" :"groove",
    "Ridge" :"ridge",
    "None" :"none",
  },

  // Button-type for customization
  "btnType": {
    "Square":"btn btn-square btn-dark",
    "Outline":"btn btn-outline-dark",
    "Pill":"btn btn-pill btn-dark"
  },
  
  // Button-size for customization
  "btnSize": {
    "Small":"sm",
    "Default":"default",
    "Large":"lg"
  },

  // Icon-size for customization
  "iconSize": {
    "Default":"10px",
    "Medium":"16px",
    "Large":"24px"
  },

  "icons" : {
    "Plus" : "M0,15A15,15,0,1,1,15,30,15,15,0,0,1,0,15Zm1.2,0A13.8,13.8,0,1,0,15,1.2,13.816,13.816,0,0,0,1.2,15Zm12.445,6.145V16.354H8.855a1.355,1.355,0,0,1,0-2.709h4.791V8.854a1.355,1.355,0,0,1,2.709,0v4.791h4.791a1.355,1.355,0,1,1,0,2.709H16.355v4.791a1.355,1.355,0,0,1-2.709,0Z",
    "Minus" : "M0,15A15,15,0,1,1,15,30,15,15,0,0,1,0,15Zm1.2,0A13.8,13.8,0,1,0,15,1.2,13.815,13.815,0,0,0,1.2,15Zm15.154,1.355h-7.5a1.355,1.355,0,1,1,0-2.71H21.146a1.355,1.355,0,0,1,0,2.71Z",
    "Information" : "M-65,15A15,15,0,0,1-50,0,15,15,0,0,1-35,15,15,15,0,0,1-50,30,15,15,0,0,1-65,15Zm1.2,0A13.816,13.816,0,0,0-50,28.8,13.816,13.816,0,0,0-36.2,15,13.816,13.816,0,0,0-50,1.2,13.816,13.816,0,0,0-63.8,15ZM-51.25,22.25V11.75A1.249,1.249,0,0,1-50,10.5a1.249,1.249,0,0,1,1.25,1.25v10.5A1.25,1.25,0,0,1-50,23.5,1.249,1.249,0,0,1-51.25,22.25ZM-51.5,8A1.5,1.5,0,0,1-50,6.5,1.5,1.5,0,0,1-48.5,8,1.5,1.5,0,0,1-50,9.5,1.5,1.5,0,0,1-51.5,8Z",
    "Exclamation" : "M0,15A15,15,0,1,1,15,30,15,15,0,0,1,0,15Zm2.223,0A12.777,12.777,0,1,0,15,2.222,12.777,12.777,0,0,0,2.223,15Zm12.552,8.19a1.125,1.125,0,0,1-.205-.063,1,1,0,0,1-.192-.1.925.925,0,0,1-.169-.139.975.975,0,0,1-.139-.167,1.137,1.137,0,0,1-.1-.192,1.086,1.086,0,0,1-.065-.644,1.046,1.046,0,0,1,.065-.207.86.86,0,0,1,.1-.191.842.842,0,0,1,.139-.17,1.05,1.05,0,0,1,.169-.139,1.08,1.08,0,0,1,.192-.1,1.1,1.1,0,0,1,1.208.241.89.89,0,0,1,.139.17.86.86,0,0,1,.1.191,1.263,1.263,0,0,1,.065.207,1.111,1.111,0,0,1,0,.436,1.365,1.365,0,0,1-.065.208.971.971,0,0,1-.1.192,1.1,1.1,0,0,1-.5.409,1.12,1.12,0,0,1-.208.063,1.172,1.172,0,0,1-.216.022,1.216,1.216,0,0,1-.22-.021ZM13.9,17.733V7.9a1.111,1.111,0,1,1,2.222,0v9.836a1.111,1.111,0,1,1-2.222,0Z",
    "Question" : "M-120,25a15,15,0,0,1,15-15A15,15,0,0,1-90,25a15,15,0,0,1-15,15A15,15,0,0,1-120,25Zm1.2,0A13.815,13.815,0,0,0-105,38.8,13.815,13.815,0,0,0-91.2,25,13.816,13.816,0,0,0-105,11.2,13.816,13.816,0,0,0-118.8,25Zm12.544,7.868a1.471,1.471,0,0,1-.408-1.068,1.535,1.535,0,0,1,.408-1.116,1.35,1.35,0,0,1,1.008-.419,1.388,1.388,0,0,1,1.02.419,1.51,1.51,0,0,1,.42,1.116,1.448,1.448,0,0,1-.42,1.068,1.389,1.389,0,0,1-1.02.42A1.35,1.35,0,0,1-106.256,32.868Zm.364-4.076a1.169,1.169,0,0,1-.279-.58h.006a4.052,4.052,0,0,1,.065-1.655,5.953,5.953,0,0,1,.564-1.392,7.7,7.7,0,0,1,.828-1.188q.468-.552.876-1.092a6.95,6.95,0,0,0,.684-1.093,2.638,2.638,0,0,0,.276-1.2,2.441,2.441,0,0,0-.588-1.644,2.172,2.172,0,0,0-1.741-.684,3.174,3.174,0,0,0-1.5.373,4.226,4.226,0,0,0-1.25,1.008l0,0a.783.783,0,0,1-.456.193c-.244,0-.309.053-.525-.1a.65.65,0,0,1-.338-.512,1.17,1.17,0,0,1,.181-.619l.005,0A6.719,6.719,0,0,1-107.3,17.2a4.754,4.754,0,0,1,2.34-.564,4.1,4.1,0,0,1,2.916,1.02,3.671,3.671,0,0,1,1.092,2.821,3.5,3.5,0,0,1-.288,1.44,6.665,6.665,0,0,1-.707,1.224q-.42.575-.888,1.139a13.109,13.109,0,0,0-.864,1.165,5.128,5.128,0,0,0-.612,1.283,3.63,3.63,0,0,0-.123,1.488h.007l-.005.023s0,.009,0,.014h0a1.219,1.219,0,0,1-.257.544.652.652,0,0,1-.55.208A.9.9,0,0,1-105.892,28.792Z"
  }
  
};

export default styles;
