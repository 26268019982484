import React, { Component } from "react";

export class healthCheck extends Component {
  render() {
    return (
      <React.Fragment>
        <div>
          Ok
        </div>
      </React.Fragment>
    );
  }
}

export default healthCheck;
