import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import "../../assets/scss/multipleChoice.scss";

class Mytips extends Component {
  constructor(props, context) {
    super(props, context);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = {
      show: false,
    };
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

  render() {
    return (
      <>
        <i className="fa fa-info-circle fa-lg hover" title="Show tip" aria-hidden="true" onClick={this.handleShow} style={{
          float:"right"
        }}></i>
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Tips</Modal.Title>
          </Modal.Header>
          <Modal.Body data-backdrop="static" data-keyboard="false" >{this.props.tip}</Modal.Body>
        </Modal>
      </>
    );
  }
}

export default Mytips;
