import React, {Component} from "react";
import Editor from "../../editor/editor";
class Hotspoteditor extends Component {

  constructor(props) {
    super(props);
    this.state = {
      upload: false
    };
  }

  handleUpload = () => { // to show the loader while uploading the image in s3
    const { upload } = this.state;
    this.setState({upload: !upload});
  }

  render(){
    return(  
      <Editor id="hotspot" type="Hotspoteditor" name="hotspoteditor" value={this.props.value} onChange={this.props.events}  handleUpload = {this.handleUpload}></Editor>       
    );
  }
}

export default Hotspoteditor;
