/* eslint-disable no-undefined */
/*eslint-disable no-useless-concat*/
import React from "react";
import { putItems } from "../../redux/components/reducer.action";
import { fetchContent, viewContent } from "../../middleware/api";
import { initializeReactGA } from "../../middleware/googleAnalytics";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import "./flipcard.scss";
import ReactHtmlParser from "react-html-parser";
import Header from "../layouts/navbar";
import {Row, Col, Button} from "react-bootstrap";
import PostHeightIframe from "../../middleware/sendHeight";

let content, response;
class FlipCardView extends React.Component {
 
  //Getting data from the Redux-state
  async componentDidMount() {
    const { PutItems } = this.props;
    const { id } = this.props.match.params;
    if(this.props.location.pathname === `Flipcard/${id}` || `flipcard/${id}`) {
      response = await viewContent(id);
    }
    else {
      if(this.props.match.params.user === undefined || this.props.match.params.user === "") {
        content = sessionStorage.getItem("user_id");
      } else {
        content = this.props.match.params.user;
      }
      response = await fetchContent(id, content);
    }
    if (!response.message && response.library_id === 5) {

      //To check the tracking id of GA
      if(this.props.location.pathname !== `/flipcard/${id}` ) initializeReactGA(this.props.location.pathname);

      PutItems({
        title: response.title,
        parameters: JSON.parse(response.parameters),
        customisations:response.customisations
      });
    } else {
      this.props.history.push("/*");
    }
    if(this.props.location.pathname === `/flipcard/${id}` ) PostHeightIframe(response.library_id);
  }

    //Edit functionality for accordion
    handleEdit = () => {
      let path;
      if (this.props.match.params.user === undefined) {
        path = "/editflipcard";
      } else {
        path = `/flipcard/edit/${this.props.match.params.user}`;
      }
      const { id } = this.props.match.params;
      if (id) {
        this.props.history.push({
          pathname: `${path}/${id}/${sessionStorage.getItem("org_id")}/${sessionStorage.getItem("loreeVersion")}`,
          state: {
            user:
              this.props.location.state !== undefined
                ? this.props.location.state.user
                : "",
            user_id:
              this.props.location.state !== undefined
                ? this.props.location.state.user_id
                : "",
          },
        });
      }
    };

    flip = (idx) => {
      var element = document.getElementById(idx);
      element.classList.toggle("flipped");
    }
  
    render() { // render flipcard view
      const { parameters, customisations, title } = this.props;
      const { id } = this.props.match.params;
      const viewpage = this.props.location.pathname;
      const noofcard= customisations.noOfCard === "" ? 4 : customisations.noOfCard;

      const borderleftStyle = (customisations.borderType === "border-left border-dark") ? true : false;

      const borderrightStyle = (customisations.borderType === "border-right border-dark") ? true : false;

      const bordertopStyle = (customisations.borderType === "border-top border-dark") ? true : false;  

      const borderbottomStyle = (customisations.borderType === "border-bottom border-dark") ? true : false;  

      const borderfullStyle = (customisations.borderType === "border border-dark") ? true : false; 

      const flipcardborderleftStyle = {
        borderLeft: `${customisations.borderSize}px`+`${customisations.borderStyle}`+`${customisations.borderColor}`
      };

      const flipcardborderrightStyle = {
        borderRight: `${customisations.borderSize}px ` + `${customisations.borderStyle} ` + `${customisations.borderColor}`
      };

      const flipcardbordertopStyle = {
        borderTop: `${customisations.borderSize}px ` + `${customisations.borderStyle} ` + `${customisations.borderColor}`
      };

      const flipcardborderbottomtStyle = {
        borderBottom: `${customisations.borderSize}px ` + `${customisations.borderStyle} ` + `${customisations.borderColor}`
      };

      const flipcardborderfullStyle = {
        border: `${customisations.borderSize}px ` + `${customisations.borderStyle} ` + `${customisations.borderColor}`
      };

      const frontcolor = {
        background : customisations.bgcolor,
        height : customisations.height+"px",
      };
  
      const flipcolor = {
        background : customisations.backColor,
        height : customisations.height+"px",
      };

      if(viewpage === `/flipcard/${id}`) {
        return(
          <React.Fragment>
            <div className="container">
              {customisations.flip === "1" ? 
                <div className="row"> {/*hover event*/}
                  {
                    parameters.map((content, idx) => (
                      <div className={`my-3 flip-card col-sm-${noofcard}`} key={idx}>
                        <div className={`flip-card-inner ${customisations.radius}`}>
                          <div className={`flip-card-front shadow ${customisations.radius} overflow-auto`} 
                            style = { (borderleftStyle) ? {...flipcardborderleftStyle,...frontcolor} : (borderrightStyle) ? 
                              {...flipcardborderrightStyle,...frontcolor} : (bordertopStyle) ? {...flipcardbordertopStyle,...frontcolor} : 
                                (borderbottomStyle) ? {...flipcardborderbottomtStyle,...frontcolor} : (borderfullStyle) ? 
                                  {...flipcardborderfullStyle,...frontcolor} : frontcolor}>
                            {ReactHtmlParser(content["fronttext"])}
                          </div>
                          <div className={`flip-card-back shadow ${customisations.radius} overflow-auto`} 
                            style = { (borderleftStyle) ? {...flipcardborderleftStyle,...flipcolor} : (borderrightStyle) ? 
                              {...flipcardborderrightStyle,...flipcolor} : (bordertopStyle) ? {...flipcardbordertopStyle,...flipcolor} : 
                                (borderbottomStyle) ? {...flipcardborderbottomtStyle,...flipcolor} : (borderfullStyle) ? 
                                  {...flipcardborderfullStyle,...flipcolor} : flipcolor}>
                            {ReactHtmlParser(content["backtext"])}
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </div>
                :
                <div className="mb-3 row"> {/*click event*/}
                  {
                    parameters.map((content,idx) => (
                      <div className={`my-3 flip col-sm-${noofcard}`} key={idx}>
                        <div id={idx} className={`inner ${customisations.radius}`} onClick={this.flip.bind(this,idx)} > 
                          <div className={`shadow front ${customisations.radius}`}
                            style = { (borderleftStyle) ? {...flipcardborderleftStyle,...frontcolor} : (borderrightStyle) ?
                              {...flipcardborderrightStyle,...frontcolor} : (bordertopStyle) ? {...flipcardbordertopStyle,...frontcolor} :
                                (borderbottomStyle) ? {...flipcardborderbottomtStyle,...frontcolor} : (borderfullStyle) ?
                                  {...flipcardborderfullStyle,...frontcolor} : frontcolor}>
                            {ReactHtmlParser(content["fronttext"])}
                          </div> 
                          <div className={`shadow back ${customisations.radius}`}  
                            style = { (borderleftStyle) ? {...flipcardborderleftStyle,...flipcolor} : (borderrightStyle) ? 
                              {...flipcardborderrightStyle,...flipcolor} : (bordertopStyle) ? {...flipcardbordertopStyle,...flipcolor} : 
                                (borderbottomStyle) ? {...flipcardborderbottomtStyle,...flipcolor} : (borderfullStyle) ? 
                                  {...flipcardborderfullStyle,...flipcolor} : flipcolor}>
                            {ReactHtmlParser(content["backtext"])}
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </div>
              }
            </div>
          </React.Fragment>
        );
      }
      else if(viewpage === `/create/flipcard/${id}` || `/contentview/flipcard/${this.props.match.params.user}/${id}`) {
        if (this.props.location.state !== undefined) {
          return(
            <React.Fragment>
              <Header user= {localStorage.getItem("user")}
                user_id= {sessionStorage.getItem("user_id")} 
                data={this.props}  
              />
              <div className="container margin">
                <Row className="d-flex justify-content-between mt-3">
                  <Col style={{wordBreak:"break-word"}}>
                    <h2>{title}</h2>
                  </Col>
                  <Col xs lg="1">
                    <Button className="btn btn-dark" onClick={this.handleEdit}>
                    Edit
                    </Button>
                  </Col>
                </Row>
                <div>
                </div>
                {customisations.flip === "1" ? 
                  <div className="row">{/*hover event*/}
                    {
                      parameters.map((content, idx) => (
                        <div className={`my-3 flip-card col-sm-${noofcard}`} key={idx}>
                          <div className={`flip-card-inner ${customisations.radius}`}>
                            <div className={`flip-card-front shadow ${customisations.radius} overflow-auto`} 
                              style = { (borderleftStyle) ? {...flipcardborderleftStyle,...frontcolor} : (borderrightStyle) ? 
                                {...flipcardborderrightStyle,...frontcolor} : (bordertopStyle) ? {...flipcardbordertopStyle,...frontcolor} : 
                                  (borderbottomStyle) ? {...flipcardborderbottomtStyle,...frontcolor} : (borderfullStyle) ? 
                                    {...flipcardborderfullStyle,...frontcolor} : frontcolor}>
                              {ReactHtmlParser(content["fronttext"])}
                            </div>
                            <div className={`flip-card-back shadow ${customisations.radius} overflow-auto`} 
                              style = { (borderleftStyle) ? {...flipcardborderleftStyle,...flipcolor} : (borderrightStyle) ? 
                                {...flipcardborderrightStyle,...flipcolor} : (bordertopStyle) ? {...flipcardbordertopStyle,...flipcolor} : 
                                  (borderbottomStyle) ? {...flipcardborderbottomtStyle,...flipcolor} : (borderfullStyle) ? 
                                    {...flipcardborderfullStyle,...flipcolor} : flipcolor}>
                              {ReactHtmlParser(content["backtext"])}
                            </div>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                  :
                  <div className="mb-3 row">{/*click event*/}
                    {
                      parameters.map((content,idx) => (
                        <div className={`my-3 flip col-sm-${noofcard}`} key={idx}>
                          <div id={idx} className={`inner ${customisations.radius}`} onClick={this.flip.bind(this,idx)} > 
                            <div className={`shadow front ${customisations.radius}`}
                              style = { (borderleftStyle) ? {...flipcardborderleftStyle,...frontcolor} : (borderrightStyle) ? 
                                {...flipcardborderrightStyle,...frontcolor} : (bordertopStyle) ? {...flipcardbordertopStyle,...frontcolor} : 
                                  (borderbottomStyle) ? {...flipcardborderbottomtStyle,...frontcolor} : (borderfullStyle) ? 
                                    {...flipcardborderfullStyle,...frontcolor} : frontcolor}> 
                              {ReactHtmlParser(content["fronttext"])}
                            </div> 
                            <div className={`shadow back ${customisations.radius}`}  
                              style = { (borderleftStyle) ? {...flipcardborderleftStyle,...flipcolor} : (borderrightStyle) ? 
                                {...flipcardborderrightStyle,...flipcolor} : (bordertopStyle) ? {...flipcardbordertopStyle,...flipcolor} : 
                                  (borderbottomStyle) ? {...flipcardborderbottomtStyle,...flipcolor} : (borderfullStyle) ? 
                                    {...flipcardborderfullStyle,...flipcolor} : flipcolor}> 
                              {ReactHtmlParser(content["backtext"])}
                            </div>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                }
              </div>
            </React.Fragment>
          );
        } else {
          return <Redirect to="/401" />;
        }
      }
    }
}

//state from redux
const mapStateToProps = ({ component }) => ({
  title: component.title,
  parameters: component.parameters,
  customisations: component.customisations
});

//props to redux
const mapDispatchToProps = dispatch => ({
  PutItems: component => dispatch(putItems(component))
});

//connecting form with redux
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FlipCardView);
