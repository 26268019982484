/* eslint-disable no-useless-concat */
/* eslint-disable no-undefined */
import React from "react";
import ReactHtmlParser from "react-html-parser";
import { putItems } from "../../redux/components/reducer.action";
import { Card, Accordion, Container, Button, Row, Col } from "react-bootstrap";
import { fetchContent, viewContent } from "../../middleware/api";
import { initializeReactGA } from "../../middleware/googleAnalytics";
import { connect } from "react-redux";
import Header from "../layouts/navbar";
import { Redirect } from "react-router-dom";
import PostHeightIframe from "../../middleware/sendHeight";

let content, response;

class AccordionComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewpage: this.props.location.pathname,
    };
  }
  //Getting data from the Redux-state
  async componentDidMount() {
    const { PutItems } = this.props;
    const { id } = this.props.match.params;
    if (
      this.props.location.pathname === `Accordion/${id}` ||
      `accordion/${id}`
    ) {
      response = await viewContent(id);
    } else {
      if (
        this.props.match.params.user === undefined ||
        this.props.match.params.user === ""
      ) {
        content = sessionStorage.getItem("user_id");
      } else {
        content = this.props.match.params.user;
      }
      response = await fetchContent(id, content);
    }
    if (!response.message && response.library_id === 1) {

      //To check the tracking id of GA
      if(this.state.viewpage !== `/Accordion/${id}` ) initializeReactGA(this.props.location.pathname);
      
      PutItems({
        title: response.title,
        parameters: JSON.parse(response.parameters),
        customisations: response.customisations,
      });
    } else {
      this.props.history.push("/*");
    }
    if(this.state.viewpage === `/Accordion/${id}` ) PostHeightIframe();
  }

  //Edit functionality for accordion
  handleEdit = () => {
    let path;
    if (this.props.match.params.user === undefined) {
      path = "/edit/Accordion";
    } else {
      path = `/content/Accordion/${this.props.match.params.user}`;
    }
    const { id } = this.props.match.params;
    if (id) {
      this.props.history.push({
        pathname: `${path}/${id}/${sessionStorage.getItem("org_id")}/${sessionStorage.getItem("loreeVersion")}`,
        state: {
          user:
            this.props.location.state !== undefined
              ? this.props.location.state.user
              : "",
          user_id:
            this.props.location.state !== undefined
              ? this.props.location.state.user_id
              : "",
        },
      });
    }
  };

  //Customisation functionality for plus/minus else up-arrow/down-arrow icon
  iconClick = (id, e) => {
    const { customisations } = this.props;
    const collapsed = e.target.parentNode.children[1].className === "collapse";
    if (customisations.icon === "plus/minus") {
      for (const x of e.target.parentNode.parentNode.children) {
        x.children[0].className = x.children[0].className.replace(
          "icon-minus",
          "icon-plus",
        );
      }
      if (collapsed) {
        e.target.className = e.target.className.replace(
          "icon-plus",
          "icon-minus",
        );
      } else {
        e.target.className = e.target.className.replace(
          "icon-minus",
          "icon-plus",
        );
      }
    } else {
      for (const x of e.target.parentNode.parentNode.children) {
        x.children[0].className = x.children[0].className.replace(
          "icon-down-arrow",
          "icon-right-arrow",
        );
      }
      if (collapsed) {
        e.target.className = e.target.className.replace(
          "icon-right-arrow",
          "icon-down-arrow",
        );
      } else {
        e.target.className = e.target.className.replace(
          "icon-down-arrow",
          "icon-right-arrow",
        );
      }
    }
    if(this.state.viewpage === `/Accordion/${id}` ) PostHeightIframe();
  };

  render() {
    // render accordion view
    const { title, parameters, customisations } = this.props;
    const { id } = this.props.match.params;
    const { viewpage } = this.state;
    const fontHeadings = ((this.props.customisations.fontHeadings !== "") ? this.props.customisations.fontHeadings : "div");

    const borderleftStyle = (customisations.borderType === "border-left border-dark") ? true : false;

    const borderrightStyle = (customisations.borderType === "border-right border-dark") ? true : false;

    const bordertopStyle = (customisations.borderType === "border-top border-dark") ? true : false;  

    const borderbottomStyle = (customisations.borderType === "border-bottom border-dark") ? true : false;  

    const borderfullStyle = (customisations.borderType === "border border-dark") ? true : false;  

    const accordionStyle = {
      fontFamily: `${customisations.fontFamily}`,
      fontWeight: `${customisations.fontWeight}`,
      backgroundColor: `${customisations.bgcolor}`,
      fontSize: `${customisations.fontSize}px`,
      textAlign: `${customisations.alignment}`,
      color: `${customisations.fontColor}`
    };
    
    const accordionborderleftStyle = {
      borderLeft: `${customisations.borderSize}px ` + `${customisations.borderStyle} ` + `${customisations.borderColor}`
    };

    const accordionborderrightStyle = {
      borderRight: `${customisations.borderSize}px ` + `${customisations.borderStyle} ` + `${customisations.borderColor}`
    };

    const accordionbordertopStyle = {
      borderTop: `${customisations.borderSize}px ` + `${customisations.borderStyle} ` + `${customisations.borderColor}`
    };

    const accordionborderbottomtStyle = {
      borderBottom: `${customisations.borderSize}px ` + `${customisations.borderStyle} ` + `${customisations.borderColor}`
    };

    const accordionborderfullStyle = {
      border: `${customisations.borderSize}px ` + `${customisations.borderStyle} ` + `${customisations.borderColor}`
    };

    if (viewpage === `/Accordion/${id}`) {
      return (
        <div className="m-2">
          <Accordion>
            {parameters.map((content, idx) => (
              <Card key={idx} className={customisations.radius}>
                <Accordion.Toggle
                  as={fontHeadings}
                  style={accordionStyle}
                  className={`card-header ${
                    customisations.icon === "plus/minus"
                      ? "icon-plus"
                      : "icon-right-arrow"
                  }`}
                  onClick={this.iconClick.bind(this,id)}
                  id={idx}
                  eventKey={idx}
                >
                  {content["title"]}
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={idx} style = {borderleftStyle ? accordionborderleftStyle : (borderrightStyle) ? accordionborderrightStyle : (bordertopStyle) ? accordionbordertopStyle : (borderbottomStyle) ? accordionborderbottomtStyle : (borderfullStyle) ? accordionborderfullStyle : null }>
                  <Card.Body className="ql-editor" border="primary">
                    {ReactHtmlParser(content["text"])}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            ))}
          </Accordion>
        </div>
      );
    } else if (
      viewpage === `/create/new/Accordion/${id}` ||
      `contentview/Accordion/${this.props.match.params.user}/${id}`
    ) {
      if (this.props.location.state !== undefined) {
        return (
          <React.Fragment>
            <Header
              user={localStorage.getItem("user")}
              user_id={sessionStorage.getItem("user_id")}
              data={this.props}
            />
            <Container className="margin">
              <Row className="d-flex justify-content-between mt-3">
                <Col style={{wordBreak:"break-word"}}>
                  <h2>{title}</h2>
                </Col>
                <Col xs lg="1">
                  <Button className="btn btn-dark" onClick={this.handleEdit}>
                    Edit
                  </Button>
                </Col>
              </Row>
              <div className="mt-3">
                <Accordion>
                  {parameters.map((content, idx) => (
                    <Card key={idx} className={customisations.radius}>
                      <Accordion.Toggle
                        as={fontHeadings}
                        style={accordionStyle}
                        className={`card-header ${
                          customisations.icon === "plus/minus"
                            ? "icon-plus"
                            : "icon-right-arrow"
                        } `}
                        onClick={this.iconClick.bind(this,id)}
                        id={idx}
                        eventKey={idx}
                      >
                        {content["title"]}
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey={idx} style = {borderleftStyle ? accordionborderleftStyle : (borderrightStyle) ? accordionborderrightStyle : (bordertopStyle) ? accordionbordertopStyle : (borderbottomStyle) ? accordionborderbottomtStyle : (borderfullStyle) ? accordionborderfullStyle : null }>
                        <Card.Body className="ql-editor border-right-0">
                          {ReactHtmlParser(content["text"])}
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  ))}
                </Accordion>
              </div>
            </Container>
          </React.Fragment>
        );
      } else {
        return <Redirect to="/401" />;
      }
    }
  }
}

//state from redux
const mapStateToProps = ({ component }) => ({
  title: component.title,
  parameters: component.parameters,
  customisations: component.customisations
});

//props to redux
const mapDispatchToProps = dispatch => ({
  PutItems: component => dispatch(putItems(component))
});

//connecting form with redux
export default connect(mapStateToProps, mapDispatchToProps)(AccordionComponent);
