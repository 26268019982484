/* eslint-disable no-useless-concat */
import React from "react";
import { Button, Modal } from "react-bootstrap";

class ViewButton extends React.Component {
  constructor(props)
  {
    super(props);
    this.state= {
      hover:false,
    };
  }

  //mouse enter into button
  hoverOn=()=>{
    this.setState({ hover: true });
  }

  //mouse leave from button
  hoverOff=()=>{ 
    this.setState({ hover: false });    
  }

  render() { //preview page render for button

    const isNewTab = this.props.content.isChecked ? "_blank" : "_top";
    const mouseover= this.props.content.btnType === "btn btn-outline-dark" ? true : false;

    const buttonHover = {
      background:`${this.props.content.color}`, 
      fontSize: `${this.props.content.fontSize}px`,
      border:"2px solid"+`${this.props.content.color}`,
      fontFamily: `${this.props.content.fontFamily}`, 
      color: `${this.props.content.fontColor}`,
      paddingTop: (this.props.content.customPaddingtop === "") ? 0 : `${this.props.content.customPaddingtop}px`,
      paddingLeft: (this.props.content.customPaddingleft === "") ? 0 : `${this.props.content.customPaddingleft}px`,
      paddingBottom: (this.props.content.customPaddingbottom === "") ? 0 : `${this.props.content.customPaddingbottom}px`,
      paddingRight: (this.props.content.customPaddingright === "") ? 0 : `${this.props.content.customPaddingright}px`
    };

    const buttonUnhover ={
      background: "white",
      color:`${this.props.content.color}`, 
      fontFamily: `${this.props.content.fontFamily}`, 
      fontSize: `${this.props.content.fontSize}px`, 
      border:"2px solid"+`${this.props.content.color}`,
      paddingTop: (this.props.content.customPaddingtop === "") ? 0 : `${this.props.content.customPaddingtop}px`,
      paddingLeft: (this.props.content.customPaddingleft === "") ? 0 : `${this.props.content.customPaddingleft}px`,
      paddingBottom: (this.props.content.customPaddingbottom === "") ? 0 : `${this.props.content.customPaddingbottom}px`,
      paddingRight: (this.props.content.customPaddingright === "") ? 0 : `${this.props.content.customPaddingright}px`
    };

    const buttonstyle ={
      background:`${this.props.content.color}`,  
      fontFamily: `${this.props.content.fontFamily}`, 
      fontSize: `${this.props.content.fontSize}px`, 
      color: `${this.props.content.fontColor}`,
      border:"2px solid"+`${this.props.content.color}`,
      paddingTop: (this.props.content.customPaddingtop === "") ? 0 : `${this.props.content.customPaddingtop}px`,
      paddingLeft: (this.props.content.customPaddingleft === "") ? 0 : `${this.props.content.customPaddingleft}px`,
      paddingBottom: (this.props.content.customPaddingbottom === "") ? 0 : `${this.props.content.customPaddingbottom}px`,
      paddingRight: (this.props.content.customPaddingright === "") ? 0 : `${this.props.content.customPaddingright}px`
    };

    return (
      <Modal
        {...this.props}
        size="lg"
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" style={{wordBreak:"break-word"}}>
            {this.props.content.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <React.Fragment>
            <div className={"text-" +`${this.props.content.alignment}`}>
              <div className="m-2">
                <a href={this.props.content.link} type="button" target={isNewTab} >
                  {mouseover ? <Button className={`${this.props.content.btnType}`} style={this.state.hover ? buttonHover : buttonUnhover } onMouseEnter={this.hoverOn} 
                    onMouseLeave={this.hoverOff}>{this.props.content.name}</Button>: <Button className={`${this.props.content.btnType}`} style={buttonstyle}>{this.props.content.name}</Button>  }     
                </a>
              </div>
            </div>
          </React.Fragment>
        </Modal.Body>
        <Modal.Footer>
          <Button variant= "btn btn-dark btn-sm btn-pill btn btn-primary" onClick={this.props.onHide}>Back to Editor</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ViewButton;
