import Quill from "quill";

let ImageBlot = Quill.import("formats/image");

export default class ImageBlotExtension extends ImageBlot {
  static create(value) {
    let node = super.create();
    node.setAttribute("class", value.class);
    node.setAttribute("src", value.src);
    return node;
  }
  
  static value(node) {
    return {
      class: node.getAttribute("class"),
      src: node.getAttribute("src"),
    };
  }
}
ImageBlot.blotName = "image";
ImageBlot.tagName = "img";
