/* eslint-disable no-undefined */
import React from "react";
import { Form, FormControl, Button, Container, Card, Row, Col } from "react-bootstrap";
import { sortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import arrayMove from "array-move";
import swal from "sweetalert2";
import { addPanel, handleChange, rearangeArray, handleTitle, errorsFetch, removeClick, makeEdit, setType, setValue} from "../../redux/flipCard/form.action";
import { connect } from "react-redux";
import { createContent, getContent, editContent } from "../../middleware/api";
import { initializeReactGA } from "../../middleware/googleAnalytics";
import Editor from "../editor/editor";
import Header from "../layouts/navbar";
import "../form/form.scss";
import FlipCardPreview from "./flipCardPreview";
import Customize from "./customize";
import { validateForm } from "../flipCard/validate";
import { Redirect } from "react-router-dom";

//Sorting the Items while drag and drop
const SortableItem = SortableElement(({ content }) => {
  return (<div>{content}</div>);
});

//Handle functionality while dragging in the panels
const DragHandle =  SortableHandle((props) =>
  <div className="d-block dragDropIcon">
    <div className="d-inline font-weight-bold"><i className="fa fa-arrows-alt"></i> Section {props.value}</div>
  </div>
);

//Allocate the space while drag and drop
const SortableContainer = sortableContainer(({ children }) => {
  return (<Card className="mt-3">{children}</Card>);
});

let content;
class InputForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: false,
      collapseID: "collapse1",
      pathName : "",
      alreadyExist: null,
      upload: false
    };
    this.toggleBox = this.toggleBox.bind(this);
  }

  // toggle the collapse ID for changing the accordion icon
  toggleBox() {
    const { opened } = this.state;
    this.setState({
      opened: !opened,
      collapseID: "collapse1"
    });
  }

  async componentDidMount() {
    const { MakeEdit, SetType, SetValue } = this.props;
    const { type, id } = this.props.match.params;

    this.setState({pathName: this.props.location.pathname});

    initializeReactGA(this.props.location.pathname);
    if (type) {
      SetType({
        type: type
      });
    }
    if (id) {
      if(this.props.match.params.user === undefined || this.props.match.params.user === "") {
        content =  sessionStorage.getItem("user_id");
      } else {
        content = this.props.match.params.user;
      }
      
      const org_id = sessionStorage.getItem("org_id");
      if(this.props.match.params.orgId !== undefined && (org_id !== undefined || org_id !== "") && this.props.match.params.orgId !== org_id){
        sessionStorage.setItem("org_id", this.props.match.params.orgId);
      }

      let data = await getContent(id, content);
      if(data.message === "Unauthorized User or Invalid Content") return this.props.history.push("/401");
      let contentArr = [], parameters = JSON.parse(data.parameters);
      parameters.forEach((el, i) => {
        contentArr.push([`title_${i}`, `fronttext_${i}`,`backtext_${i}`]);
        SetValue({
          num : i + 1
        });
      });
      
      this.setState({alreadyExist: this.state.pathName.includes("duplicate") ? "* DUPLICATED INTERACTIVE - Please enter a new title" : null});
      MakeEdit({
        title: this.state.pathName.includes("duplicate") ? "" : data.title,
        contentArr: contentArr,
        parameters: parameters,
        type: data.type,
        bgcolor: data.customisations.bgcolor,
        icon: data.customisations.icon,
        backColor: data.customisations.backColor,
        height: data.customisations.height,
        noOfCard: data.customisations.noOfCard,
        radius: data.customisations.radius,
        borderType: data.customisations.borderType,
        borderColor: data.customisations.borderColor,
        borderSize: data.customisations.borderSize ? data.customisations.borderSize : "1",
        borderStyle: data.customisations.borderStyle ? data.customisations.borderStyle :"solid",
        flip: data.customisations.flip
      });
    }
  }


  //dynamically adding panel functionality
  addpanel = () => {
    const { addPanels } = this.props;
    addPanels({
      parameters: [...this.props.parameters, { "title": "", "fronttext": "", "backtext": "" }],
      contentArr: [...this.props.contentArr, [`title_${this.props.num + 1}`, `fronttext_${this.props.num + 1}`,`backtext_${this.props.num + 1}`]],
      num: this.props.num + 1
    });
  }

  //value from title within the panel
  handleChange = (i, event) => {
    const { errors, parameters, HandleChange} = this.props;
    const { value } = event.target;
    let fail = errors;
    if (value.length < 256) {
      let content = [...parameters];
      content[i] = { ...content[i], "title": !value.trim() ? "" : value };
      HandleChange({
        parameters: content,
        errors: fail
      });
    }
  }

  handleEditor = (i, value) => {
    const { errors, parameters, HandleChange } = this.props;
    let fail = errors;
    let content = parameters;
    // eslint-disable-next-line no-useless-escape
    let refinedValue = value.replace(/\>\s+\</g,"><br><");
    content[i] = { ...content[i], "fronttext": refinedValue };
    HandleChange({
      parameters: content,
      errors: fail
    });
  }

  handleEditorText = (i, value) => {
    const { errors, parameters, HandleChange } = this.props;
    let fail = errors;
    let content = parameters;
    // eslint-disable-next-line no-useless-escape
    let refinedValue = value.replace(/\>\s+\</g,"><br><");
    content[i] = { ...content[i], "backtext": refinedValue };
    HandleChange({
      parameters: content,
      errors: fail
    });
  }

  // value from main-title
  handleTitle = event => {
    const { HandleTitle } = this.props;
    const { value } = event.target;
    if (this.state.pathName.includes("/duplicate")){
      let data = this.props.location.state.contentList.filter(function(el) { return el.title === event.target.value;});
      this.setState({ 
        alreadyExist :  value === "" ? "* DUPLICATED INTERACTIVE - Please enter a new title" : data.length !== 0 ? 
          "* This title already exists. Please enter a new title" :null 
      });
    }
    if (value.length < 256) {
      HandleTitle({
        title: !value.trim() ? "" : value 
      });
    }
  }
 
  // remove the selected panel
  removeClick = (index, element) => {
    const { errors, parameters, contentArr, RemoveCLick } = this.props;
    swal.fire({
      allowOutsideClick: false,
      title: "Are you sure you wish to remove this panel?",
      type: "question",
      showCancelButton: true,
      cancelButtonText: "Cancel",
    })
      .then((isConfirm) => {
        if (isConfirm.value) {
          let content = [...parameters];
          content.splice(index, 1);
          let contentArray = [...contentArr];
          contentArray.splice(index, 1);
        
          //removes value if stored in error-object it is stored in error object,by submitting without value 
          let errorObject = errors;
          delete errorObject[element[0]];
          delete errorObject[element[1]];
          RemoveCLick({
            parameters: content,
            contentArr: contentArray,
            errors: errorObject
          });
        }
      });
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { RearangeArray, parameters, contentArr } = this.props;
    RearangeArray({
      contentArr: arrayMove(contentArr, oldIndex, newIndex),
      parameters: arrayMove(parameters, oldIndex, newIndex)
    });
  }

  removeEmptyTags = (parameters) => {
    parameters.forEach((content) => {
      const frontWrapper = document.createElement("div");
      const backWrapper = document.createElement("div");
      frontWrapper.innerHTML = content.fronttext;
      backWrapper.innerHTML = content.backtext;
      let bool = false;
      let boolean = false;
      Array.from( frontWrapper.childNodes).forEach((child) => {
        if(child.outerHTML !== "<p><br></p>") {
          bool = true;
          return;
        }
        else if (child.outerHTML === "<p><br></p>" && !bool)  {
          child.remove();
        }
      });
      Array.from( frontWrapper.childNodes).forEach((child) => {
        if(child.outerHTML !== "<p><br></p>") {
          boolean = true;
          return;
        }
        else if (child.outerHTML === "<p><br></p>" && !boolean)  {
          child.remove();
        }
      });
      content.fronttext = frontWrapper.innerHTML;
      content.backtext = backWrapper.innerHTML;
    });
  };

  //sumbit the form values to database and send response to preview page
  handleSubmit = async event => {
    const { parameters, title, ErrorsFetch, contentArr, bgcolor, backColor, height, noOfCard, radius, borderType, borderColor, borderSize, borderStyle, flip} = this.props;
    const { id } = this.props.match.params;
    let response = {};
    let user_id;
    if(this.props.match.params.user === undefined || this.props.match.params.user === "") {
      user_id =  sessionStorage.getItem("user_id") ;
    } else {
      user_id = this.props.match.params.user;
    }
    event.preventDefault();
    this.removeEmptyTags(parameters);
    let fail = validateForm(parameters, title, ErrorsFetch, contentArr, this.state.pathName);
    let dupicatetitle = fail.title === "* DUPLICATED INTERACTIVE - Please enter a new title"  ? delete fail["title"] : null;  
    if (Object.keys(fail).length === 0 && this.state.alreadyExist === null) {
      let data = {
        user_id: user_id,
        library_id: 5,
        organization_id: sessionStorage.getItem("org_id"),
        title: title,
        parameters: JSON.stringify(parameters),
        customisations: {
          "bgcolor": bgcolor,
          "backColor": backColor,
          "height": height,
          "noOfCard": noOfCard,
          "radius": radius,
          "borderType": borderType,
          "borderColor": borderColor,
          "borderSize": borderSize,
          "borderStyle": borderStyle,
          "flip": flip
        },
        active : true,
        private: true
      };
      if (id) {
        const { pathName } = this.state;
        if (pathName.includes("/duplicate") && dupicatetitle === null) {
          response = await createContent(data);
          swal.fire({
            allowOutsideClick: false,
            title: "Duplicated",
            text: "Content Duplicated Successfully.",
            icon: "success",
            showConfirmButton: true
          });
          this.props.history.push({
            pathname: `/contentview/flipcard/${this.props.match.params.user}/${response.id}`,
            state: {
              user: this.props.match.params.orgId,
              user_id: this.props.match.params.user
            }
          });
        } else{
          response.id = id;
          await editContent({
            id,
            body: data
          });
          swal.fire({
            allowOutsideClick: false,
            title: "Updated",
            text: "Content updated Successfully.",
            icon: "success",
            showConfirmButton: true
          });
          if(this.props.match.params.user === undefined || this.props.match.params.user === "") {
            this.props.history.push({
              pathname: `/create/flipcard/${response.id}`,
              state:{
                user: localStorage.getItem("user"),
                user_id: sessionStorage.getItem("user_id"),
              }
            });
          } else {
            this.props.history.push({
              pathname:`/contentview/flipcard/${this.props.match.params.user}/${response.id}`,
              state:{
                user: localStorage.getItem("user"),
                user_id: sessionStorage.getItem("user_id"),
              }
            });
          }
        }
      }
      else {
        response = await createContent(data);
        this.props.history.push({
          pathname:`/create/flipcard/${response.id}`,
          state:{
            user: localStorage.getItem("user"),
            user_id: sessionStorage.getItem("user_id"),
          }
        });  
      }
    }
  }

  modalToggle = (event) =>{
    const { parameters, title, ErrorsFetch, contentArr } = this.props;
    event.preventDefault();
    this.removeEmptyTags(parameters);
    let fail = validateForm(parameters, title, ErrorsFetch, contentArr, this.state.pathName);
    if (Object.keys(fail).length === 0) {
      this.setState({Modelshow:true});
    }
  }

  Modelclose = () =>{
    this.setState({Modelshow:false});
  }

  handleUpload = () => { // to show the loader while uploading the image in s3
    const { upload } = this.state;
    this.setState({upload: !upload});
  }

  render() { // users view area 
    const { errors, contentArr, parameters, title } = this.props;
    const { id } = this.props.match.params;
    const { opened, upload } = this.state;
    let pathName = this.props.location.pathname;


    //To display the customise and preview button based on the version
    let loreeVersion = sessionStorage.getItem("loreeVersion");
    let rowClass = (loreeVersion === "1") ? "flex-row-reverse" : "flex-row";
    
    if(id){
      
      //To display the customise and preview button based on the version
      if (loreeVersion === null) {
        sessionStorage.setItem("loreeVersion", this.props.match.params.version);
        loreeVersion = sessionStorage.getItem("loreeVersion");
        rowClass = (loreeVersion === "1") ? "flex-row-reverse" : "flex-row";
      } 

      // to find the parent tab to restrict the user to access interactive outside loree
      if(window.location !== window.parent.location){
        const user_id = localStorage.getItem("user_id");
        sessionStorage.setItem("user_id", user_id);
      } 
    }
    
    if(sessionStorage.getItem("user_id") === null) return <Redirect to="/401" />;
    else {
      return (
        <React.Fragment>
          <Header user= {localStorage.getItem("user")}
            user_id= {sessionStorage.getItem("user_id")} 
            data={this.props}  
          />
          <Container className="margin">
            <div className={`d-flex ${rowClass} my-3`}>
              <Button className="m-1 btn-pill mw-100" onClick={this.toggleBox} size="sm" variant="dark">
                Customize <i className={!opened ? "fa fa-plus" : "fa fa-minus"}></i>
              </Button>
              <Button className="m-1 btn-pill mw-100" disabled={upload} onClick={this.modalToggle} size="sm" variant="light">
                Preview
              </Button>
              <FlipCardPreview content= {this.props}
                show={this.state.Modelshow}
                onHide={this.Modelclose}
              /> 
            </div>
            <Row>
              {opened && (loreeVersion === "2") && (
                <Customize content={this.props} />
              )}
              <Col>
                <Form onSubmit={this.handleSubmit.bind(this)} >
                  <Card>
                    <Card.Header className="text-capitalize h4 font-weight-bold header">
                      Interactive Content Builder <span className="h4"> | </span><span style={{ color: "#0B099C" }}>FlipCard</span>
                    </Card.Header>
                    <Card.Body>
                      <Form.Group>
                        <Form.Label>My Interactive Title</Form.Label>
                        <FormControl name="title" id="title" onChange={this.handleTitle} value={title} autoComplete="off" />
                        {pathName.includes("/duplicate") && this.state.alreadyExist ? <Form.Text className="text-danger">{this.state.alreadyExist}</Form.Text> : null}
                        {errors.title ? <Form.Text className="text-danger">{errors.title}</Form.Text> : null}
                      </Form.Group>
                      <SortableContainer onSortEnd={this.onSortEnd} onSortStart={(_, event) => event.preventDefault()} useDragHandle helperClass="SortableHelper">
                        {contentArr.map((panel, index) => {
                          return (
                            <SortableItem id={index} key={index} panel={panel} index={index} sortIndex={index} content={
                              <React.Fragment>
                                <Card className="m-2">
                                  <Card.Header className="text-capitalize" key={index} id={panel}>
                                    {(() => {
                                      return (contentArr.length > 1) ? <Button variant="outline-danger" size="sm" title="Remove item!" onClick={this.removeClick.bind(this, index, panel)} className="float-right close-btn">×</Button> : null;
                                    })()}
                                    <DragHandle  value={`${index + 1}`}/>
                                  </Card.Header>
                                  <Card.Body>
                                    <Form.Group>
                                      <Form.Label htmlFor={panel[0]}>Section Header</Form.Label>
                                      <FormControl id={panel[0]} name={panel[0]} type='text' onChange={this.handleChange.bind(this, index)} distance={1} value={parameters[index]["title"]} autoComplete="off"/>
                                      {errors[`${panel[0]}`] ? <Form.Text className="text-danger">{errors[`${panel[0]}`]}</Form.Text> : null}
                                    </Form.Group>
                                    <Form.Group className="mb-0">
                                      <Form.Label htmlFor={panel[1]}>Front View</Form.Label>
                                      <Editor id={panel[1]} name={panel[1]} onChange={this.handleEditor.bind(this, index)} value={parameters[index]["fronttext"]} handleUpload = {this.handleUpload}/>
                                      {errors[`${panel[1]}`] ? <Form.Text className="text-danger">{errors[`${panel[1]}`]}</Form.Text> : null}
                                    </Form.Group>
                                    <Form.Group className="mb-0">
                                      <Form.Label htmlFor={panel[2]}>Back View</Form.Label>
                                      <Editor id={panel[2]} name={panel[2]} onChange={this.handleEditorText.bind(this, index)} value={parameters[index]["backtext"]} handleUpload = {this.handleUpload}/>
                                      {errors[`${panel[2]}`] ? <Form.Text className="text-danger">{errors[`${panel[2]}`]}</Form.Text> : null}
                                    </Form.Group>
                                  </Card.Body>
                                </Card>
                              </React.Fragment>
                            }
                            />
                          );
                        })}
                      </SortableContainer>
                      {(() => {
                        let addPanel = [];
                        if (contentArr.length < 100) {
                          addPanel.push(
                            <Button key="addPanel" variant="outline-secondary" className="mt-3" onClick={this.addpanel} size="sm">Add Panel</Button>
                          );
                          return addPanel;
                        }
                      })()}
                    </Card.Body>
                    <Card.Footer>
                      <Button className="btn btn-dark btn-sm btn-pill" type="submit" onClick={this.handleSubmit.bind(this)}>
                        {(id && pathName.includes("/duplicate")) ? "Create" : id ? "Update" :"Create"}
                      </Button>
                    </Card.Footer>
                  </Card>
                </Form>
              </Col>
              {opened && (loreeVersion === "1") && (
                <Customize content={this.props} />
              )}
            </Row>
          </Container>
        </React.Fragment>
      );
    }
  }
}

//state from redux
const mapStateToProps = ({ flipform }) => ({
  num: flipform.num,
  contentArr: flipform.contentArr,
  title: flipform.title,
  parameters: flipform.parameters,
  errors: flipform.errors,
  type: flipform.type,
  bgcolor: flipform.bgcolor,
  backColor: flipform.backColor,
  height: flipform.height,
  noOfCard: flipform.noOfCard,
  radius: flipform.radius,
  borderType: flipform.borderType,
  borderColor: flipform.borderColor,
  borderSize: flipform.borderSize,
  borderStyle: flipform.borderStyle,
  flip: flipform.flip
});

//props to redux
const mapDispatchToProps = dispatch => ({
  addPanels: form => dispatch(addPanel(form)),
  HandleChange: form => dispatch(handleChange(form)),
  RearangeArray: form => dispatch(rearangeArray(form)),
  HandleTitle: form => dispatch(handleTitle(form)),
  ErrorsFetch: form => dispatch(errorsFetch(form)),
  RemoveCLick: form => dispatch(removeClick(form)),
  MakeEdit: form => dispatch(makeEdit(form)),
  SetType: form => dispatch(setType(form)),
  SetValue: form => dispatch(setValue(form)),
});

//connecting form with redux
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InputForm);
