/* eslint-disable no-undefined */
import React from "react";
import { Form, FormControl, Button, Container, Card, Row, Col } from "react-bootstrap";
import { sortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import arrayMove from "array-move";
import swal from "sweetalert2";
import { addPanel, handleChange, rearangeArray, handleTitle, errorsFetch, removeClick, makeEdit, setType, 
  setValue, setParameter, addSection, sortArray, removeElement } from "../../redux/form/form.actions";
import { connect } from "react-redux";
import { createContent, getContent, editContent } from "../../middleware/api";
import { initializeReactGA } from "../../middleware/googleAnalytics";
import Editor from "../editor/editor";
import Header from "../layouts/navbar";
import "../form/form.scss";
import AccordionPreview from "../Interactives/accordionPreview";
import Customize from "./customize";
import TabPreview from "../Interactives/tabPreview";
import ImagesliderPreview from "../Interactives/imagesliderPreview";
import { validateForm } from "./validate";
import { Redirect } from "react-router-dom";
import InteractiveCardPreview from "../Interactives/interactiveCardPreview";
import DragandDrop from "../Interactives/draganddrop/dragDropPreview";
import {shuffleOptions} from "../Interactives/shuffle";
import DnDCustomize from "../Interactives/draganddrop/customize";
import HotspotCustomize from "../Interactives/hotspot/customize";
import ReactHtmlParser from "react-html-parser";
import HotspotPreview from "../Interactives/hotspot/hotspotPreview";

let content;

//Sorting the Items while drag and drop
const SortableItem = SortableElement(({ content }) => {
  return (<div>{content}</div>);
});

//Handle functionality while dragging in the panels
const DragHandle = SortableHandle((props) => {
  return (<div className="d-block dragDropIcon">
    <div className="d-inline font-weight-bold"><i className="fa fa-arrows-alt"></i> {props.type} {props.value}</div>
  </div>);
}
);

//Allocate the space while drag and drop
const SortableContainer = sortableContainer(({ children }) => {
  return (<Card className="mt-3">{children}</Card>);
});

class InputForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      opened: false,
      collapseID: "collapse1",
      pathName : "",
      alreadyExist: null,
      Modelshow: false,
      dragText: [],
      imagesrc: [],
      upload: false
    };
    this.toggleBox = this.toggleBox.bind(this);
  }

  // toggle the collapse ID for changing the accordion icon
  toggleBox() {
    const { opened } = this.state;
    this.setState({
      opened: !opened,
      collapseID: "collapse1"
    });
  }


  async componentDidMount() {
    const { MakeEdit, SetType, SetValue } = this.props;
    const { type, id } = this.props.match.params;

    this.setState({pathName: this.props.location.pathname});
    initializeReactGA(this.props.location.pathname);
    
    if (type) {
      SetType({
        type: type
      });
    }
    if (id) {
      if (this.props.match.params.user === undefined || this.props.match.params.user === "") {
        content = sessionStorage.getItem("user_id");
      } else {
        content = this.props.match.params.user;
      }
      const org_id = sessionStorage.getItem("org_id");
      if(this.props.match.params.orgId !== undefined && (org_id !== undefined || org_id !== "") && this.props.match.params.orgId !== org_id){
        sessionStorage.setItem("org_id", this.props.match.params.orgId);
      }
      let data = await getContent(id, content);
      if (data.message === "Unauthorized User or Invalid Content") return this.props.history.push("/401");
      let contentArr = [], dndArray=[] , parameters = JSON.parse(data.parameters);
      parameters.forEach((el, i) => {
        dndArray.push([`background_${i}`, `text_${i}`]);
        contentArr.push([`title_${i}`, `text_${i}`]);
        SetValue({
          num: i + 1
        });
      });
      this.setState({alreadyExist: this.state.pathName.includes("duplicate") ? "* DUPLICATED INTERACTIVE - Please enter a new title" : null});
      MakeEdit({
        title: this.state.pathName.includes("duplicate") ? "" : data.title,
        contentArr: contentArr,
        parameters: parameters,
        type: data.type,
        radius: data.customisations.radius,
        fontHeadings: data.customisations.fontHeadings,
        fontFamily: data.customisations.fontFamily ? data.customisations.fontFamily : "Helvetica",
        fontSize: data.customisations.fontSize !== "" ? data.customisations.fontSize : "16",
        fontWeight: data.customisations.fontWeight ? data.customisations.fontWeight : "Normal",
        bgcolor: data.customisations.bgcolor ? data.customisations.bgcolor : "#F2F2F2",
        icon: data.customisations.icon,
        alignment: data.customisations.alignment,
        fontColor: data.customisations.fontColor ? data.customisations.fontColor : "#000000",
        borderType: data.customisations.borderType,
        borderColor: data.customisations.borderColor,
        borderSize: data.customisations.borderSize ? data.customisations.borderSize : "1",
        borderStyle: data.customisations.borderStyle ? data.customisations.borderStyle : "solid",
        setcaptionAlignment: data.customisations.setcaptionAlignment,
        iconColor: data.customisations.iconColor,
        iconSize: data.customisations.iconSize,
        iconAlignment: data.customisations.iconAlignment,
        iconType: data.customisations.iconType,
        dndLayout: data.customisations.dndLayout,
        dndParameters: parameters,
        dndArray: dndArray,
        dragAreaType: data.customisations.dragAreaType ? data.customisations.dragAreaType : "btn btn-square btn-dark",
        paddingTop:data.customisations.paddingTop ? data.customisations.paddingTop : "10",
        paddingLeft:data.customisations.paddingLeft ? data.customisations.paddingLeft: "10",
        paddingBottom:data.customisations.paddingBottom ? data.customisations.paddingBottom : "10",
        paddingRight:data.customisations.paddingRight ? data.customisations.paddingRight : "10",
        dropZoneBgColor:data.customisations.dropZoneBgColor ? data.customisations.dropZoneBgColor : "#F2F2F2",
        dropZoneOpacity:data.customisations.dropZoneOpacity ? data.customisations.dropZoneOpacity : "0.5",
        hotspotIcon: data.customisations.hotspotIcon ? data.customisations.hotspotIcon : {draw: "M0,15A15,15,0,1,1,15,30,15,15,0,0,1,0,15Zm1.2,0A13…1,0,2.709H16.355v4.791a1.355,1.355,0,0,1-2.709,0Z", iconType: "Plus"}
      });
    }else if(type === "DragandDrop") {
      // to make the customize section opened
      this.setState({opened: true});
    }
  }

  //dynamically adding panel functionality
  addpanel = () => {
    const { addPanels } = this.props;
    addPanels({
      parameters: [...this.props.parameters, { "title": "", "text": "" }],
      contentArr: [...this.props.contentArr, [`title_${this.props.num + 1}`, `text_${this.props.num + 1}`]],
      num: this.props.num + 1
    });
  }

  addsection = () => {
    const { AddSection } = this.props;
    AddSection({
      dndParameters: [...this.props.dndParameters, {"background": "", "dropZone" : [] }],
      dndArray: [...this.props.dndArray, [`background_${this.props.num + 1}`, `text_${this.props.num + 1}`]],
      num: this.props.num + 1
    });
  }

  //value from title within the panel
  handleChange = (i, event) => {
    const { errors, parameters, HandleChange, type } = this.props;
    const { value } = event.target;
    let fail = errors;
    let length = (type === "DragandDrop") ? 100 : 250;
    if (value.length < length) {
      let content = [...parameters];
      content[i] = { ...content[i], "title": !value.trim() ? "" : value };
      HandleChange({
        parameters: content,
        errors: fail
      });
    }
  }

  handleEditor = (i, value) => {
    const { errors, parameters, HandleChange } = this.props;
    let fail = errors;
    let content = parameters;
    // eslint-disable-next-line no-useless-escape
    let refinedValue = value.replace(/\>\s+\</g,"><br><");
    content[i] = { ...content[i], "text": refinedValue };
    HandleChange({
      parameters: content,
      errors: fail
    });
  }

  // to save the background image for draganddrop
  handleBackground = (i, value) => {
    const { errors, dndParameters, SetParameter } = this.props;
    let fail = errors;
    let content = dndParameters;
    // eslint-disable-next-line no-useless-escape
    content[i] = { ...content[i], "background": value };
    SetParameter({
      dndParameters: content,
      errors: fail
    });
  }

  // value from main-title
  handleTitle = event => {
    const { HandleTitle } = this.props;
    const { value } = event.target;
    if (this.state.pathName.includes("/duplicate")){
      let data = this.props.location.state.contentList.filter(function(el) { return el.title === event.target.value;});
      this.setState({ 
        alreadyExist :  value === "" ? "* DUPLICATED INTERACTIVE - Please enter a new title" : data.length !== 0 ? 
          "* This title already exists. Please enter a new title" :null 
      });
    }
    if (value.length < 256) {
      HandleTitle({
        title: !value.trim() ? "" : value 
      });
    }
  }

  removeEmptyTags = (parameters) => {
    parameters.forEach((content) => {
      const wrapper = document.createElement("div");
      wrapper.innerHTML = content.text;
      let bool = false;
      Array.from( wrapper.childNodes).forEach((child) => {
        if(child.outerHTML !== "<p><br></p>") {
          bool = true;
          return;
        }
        else if (child.outerHTML === "<p><br></p>" && !bool)  {
          child.remove();
        }
      });
      content.text = wrapper.innerHTML;
    });
  };

  // remove the selected panel
  removeClick = (index, element) => {
    const { errors, parameters, contentArr, RemoveCLick, type, dndLayout, dndParameters, dndArray, RemoveElement } = this.props;
    swal.fire({
      allowOutsideClick: false,
      title: "Are you sure you wish to remove this panel?",
      type: "question",
      showCancelButton: true,
      cancelButtonText: "Cancel",
    })
      .then((isConfirm) => {
        if (isConfirm.value) {
          let content = (type === "DragandDrop" && dndLayout === "CustomOverlay") || (type === "Hotspot") ? [...dndParameters] : [...parameters];
          content.splice(index, 1);
          let contentArray = (type === "DragandDrop" && dndLayout === "CustomOverlay") || (type === "Hotspot") ? [...dndArray] : [...contentArr];
          contentArray.splice(index, 1);

          //removes value if stored in error-object it is stored in error object,by submitting without value 
          let errorObject = errors;
          delete errorObject[element[0]];
          delete errorObject[element[1]];

          if((type === "DragandDrop" && dndLayout === "CustomOverlay") || (type === "Hotspot") ){
            RemoveElement({
              dndParameters: content,
              dndArray: contentArray,
              errors: errorObject
            });
          }else{
            RemoveCLick({
              parameters: content,
              contentArr: contentArray,
              errors: errorObject
            });
          }
        }
      });
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { RearangeArray, parameters, contentArr, dndArray, SortDndArray, dndParameters, type, dndLayout } = this.props;

    if(type==="DragandDrop" && dndLayout==="CustomOverlay"){
      SortDndArray({
        dndArray: arrayMove(dndArray, oldIndex, newIndex),
        dndParameters: arrayMove(dndParameters, oldIndex, newIndex)
      });
    }else{
      RearangeArray({
        contentArr: arrayMove(contentArr, oldIndex, newIndex),
        parameters: arrayMove(parameters, oldIndex, newIndex)
      });
    }
  }

  //sumbit the form values to database and send response to preview page
  handleSubmit = async event => {
    const { parameters, title, ErrorsFetch, contentArr, fontHeadings, fontFamily, fontSize, fontWeight, radius, icon, bgcolor, dropzoneBgColor, dropzoneOpacity, 
      fontColor, alignment, borderType, borderColor, borderSize, borderStyle, setcaptionAlignment, iconColor, iconSize, iconAlignment, hotspotIcon,
      iconType, dndLayout, dndParameters, dndArray , dragAreaType, paddingTop, paddingLeft, paddingBottom, paddingRight} = this.props;
    let { id, type } = this.props.match.params;
    let response = {};
    let user_id, typeid;
    this.removeEmptyTags(parameters);
    if (this.props.match.params.user === undefined || this.props.match.params.user === "") {
      user_id = sessionStorage.getItem("user_id");
    } else {
      user_id = this.props.match.params.user;
    }
    event.preventDefault();
    let fail = validateForm(parameters, title, fontSize, ErrorsFetch, contentArr, this.state.pathName, type, dndLayout, dndParameters, dndArray);
    let dupicatetitle = fail.title === "* DUPLICATED INTERACTIVE - Please enter a new title"  ? delete fail["title"] : null; 
    if (Object.keys(fail).length === 0 && this.state.alreadyExist === null) {
      let data = {
        user_id: user_id,
        library_id: id,
        id,
        organization_id: sessionStorage.getItem("org_id"),
        title: title,
        parameters: ((dndLayout === "CustomOverlay" && type === "DragandDrop") || type === "Hotspot")? JSON.stringify(dndParameters) : JSON.stringify(parameters),
        customisations: {
          "fontHeadings": fontHeadings,
          "fontFamily": fontFamily,
          "fontSize": fontSize,
          "fontWeight": fontWeight,
          "radius": radius,
          "icon": icon,
          "bgcolor": bgcolor,
          "alignment": alignment,
          "fontColor": fontColor,
          "borderType": borderType,
          "borderColor": borderColor,
          "borderSize": borderSize,
          "borderStyle": borderStyle,
          "setcaptionAlignment": setcaptionAlignment,
          "iconColor": iconColor,
          "iconSize": iconSize,
          "iconAlignment": iconAlignment,
          "iconType": iconType,
          "dndLayout": dndLayout,
          "dragAreaType": dragAreaType,
          "paddingTop": paddingTop,
          "paddingLeft": paddingLeft,
          "paddingBottom": paddingBottom,
          "paddingRight": paddingRight,
          "dropZoneBgColor": dropzoneBgColor,
          "dropZoneOpacity": dropzoneOpacity,
          "hotspotIcon" : hotspotIcon
        },
        active: true,
        private: true
      };  
      if (id) {
        const { pathName } = this.state;
        if (pathName.includes("/duplicate") && dupicatetitle === null) {
          if (type === "Accordion") {
            typeid = 1;
          } else if (type === "Tabs") {
            typeid = 2;
          } else if (type === "imageslider") {
            typeid = 6;
          } else if (type === "ClickAndRevealInteractions") {
            typeid = 7;
          } else if (type === "DragandDrop"){
            typeid = 8;
          } else {
            typeid = 9;
          }
          data.library_id = typeid;
          response = await createContent(data);
          swal.fire({
            allowOutsideClick: false,
            title: "Duplicated",
            text: "Content Duplicated Successfully.",
            icon: "success",
            showConfirmButton: true
          });
          this.props.history.push({
            pathname: `/contentview/${type}/${this.props.match.params.user}/${response.id}`,
            state: {
              user: this.props.match.params.orgId,
              user_id: this.props.match.params.user
            }
          });
        }
        else{
          const { type } = this.props.match.params;
          response.id = id;
          await editContent({
            id,
            body: data
          });
          swal.fire({
            allowOutsideClick: false,
            title: "Updated",
            text: "Content updated Successfully.",
            icon: "success",
            showConfirmButton: true
          });
          if (this.props.match.params.user === undefined || this.props.match.params.user === "") {
            this.props.history.push({
              pathname: `/create/new/${type}/${response.id}`,
              state: {
                user: (this.props.location.state !== undefined) ? this.props.location.state.user : "",
                user_id: (this.props.location.state !== undefined) ? this.props.location.state.user_id : ""
              }
            });
          } else {
            this.props.history.push({
              pathname: `/contentview/${type}/${this.props.match.params.user}/${response.id}`,
              state: {
                user: (this.props.location.state !== undefined) ? this.props.location.state.user : "",
                user_id: (this.props.location.state !== undefined) ? this.props.location.state.user_id : ""
              }
            });
          }
        }
      }
      else if (type) {
        if (type === "Accordion") {
          typeid = 1;
        } else if (type === "Tabs") {
          typeid = 2;
        } else if (type === "imageslider") {
          typeid = 6;
        } else if (type === "ClickAndRevealInteractions") {
          typeid = 7;
        } else if(type === "DragandDrop"){
          typeid = 8;
        } else {
          typeid = 9;
          
        }
        data.library_id = typeid;
        response = await createContent(data);
        this.props.history.push({
          pathname: `/create/new/${type}/${response.id}`,
          state: {
            user: (this.props.location.state !== undefined) ? this.props.location.state.user : "",
            user_id: (this.props.location.state !== undefined) ? this.props.location.state.user_id : ""
          }
        });
      }
    }
  }

  dndPreview = () => {
    const { parameters, dndLayout, dndParameters } = this.props;
    let shuffledText = [], imagesrc=[];
    if (dndLayout === "Fixed") {
      parameters.map((content) => {
        shuffledText.push(content.title);
        return null;
      });
    } else if(dndLayout === "Overlay"){
      parameters.map((content) => {
        let data = ReactHtmlParser(content.text);
        data.map((children) => {
          let image = children.props.children;
          image.map((source)=>{
            let finalValue = {title: content.title, src: source.props.src };
            imagesrc.push(finalValue);
            return null;
          });
          return null;
        });
        shuffledText.push(content.title);
        return null;
      });
      this.setState({imagesrc: imagesrc});
    }else{
      dndParameters.map((content) => {
        content.dropZone.map((dropArea)=>{
          shuffledText.push(dropArea.data.text);
          return null;   
        });
        return null;
      });
    }
    shuffledText= shuffleOptions(
      shuffledText
    );
    this.setState({dragText: shuffledText});
  }

  modalToggle = (event) => {
    const { parameters, title, fontSize, ErrorsFetch, contentArr, type, dndLayout, dndParameters, dndArray } = this.props;
    if (type === "DragandDrop" ){
      this.dndPreview();
    }
    event.preventDefault();
    this.removeEmptyTags(parameters);
    let fail = validateForm(parameters, title, fontSize, ErrorsFetch, contentArr, this.state.pathName, type, dndLayout, dndParameters, dndArray);
    if (Object.keys(fail).length === 0) {
      this.setState({ Modelshow: true });
    }
  }

  Modelclose = () => {
    this.setState({ Modelshow: false });
  }

  handleUpload = () => { // to show the loader in editor
    const { upload } = this.state;
    this.setState({upload: !upload});
  }

  render() { // users view area 
    const { errors, contentArr, parameters, title, dndLayout, dndParameters, dndArray} = this.props;
    const { id, type } = this.props.match.params;
    const { opened } = this.state;
    let pathName = this.props.location.pathname;

    // this line will be removed once the add section is added for draganddrop custom layout
    let array = (type === "DragandDrop" && dndLayout === "CustomOverlay") || (type === "Hotspot") ? dndArray : contentArr;

    //To display the customise and preview button based on the version
    let loreeVersion = sessionStorage.getItem("loreeVersion");
    let rowClass = (loreeVersion === "1") ? "flex-row-reverse" : "flex-row";
    
    if(id){

      //To display the customise and preview button based on the version
      if (loreeVersion === null) {
        sessionStorage.setItem("loreeVersion", this.props.match.params.version);
        loreeVersion = sessionStorage.getItem("loreeVersion");
        rowClass = (loreeVersion === "1") ? "flex-row-reverse" : "flex-row";
      } 

      // to find the parent tab to restrict the user to access interactive outside loree
      if(window.location !== window.parent.location){
        const user_id = localStorage.getItem("user_id");
        sessionStorage.setItem("user_id", user_id);
      }
    }
   
    if (sessionStorage.getItem("user_id") === null) return <Redirect to="/401" />;
    else {
      return (
        <React.Fragment>
          <Header user={localStorage.getItem("user")}
            user_id={sessionStorage.getItem("user_id")} 
            data={this.props}
          />
          <Container className="margin">
            <div className={`d-flex ${rowClass} my-3`}>
              <Button className="m-1 btn-pill mw-100" onClick={this.toggleBox} size="sm" variant="dark">
                Customize <i className={!opened ? "fa fa-plus" : "fa fa-minus"}></i>
              </Button>
              <Button className="m-1 btn-pill mw-100" disabled= {this.state.upload} onClick={this.modalToggle} size="sm" variant="light">
                Preview
              </Button>
              {(() => {
                return (type === "Accordion") ?
                  <AccordionPreview content={this.props}
                    show={this.state.Modelshow}
                    onHide={this.Modelclose}
                  /> : (type === "Tabs") ?
                    <TabPreview content={this.props}
                      show={this.state.Modelshow}
                      onHide={this.Modelclose}
                    /> : (type === "imageslider") ?
                      <ImagesliderPreview content={this.props}
                        show={this.state.Modelshow}
                        onHide={this.Modelclose}
                      /> : (type === "ClickAndRevealInteractions") ?
                        <InteractiveCardPreview content={this.props}
                          show={this.state.Modelshow}
                          onHide={this.Modelclose} 
                        /> : (type === "DragandDrop") ?
                          <DragandDrop content={this.props}
                            show={this.state.Modelshow}
                            data= {this.state}
                            onHide={this.Modelclose} 
                          /> :   <HotspotPreview content={this.props}
                            show={this.state.Modelshow}
                            data= {this.state}
                            onHide={this.Modelclose} 
                          />;
              })()}
            </div>
            <Row>
              {opened && (loreeVersion === "2") && (
                (type === "DragandDrop" ) ? <DnDCustomize content={this.props} /> : 
                  ( type === "Hotspot" ) ? <HotspotCustomize content={this.props} /> : <Customize content={this.props} /> 
              )}
              <Col>
                <Form onSubmit={this.handleSubmit} >
                  <Card>
                    <Card.Header className="text-capitalize h4 font-weight-bold header">
                      Interactive Content Builder <span className="h4"> | </span><span style={{ color: "#0B099C" }}>{type}</span>
                    </Card.Header>
                    <Card.Body>
                      <Form.Group>
                        <Form.Label>My Interactive Title</Form.Label>
                        <FormControl required name="title" id="title" onChange={this.handleTitle} value={title} autoComplete="off" />
                        {pathName.includes("/duplicate") && this.state.alreadyExist ? <Form.Text className="text-danger">{this.state.alreadyExist}</Form.Text> : null}
                        {errors.title ? <Form.Text className="text-danger">{errors.title}</Form.Text> : null}
                      </Form.Group>
                      <SortableContainer onSortEnd={this.onSortEnd} onSortStart={(_, event) => event.preventDefault()} useDragHandle helperClass="SortableHelper">
                        {array.map((panel, index) => {
                          return (
                            <SortableItem id={index} key={index} panel={panel} index={index} sortIndex={index} content={
                              <React.Fragment>
                                <Card className="m-2">
                                  <Card.Header className="text-capitalize" key={index} id={panel}>
                                    {(() => {
                                      return (array.length > 1) ? <Button variant="outline-danger" size="sm" title="Remove item!" onClick={this.removeClick.bind(this, index, panel)} className="float-right close-btn">×</Button> : null;
                                    })()}
                                    <DragHandle value={`${index + 1}`} type = {"Section"} />
                                  </Card.Header>
                                  {(
                                    (type === "DragandDrop" && (dndLayout === "Fixed" || dndLayout === "Overlay")) ?
                                    // for dragandrop fixed and overlay layout
                                      <Card.Body>
                                        <Form.Group>
                                          <Form.Group className="mb-0">
                                            <Form.Label htmlFor={panel[1]}>Background Image</Form.Label>
                                            <Editor id={panel[1]} type={type} name={panel[1]} onChange={this.handleEditor.bind(this, index)} value={parameters[index]["text"]} handleUpload = {this.handleUpload}/>
                                            {errors[`${panel[1]}`] ? <Form.Text className="text-danger">{errors[`${panel[1]}`]}</Form.Text> : null}
                                          </Form.Group>
                                          <Form.Group className="mt-4">
                                            <Form.Label htmlFor={panel[0]}>Droppable Text</Form.Label>
                                            <FormControl id={panel[0]} name={panel[0]} type='text' onChange={this.handleChange.bind(this, index)} distance={1} value={parameters[index]["title"]} autoComplete="off" />
                                            {errors[`${panel[0]}`] ? <Form.Text className="text-danger">{errors[`${panel[0]}`]}</Form.Text> : null}
                                          </Form.Group>
                                        </Form.Group>
                                      </Card.Body> : 
                                      (type === "DragandDrop" && dndLayout === "CustomOverlay") || (type === "Hotspot")? 
                                      // for draganddrop custom overlay 
                                        <Card.Body>
                                          <Form.Group className="mb-0">
                                            <Form.Label htmlFor={panel[1]}>Background Image</Form.Label>
                                            <Editor id={panel[1]} type={type} dndLayout={dndLayout} index={index} name={panel[1]} onChange={this.handleBackground.bind(this, index)} value={dndParameters[index]["background"]} handleUpload = {this.handleUpload} size={this.props.fontSize}/>
                                            {errors[`${panel[0]}`] ? <Form.Text className="text-danger">{errors[`${panel[0]}`]}</Form.Text> : null}
                                            {errors[`${panel[1]}`] ? <Form.Text className="text-danger">{errors[`${panel[1]}`]}</Form.Text> : null}
                                          </Form.Group>
                                        </Card.Body> :
                                        <Card.Body>
                                          <Form.Group>
                                            {(type === "Accordion" || type === "Tabs") ?
                                              <Form.Label htmlFor={panel[0]}>Section Header</Form.Label> : (type === "imageslider") ? <Form.Label htmlFor={panel[0]}>Caption</Form.Label> : <Form.Label htmlFor={panel[0]}>Reveal Content</Form.Label>
                                            }
                                            <FormControl id={panel[0]} name={panel[0]} type='text' onChange={this.handleChange.bind(this, index)} distance={1} value={parameters[index]["title"]} autoComplete="off" />
                                            {errors[`${panel[0]}`] ? <Form.Text className="text-danger">{errors[`${panel[0]}`]}</Form.Text> : null}
                                          </Form.Group>
                                          <Form.Group className="mb-0">
                                            {type === "Accordion" || type === "Tabs" ?
                                              <Form.Label htmlFor={panel[1]}>Section Body</Form.Label> : (type === "imageslider") ? <Form.Label htmlFor={panel[0]}>Video/Image</Form.Label> : <Form.Label htmlFor={panel[0]}> Click Content </Form.Label>
                                            }
                                            <Editor id={panel[1]} type={type} name={panel[1]} onChange={this.handleEditor.bind(this, index)} value={parameters[index]["text"]}  handleUpload = {this.handleUpload} />
                                            {errors[`${panel[1]}`] ? <Form.Text className="text-danger">{errors[`${panel[1]}`]}</Form.Text> : null}
                                          </Form.Group>
                                        </Card.Body> 
                                  )}
                                </Card>
                              </React.Fragment>
                            }
                            />
                          );
                        })}
                      </SortableContainer>
                      {(() => {
                        let addPanel = [];
                        if (array.length < 100) {
                          addPanel.push(
                            (type !== "DragandDrop" && type !== "Hotspot") ?
                              <Button key="addPanel" variant="outline-secondary" className="mt-3" onClick={this.addpanel} size="sm">Add Panel</Button> :
                              (dndLayout !== "CustomOverlay") ?
                                <Button key="addPanel" variant="outline-secondary" className="mt-3" onClick={this.addpanel} size="sm">Add Section</Button> : 
                                <Button key="addPanel" variant="outline-secondary" className="mt-3" onClick={this.addsection} size="sm">Add Section</Button>
                          );
                          return addPanel;
                        }
                      })()}
                    </Card.Body>
                    <Card.Footer>
                      <Button className="btn btn-dark btn-sm btn-pill" type="submit" disabled={this.state.upload} onClick={this.handleSubmit}>
                        {(id && pathName.includes("/duplicate")) ? "Create" : id ? "Update" :"Create"}
                      </Button>
                    </Card.Footer>
                  </Card>
                </Form>
              </Col>
              {opened && (loreeVersion === "1") && (
                ( type === "DragandDrop" ) ? <DnDCustomize content={this.props} /> : 
                  ( type === "Hotspot" ) ? <HotspotCustomize content={this.props} /> : <Customize content={this.props} /> 
              )}
            </Row>
          </Container>
        </React.Fragment>
      );
    }
  }
}

//state from redux
const mapStateToProps = ({ form }) => ({
  num: form.num,
  contentArr: form.contentArr,
  title: form.title,
  parameters: form.parameters,
  errors: form.errors,
  type: form.type,
  fontHeadings: form.fontHeadings,
  fontFamily: form.fontFamily,
  fontSize: form.fontSize,
  fontWeight: form.fontWeight,
  radius: form.radius,
  icon: form.icon,
  color: form.color,
  bgcolor: form.bgcolor,
  alignment: form.alignment,
  fontColor: form.fontColor,
  borderType: form.borderType,
  borderColor: form.borderColor,
  borderSize: form.borderSize,
  borderStyle: form.borderStyle,
  setcaptionAlignment: form.setcaptionAlignment,
  iconColor: form.iconColor,
  iconSize: form.iconSize,
  iconAlignment: form.iconAlignment,
  iconType: form.iconType,
  dndLayout: form.dndLayout,
  dndParameters: form.dndParameters,
  dndArray: form.dndArray,
  dragAreaType: form.dragAreaType,
  paddingTop:form.paddingTop,
  paddingLeft:form.paddingLeft,
  paddingBottom:form.paddingBottom,
  paddingRight:form.paddingRight,
  dropzoneBgColor: form.dropzoneBgColor,
  dropzoneOpacity: form.dropzoneOpacity,
  hotspotIcon: form.hotspotIcon
});

//props to redux
const mapDispatchToProps = dispatch => ({
  addPanels: form => dispatch(addPanel(form)),
  HandleChange: form => dispatch(handleChange(form)),
  RearangeArray: form => dispatch(rearangeArray(form)),
  HandleTitle: form => dispatch(handleTitle(form)),
  ErrorsFetch: form => dispatch(errorsFetch(form)),
  RemoveCLick: form => dispatch(removeClick(form)),
  MakeEdit: form => dispatch(makeEdit(form)),
  SetType: form => dispatch(setType(form)),
  SetValue: form => dispatch(setValue(form)),
  SetParameter : form => dispatch(setParameter(form)),
  AddSection: form => dispatch(addSection(form)),
  SortDndArray: form => dispatch(sortArray(form)),
  RemoveElement: form => dispatch(removeElement(form))
});

//connecting form with redux
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InputForm);
