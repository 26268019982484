/* eslint-disable no-useless-concat */
/* eslint-disable no-undefined */
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Tab, Tabs, Button, Row, Col, Container } from "react-bootstrap";
import { putItems } from "../../redux/components/reducer.action";
import ReactHtmlParser from "react-html-parser";
import { fetchContent, viewContent } from "../../middleware/api";
import { initializeReactGA } from "../../middleware/googleAnalytics";
import { connect } from "react-redux";
import { moveNavigationLeft, moveNavigationRight } from "./arrowFunction";
import "../../assets/scss/tabComponent.scss";
import Header from "../layouts/navbar";
import PostHeightIframe from "../../middleware/sendHeight";

let content, response;

class TabComponent extends Component {
  //Getting data from the Redux-state
  async componentDidMount() {
    const { PutItems } = this.props;
    const { id } = this.props.match.params;
    if (this.props.location.pathname === `Tab/${id}` || `tabs/${id}`) {
      response = await viewContent(id);
    } else {
      if (
        this.props.match.params.user === undefined ||
        this.props.match.params.user === ""
      ) {
        content = sessionStorage.getItem("user_id");
      } else {
        content = this.props.match.params.user;
      }
      response = await fetchContent(id, content);
    }
    if (!response.message && response.library_id === 2) {

      //To check the tracking id of GA
      if(this.props.location.pathname !== `/Tabs/${id}` ) initializeReactGA(this.props.location.pathname);

      PutItems({
        title: response.title,
        parameters: JSON.parse(response.parameters),
        customisations: response.customisations,
      });
    } else {
      this.props.history.push("/*");
    }
    if(this.props.location.pathname === `/Tabs/${id}` ) PostHeightIframe();
  }

  //Edit functionality for tab
  handleEdit = () => {
    let path;
    if (this.props.match.params.user === undefined) {
      path = "/edit/Tabs";
    } else {
      path = `/content/Tabs/${this.props.match.params.user}`;
    }
    const { id } = this.props.match.params;
    if (id) {
      this.props.history.push({
        pathname: `${path}/${id}/${sessionStorage.getItem("org_id")}/${sessionStorage.getItem("loreeVersion")}`,
        state: {
          user:
            this.props.location.state !== undefined
              ? this.props.location.state.user
              : "",
          user_id:
            this.props.location.state !== undefined
              ? this.props.location.state.user_id
              : "",
        },
      });
    }
  };

  //Update iframe height ontabselect
  handleIframeHeight = (id) => {
    if(this.props.location.pathname === `/Tabs/${id}` ) PostHeightIframe();
  };

  //this functionality used to show the leftArrow
  handleLeftArrow = () => {
    moveNavigationLeft();
  };

  //this functionality used to show the rightArrow
  handleRightArrow = () => {
    moveNavigationRight();
  };

  render() {
    //render the tab view page
    const { title, parameters, customisations } = this.props;
    const { id } = this.props.match.params;
    const viewpage = this.props.location.pathname;

    const borderleftStyle = (customisations.borderType === "border-left border-dark") ? true : false;

    const borderrightStyle = (customisations.borderType === "border-right border-dark") ? true : false;

    const bordertopStyle = (customisations.borderType === "border-top border-dark") ? true : false;  

    const borderbottomStyle = (customisations.borderType === "border-bottom border-dark") ? true : false;  

    const borderfullStyle = (customisations.borderType === "border border-dark") ? true : false;  
    
    const tabStyle = {
      fontFamily: `${customisations.fontFamily}`,
      fontWeight: `${customisations.fontWeight}`,
      backgroundColor: `${customisations.bgcolor}`,
      fontSize: `${customisations.fontSize}px`,
      color: `${customisations.fontColor}`
        ? `${customisations.fontColor}`
        : "#007bff",
    };

    const tabborderleftStyle = {
      borderLeft: `${customisations.borderSize}px ` + `${customisations.borderStyle} ` + `${customisations.borderColor}`
    };

    const tabborderrightStyle = {
      borderRight: `${customisations.borderSize}px ` + `${customisations.borderStyle} ` + `${customisations.borderColor}`
    };

    const tabbordertopStyle = {
      borderTop: `${customisations.borderSize}px ` + `${customisations.borderStyle} ` + `${customisations.borderColor}`
    };

    const tabborderbottomtStyle = {
      borderBottom: `${customisations.borderSize}px ` + `${customisations.borderStyle} ` + `${customisations.borderColor}`
    };

    const tabborderfullStyle = {
      border: `${customisations.borderSize}px ` + `${customisations.borderStyle} ` + `${customisations.borderColor}`
    };

    if (viewpage === `/Tabs/${id}`) {
      if (parameters.length > 5) {
        //this function show more than 5 tabs with arrow functionality
        return (
          <div className="border rounded-lg m-2">
            <div className="m-2">
              <button
                className="btn btn-link tab-control float-left"
                onClick={this.handleLeftArrow}
              >
                <i className="fa fa-caret-left arrow"></i>
              </button>
              <button
                className="btn btn-link tab-control float-right"
                onClick={this.handleRightArrow}
              >
                <i className="fa fa-caret-right arrow"></i>
              </button>
              <Tabs
                defaultevenkey="0"
                className={`${customisations.fontHeadings} list-tab ${customisations.radius} interactivetab li-tab`}
                style={tabStyle}
                onSelect={this.handleIframeHeight.bind(this, id)}
              >
                {parameters.map((list, index) => (
                  <Tab
                    eventKey={`${index}`}
                    title={list["title"]}
                    key={`${index}`}
                    className="tabcontent"
                    style = {borderleftStyle ? tabborderleftStyle : (borderrightStyle) ? tabborderrightStyle : (bordertopStyle) ? tabbordertopStyle : (borderbottomStyle) ? tabborderbottomtStyle : (borderfullStyle) ? tabborderfullStyle : null }
                  >
                    <div className="m-3 ql-editor" >
                      {ReactHtmlParser(list["text"])}
                    </div>
                  </Tab>
                ))}
              </Tabs>
            </div>
          </div>
        );
      } else {
        return (
          <div className="border borderRadius m-2">
            <div className="m-2">
              <Tabs
                defaultevenkey="0"
                className={`${customisations.fontHeadings} list-tab ${customisations.radius} li-md-tab`}
                style={tabStyle}
                onSelect={this.handleIframeHeight.bind(this, id)}
              >
                {parameters.map((list, index) => (
                  <Tab
                    eventKey={`${index}`}
                    title={list["title"]}
                    key={`${index}`}
                    className="tabcontent"
                    style = {borderleftStyle ? tabborderleftStyle : (borderrightStyle) ? tabborderrightStyle : (bordertopStyle) ? tabbordertopStyle : (borderbottomStyle) ? tabborderbottomtStyle : (borderfullStyle) ? tabborderfullStyle : null }

                  >
                    <div className="m-3 ql-editor">
                      {ReactHtmlParser(list["text"])}
                    </div>
                  </Tab>
                ))}
              </Tabs>
            </div>
          </div>
        );
      }
    }

    if (
      viewpage === `/create/new/Tabs/${id}` ||
      `contentview/Tabs/${this.props.match.params.user}/${id}`
    )
      if (this.props.location.state !== undefined) {
        if (parameters.length > 5) {
          //this function show more than 5 tabs with arrow functionality
          return (
            <React.Fragment>
              <Header
                user={localStorage.getItem("user")}
                user_id={sessionStorage.getItem("user_id")}
                data={this.props}
              />
              <Container className="margin">
                <Row className="d-flex justify-content-between mt-3">
                  <Col style={{wordBreak:"break-word"}}>
                    <h2>{title}</h2>
                  </Col>
                  <Col xs lg="1">
                    <Button
                      className="btn btn-dark"
                      onClick={this.handleEdit}
                    >
                      Edit
                    </Button>
                  </Col>
                </Row>
                <div className="border rounded-lg m-2 mt-4">
                  <div className="m-2">
                    <button
                      className="btn btn-link tab-control float-left"
                      onClick={this.handleLeftArrow}
                    >
                      <i className="fa fa-caret-left arrow"></i>
                    </button>
                    <button
                      className="btn btn-link tab-control float-right"
                      onClick={this.handleRightArrow}
                    >
                      <i className="fa fa-caret-right arrow"></i>
                    </button>
                    <Tabs
                      defaultevenkey="0"
                      className={`${customisations.fontHeadings} list-tab ${customisations.radius} interactivetab li-tab`}
                      style={tabStyle}
                    >
                      {parameters.map((list, index) => (
                        <Tab
                          eventKey={`${index}`}
                          title={list["title"]}
                          key={`${index}`}
                          className="tabcontent"
                          style = {borderleftStyle ? tabborderleftStyle : (borderrightStyle) ? tabborderrightStyle : (bordertopStyle) ? tabbordertopStyle : (borderbottomStyle) ? tabborderbottomtStyle : (borderfullStyle) ? tabborderfullStyle : null }

                        >
                          <div className="m-3 ql-editor">
                            {ReactHtmlParser(list["text"])}
                          </div>
                        </Tab>
                      ))}
                    </Tabs>
                  </div>
                </div>
              </Container>
            </React.Fragment>
          );
        } else {
          return (
            <React.Fragment>
              <Header
                user={localStorage.getItem("user")}
                user_id={sessionStorage.getItem("user_id")}
                data={this.props}
              />
              <Container className="margin" >
                <Row className="d-flex justify-content-between mt-3">
                  <Col>
                    <h2 style={{wordBreak:"break-word"}}>{title}</h2>
                  </Col>
                  <Col xs lg="1">
                    <Button
                      className="btn btn-dark"
                      onClick={this.handleEdit}
                    >
                      Edit
                    </Button>
                  </Col>
                </Row>
                <div></div>
                <div className="border borderRadius mt-4">
                  <div>
                    <Tabs
                      defaultevenkey="0"
                      className={`${customisations.fontHeadings} list-tab ${customisations.radius} li-md-tab`}
                      style={tabStyle}
                    >
                      {parameters.map((list, index) => (
                        <Tab
                          eventKey={`${index}`}
                          title={list["title"]}
                          key={`${index}`}
                          className="tabcontent"
                          style = {borderleftStyle ? tabborderleftStyle : (borderrightStyle) ? tabborderrightStyle : (bordertopStyle) ? tabbordertopStyle : (borderbottomStyle) ? tabborderbottomtStyle : (borderfullStyle) ? tabborderfullStyle : null }
                        >
                          <div className="m-3 ql-editor">
                            {ReactHtmlParser(list["text"])}
                          </div>
                        </Tab>
                      ))}
                    </Tabs>
                  </div>
                </div>
              </Container>
            </React.Fragment>
          );
        }
      } else {
        return <Redirect to="/401" />;
      }
  }
}

//state from redux
const mapStateToProps = ({ component }) => ({
  title: component.title,
  parameters: component.parameters,
  customisations: component.customisations,
});

//props to redux
const mapDispatchToProps = dispatch => ({
  PutItems: component => dispatch(putItems(component)),
});

//connecting form with redux
export default connect(mapStateToProps, mapDispatchToProps)(TabComponent);
