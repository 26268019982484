/* eslint-disable no-undefined */
/* eslint-disable react/jsx-no-duplicate-props */
import React from "react";
import ReactHtmlParser from "react-html-parser";
import { Modal, Button, Carousel, Card } from "react-bootstrap";

class ImagesliderPreview extends React.Component {

  componentDidUpdate(){
    var carouselPrevIcon = document.getElementsByClassName("carousel-control-prev");
    var carouselnextIcon = document.getElementsByClassName("carousel-control-next"); 
    var carouselprevControlIcon = document.getElementsByClassName("carousel-control-prev-icon"); 
    var carouselnextControlIcon = document.getElementsByClassName("carousel-control-next-icon"); 
    if(carouselPrevIcon[0] !== undefined && carouselnextIcon[0] !== undefined){
      if(carouselPrevIcon && carouselnextIcon){
        carouselPrevIcon[0].setAttribute("style", `background-color: ${this.props.content.iconColor};`); 
        carouselnextIcon[0].setAttribute("style", `background-color: ${this.props.content.iconColor};`); 
        carouselprevControlIcon[0].setAttribute("style", `padding: ${this.props.content.iconSize};`); 
        carouselnextControlIcon[0].setAttribute("style", `padding: ${this.props.content.iconSize};`); 
      }
      if(document.getElementById !== "prev-id" && document.getElementById !== "next-id" ){
        carouselPrevIcon[0].setAttribute("id", "prev-id");
        carouselnextIcon[0].setAttribute("id", "next-id");
      }
      if(this.props.content.iconAlignment === "Top"){
        document.getElementById("prev-id").className = "carousel-control-prev-top";
        document.getElementById("next-id").className = "carousel-control-next-top";
      }
      if(this.props.content.iconAlignment === "Bottom"){
        document.getElementById("prev-id").className = "carousel-control-prev-bottom";
        document.getElementById("next-id").className = "carousel-control-next-bottom";
      }
      if(this.props.content.iconType === "50%"){
        document.getElementById("prev-id").setAttribute("style", document.getElementById("prev-id").getAttribute("style")+`; border-radius: ${this.props.content.iconType};`);
        document.getElementById("next-id").setAttribute("style", document.getElementById("next-id").getAttribute("style")+`; border-radius: ${this.props.content.iconType};`);
      }
    }
  }

  render() { //preview page render for imageslider using modal
    const caption = `${this.props.content.setcaptionAlignment}`;
    const fontHeadings = ((this.props.content.fontHeadings !== "" ) ? this.props.content.fontHeadings : "div");
    const parameters = this.props.content.parameters;
    let sliderStyle = {
      fontFamily: `${this.props.content.fontFamily}`, 
      fontWeight: `${this.props.content.fontWeight}`,
      fontSize: `${this.props.content.fontSize}px`, 
      color: `${this.props.content.fontColor}`,
      backgroundColor: `${this.props.content.bgcolor}`
    };
    let videoVal, params;
    for(params of parameters){
      videoVal = params["text"].includes("iframe");
    }
    return (
      <div className="m-2">
        <Modal
          {...this.props}
          size="lg"
          dialogClassName="modal-90w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter" style={{wordBreak:"break-word"}}>
              {this.props.content.title}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <React.Fragment>
              <Carousel className="carousel-preview" controls={(this.props.content.parameters.length === 1) ? false : true} pause={"hover"} interval={videoVal === true ? null : 5000}>
                {
                  this.props.content.parameters.map((content, idx) => {
                    let extraTag =  content["text"].replace("<p><br></p>", "");
                    content["text"] = extraTag;
                    return (
                      <Carousel.Item key={idx}
                        eventkey={idx}>
                        <Card className="text-center" style={{display: caption === "bottom" ? "none" : " "}}>
                          <Card.Body as={fontHeadings} style={sliderStyle}>{content["title"]}</Card.Body>
                        </Card>
                        {ReactHtmlParser(content["text"])}
                        <Card className="text-center" style={{display: caption === "top" ? "none" : " "}}>
                          <Card.Body as={fontHeadings} style={sliderStyle}>{content["title"]}</Card.Body>
                        </Card>
                      </Carousel.Item>
                    );}
                  )}
              </Carousel>
            </React.Fragment>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="btn btn-dark btn-sm btn-pill btn btn-primary" onClick={this.props.onHide}>Back to Editor</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default ImagesliderPreview;

