//handles add panel functionality
export const addPanel = data => ({
  type: "ADD_PANEL",
  parameters: data.parameters,
  contentArr: data.contentArr,
  num: data.num
});

//handles title value within the panel
export const handleChange = data => ({
  type: "HANDLE_CHANGE",
  parameters: data.parameters,
  errors: data.errors
});
export const rearangeArray = data => ({
  type: "REARANGE_ARRAY",
  parameters: data.parameters,
  contentArr: data.contentArr
});

//handles the value of main-title of the form
export const handleTitle = data => ({
  type: "HANDLE_TITLE",
  title: data.title
});

//handles the remove functionality for selected panel
export const removeClick = data => ({
  type: "REMOVE_CLICK",
  parameters: data.parameters,
  contentArr: data.contentArr,
  errors: data.errors
});

//validation for empty feildssta
export const errorsFetch = data =>({
  type:"ERRORS",
  errors: data.errors
});

export const makeEdit = data => ({
  type: "MAKE_EDIT",
  title: data.title,
  parameters: data.parameters, 
  contentArr: data.contentArr,
  component: data.type,
  bgcolor : data.bgcolor,
  backColor: data.backColor,
  height: data.height,
  noOfCard: data.noOfCard,
  radius: data.radius,
  borderType: data.borderType,
  borderColor: data.borderColor,
  borderSize: data.borderSize,
  borderStyle: data.borderStyle,
  flip: data.flip,
});

export const setType = data => ({
  type: "SET_TYPE",
  component: data.type
});

//To set the ID for the panels
export const setValue = data => ({
  type: "SET_VALUE",
  num: data.num,
  option_id: data.option_id
});

export const setBgColor = data => ({
  type: "CUSTOM_BGCOLOR",
  bgcolor: data.bgcolor
});

export const backColor = data => ({
  type: "CUSTOM_BACKCOLOR",
  backColor: data.backColor
});

export const height = data => ({
  type: "CUSTOM_HEIGHT",
  height: data.height
});

export const noOfCard = data => ({
  type: "CUSTOM_NOOFCARD",
  noOfCard: data.noOfCard
});

export const radius = data => ({
  type: "CUSTOM_RADIUS",
  radius: data.radius
});

export const borderType = data => ({
  type:"CUSTOM_BORDER",
  borderType: data.borderType
});

export const setBorderColor = data => ({
  type:"BORDER_COLOR",
  borderColor: data.borderColor
});

export const setBorderSize = data => ({
  type:"BORDER_SIZE",
  component: data.borderSize
});

export const setBorderStyle = data => ({
  type:"BORDER_STYLE",
  component: data.borderStyle
});

export const flip = data => ({
  type: "CUSTOM_FLIP",
  flip: data.flip
});
