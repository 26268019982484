/* eslint-disable no-useless-concat */
import React, { Component } from "react";
import { Tab, Tabs, Modal, Button } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import { moveNavigationLeft, moveNavigationRight } from "./arrowFunction";
import "../../assets/scss/tabComponent.scss";

class TabPreview extends Component {

  //this functionality used to show the leftArrow
  handleLeftArrow = () => {
    moveNavigationLeft();
  }

  //this functionality used to show the rightArrow
  handleRightArrow = () => {
    moveNavigationRight();
  }

  render() { //render more than 5 tabs preview page
    const tabPreviewstyle = {
      fontFamily: `${this.props.content.fontFamily}`,
      backgroundColor: `${this.props.content.bgcolor}`, 
      fontWeight: `${this.props.content.fontWeight}`,
      fontSize: `${this.props.content.fontSize}px`,      
      color : `${this.props.content.fontColor}` ? `${this.props.content.fontColor}` : "#007bff"
    };

    const borderleftStyle = (this.props.content.borderType === "border-left border-dark") ? true : false;

    const borderrightStyle = (this.props.content.borderType === "border-right border-dark") ? true : false;

    const bordertopStyle = (this.props.content.borderType === "border-top border-dark") ? true : false;  

    const borderbottomStyle = (this.props.content.borderType === "border-bottom border-dark") ? true : false;  

    const borderfullStyle = (this.props.content.borderType === "border border-dark") ? true : false; 

    const tabborderleftStyle = {
      borderLeft: `${this.props.content.borderSize}px ` + `${this.props.content.borderStyle} ` + `${this.props.content.borderColor}`
    };

    const tabborderrightStyle = {
      borderRight: `${this.props.content.borderSize}px ` + `${this.props.content.borderStyle} ` + `${this.props.content.borderColor}`
    };

    const tabbordertopStyle = {
      borderTop: `${this.props.content.borderSize}px ` + `${this.props.content.borderStyle} ` + `${this.props.content.borderColor}`
    };

    const tabborderbottomtStyle = {
      borderBottom: `${this.props.content.borderSize}px ` + `${this.props.content.borderStyle} ` + `${this.props.content.borderColor}`
    };

    const tabborderfullStyle = {
      border: `${this.props.content.borderSize}px ` + `${this.props.content.borderStyle} ` + `${this.props.content.borderColor}`
    };

    if (this.props.content.parameters.length > 5) {
      return (
        <div>
          <Modal
            {...this.props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter" style={{wordBreak:"break-word"}}>
                {this.props.content.title}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <React.Fragment>
                <div className="border rounded-lg m-2 mt-4">
                  <div className="m-2">
                    <button className="btn btn-link tab-control float-left" onClick={this.handleLeftArrow} >
                      <i className="fa fa-caret-left arrow"></i>
                    </button>
                    <button className="btn btn-link tab-control float-right" onClick={this.handleRightArrow}>
                      <i className="fa fa-caret-right arrow"></i>
                    </button>
                    <Tabs defaultevenkey="0" className={`${this.props.content.fontHeadings} ${this.props.content.radius} interactivetab li-tab list-tab`} style={tabPreviewstyle}>
                      {
                        this.props.content.parameters.map((list, index) => (
                          <Tab eventKey={`${index}`} title={list["title"]} key={`${index}`} className="tabcontent" style = {borderleftStyle ? tabborderleftStyle : (borderrightStyle) ? tabborderrightStyle : (bordertopStyle) ? tabbordertopStyle : (borderbottomStyle) ? tabborderbottomtStyle : (borderfullStyle) ? tabborderfullStyle : null} >
                            <div className="m-3 ql-editor">{ReactHtmlParser(list["text"])}</div>
                          </Tab>
                        ))
                      }
                    </Tabs>
                  </div>
                </div>
              </React.Fragment>
            </Modal.Body>
            <Modal.Footer>
              <Button variant= "btn btn-dark btn-sm btn-pill btn btn-primary" onClick={this.props.onHide}>Back to Editor</Button>
            </Modal.Footer>
          </Modal>
        </div>
      );
    }

    else{
      return( //render the tabs preview page
        <div className="m-2">  
          <Modal
            {...this.props}
            size="lg"
            dialogClassName="modal-90w"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter" style={{wordBreak:"break-word"}}>
                {this.props.content.title}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <React.Fragment>
                <div className="border rounded-lg m-2 mt-4">
                  <div className="m-2">
                    <Tabs defaultevenkey="0" className={`${this.props.content.fontHeadings} ${this.props.content.radius} li-md-tab list-tab`} style={tabPreviewstyle} >
                      {
                        this.props.content.parameters.map((list, index) => (
                          <Tab eventKey={`${index}`} title={list["title"]} key={`${index}`} className="tabcontent" style = {borderleftStyle ? tabborderleftStyle : (borderrightStyle) ? tabborderrightStyle : (bordertopStyle) ? tabbordertopStyle : (borderbottomStyle) ? tabborderbottomtStyle : (borderfullStyle) ? tabborderfullStyle : null }>
                            <div className="m-3 ql-editor">{ReactHtmlParser(list["text"])}</div>
                          </Tab>
                        ))
                      }
                    </Tabs>
                  </div>
                </div>
              </React.Fragment>
            </Modal.Body>
            <Modal.Footer>
              <Button variant= "btn btn-dark btn-sm btn-pill btn btn-primary" onClick={this.props.onHide}>Back to Editor</Button>
            </Modal.Footer>
          </Modal>
        </div>
      );
    }
  }
}

export default TabPreview;
