/* eslint-disable no-useless-concat */
import React from "react";
import "../../assets/scss/multipleChoice.scss";
import { Container, Modal, Button } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import Mytips from "../multipleChoice/mytips";


let btnType, mouseover, alignment, buttonstyle, buttonHover, buttonUnhover;
//render raw HTML from question data
const QuizProgress = (props) => {
  return (
    <div className={`progress ${props.customisation.pbFontHeadings}`} style={{fontSize:`${props.customisation.pbFontSize}px`,
      fontWeight: `${props.customisation.pbFontWeight}`, fontFamily: `${props.customisation.pbFontFamily}`}}>
      <p className="counter" style={{color: props.customisation.pbFontColor}}>
        <span>
          Question {props.currentQuestion + 1} of {props.questionLength}
        </span>
      </p>
      <div
        className="progress-bar"
        style={{
          width:
            ((props.currentQuestion + 1) / props.questionLength) * 100 + "%",
          background: props.customisation.pbBgColor,
        }}
      ></div>
    </div>
  );
};
// to display the final score of the quiz attempt 
const Results = (props) => {
  return (
    <div className="results fade-in">
      <h1>Your score: {((props.correct / props.questionLength) * 100).toFixed()}%</h1>
      {props.customtryagain ? 
        <>
          <div className={"text-" + `${alignment}`}>
            {mouseover ? (
              <Button
                className={`${btnType}`}
                style={props.hover ? buttonHover : buttonUnhover}
                onMouseEnter={ props.hoverOn.bind(this, "tryagain")}
                onMouseLeave={props.hoverOff.bind(this, "tryagain")}
                type="button"
                onClick={props.startOver}
              >
                 Try again <i className="fas fa-redo"></i>
              </Button>
            ) : (
              <Button
                className={`${btnType}`}
                style = {buttonstyle}
                type="button"
                onClick={props.startOver}
              >
                 Try again <i className="fas fa-redo"></i>
              </Button>
            )}
          </div>
        </>
        : null}
    </div>
  );
};
class McqPreview extends React.Component {
  constructor(props) {
    super(props);
    this.updateAnswer = this.updateAnswer.bind(this);
    this.checkAnswer = this.checkAnswer.bind(this);
    this.nextQuestion = this.nextQuestion.bind(this);
    this.getResults = this.getResults.bind(this);
    this.startOver = this.startOver.bind(this);
    this.state = {
      hover: false,
      hoverNext: false,
      hoverGetRes: false,
      hoverTryagain: false,
      currentQuestion: 0,
      correct: 0,
      showModal: false,
      inProgress: true,
      checkAnswered: true,
      questions: [{
        question: "",
        options: [{
          option: "",
          correct: false
        }, {
          option: "",
          correct: false
        }, {
          option: "",
          correct: false
        }]
      }
      ]
    };
  }
  //to set the state at the initial state
  componentDidMount() {
    this.setState({
      questions: this.props.parameters,
      checkAnswered: true
    });
    this.radioRef = React.createRef();
  }

  //eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextprops) {
    if (this.props !== nextprops) {
      this.setState({
        questions: nextprops.parameters,
        showModal: nextprops.show,
        inProgress: true,
        correct: 0,
        checkAnswered: true,
        currentQuestion: 0
      });
    }
  }

   //mouse cursor in button
   // eslint-disable-next-line no-unused-vars
   hoverOn = (type, e) => {

     if (type === "checkAns"){ this.setState({ hover: true }); }
     else if (type === "next") { this.setState({hoverNext: true}); }
     else if (type === "result") { this.setState({hoverGetRes: true}); }
     else { this.setState({hoverTryagain: true}); }
   };

  //mouse cursor leave from button
  // eslint-disable-next-line no-unused-vars
  hoverOff = (type, e) => {
    if (type === "checkAns"){ this.setState({ hover: false }); }
    else if (type === "next") { this.setState({hoverNext: false}); }
    else if (type === "result") { this.setState({hoverGetRes: false}); }
    else { this.setState({hoverTryagain: false}); }
  };

  //record whether the question was answered correctly
  updateAnswer(e) {
    let answerValue = e.target.value;
    this.setState((prevState) => {
      let stateToUpdate = prevState.questions;
      stateToUpdate[prevState.currentQuestion].answerCorrect = answerValue;
      if (this.props.content.optiontype === "checkbox") {
        let CheckboxChecked = (document.getElementById("checkbox-0").checked || document.getElementById("checkbox-1").checked);
        // eslint-disable-next-line no-unused-expressions
        this.props.content.checkedAnswer ? this.setBtnDisabled("checkAnsBtn", CheckboxChecked) : this.setBtnDisabled("nextBtn", CheckboxChecked);
      }
      return { questions: stateToUpdate, checkAnswered: false };
    });
  }

  setBtnDisabled(btnName, CheckboxChecked) {
    document.getElementById(`${btnName}`).disabled = !CheckboxChecked;
  }

  checkAnswer() {
    //display to the user whether their answer is correct
    this.setState((prevState) => {
      let stateToUpdate = prevState.questions;
      stateToUpdate[prevState.currentQuestion].checked = true;
      return { questions: stateToUpdate };
    });
  }
  nextQuestion() {
    //advance to the next question
    this.setState((prevState) => {
      let stateToUpdate = prevState.currentQuestion;
      return { currentQuestion: stateToUpdate + 1, checkAnswered: true, hover: false };
    }, () => {
      this.radioRef.current.reset();
    });
  }
  getResults() {
    //loop through questions and calculate the number right
    let correct = this.state.correct;
    this.state.questions.forEach((item, index) => {
      if (item.answerCorrect === "true") {
        ++correct;
      }
      if (index === (this.state.questions.length - 1)) {
        this.setState({
          correct: correct,
          inProgress: false,
          checkAnswered: true,
          hoverGetRes: false
        });
      }
    });
  }
  //to set the state at every time when the page is updated
  componentDidUpdate(prevProps) {
    if (this.props.content.parameters !== prevProps.content.parameters) {
      this.setState({
        questions: (this.props.content.parameters),
        inProgress: true,
        correct: 0,
        checkAnswered: true,
        currentQuestion: 0
      });
      return true;
    }
  }

  // Once customization is added this feature will be available (Try again feature)
  startOver() {
    // reset form and state back to its original value
    this.setState((prevState) => {
      let questionsToUpdate = prevState.questions;
      questionsToUpdate.forEach(item => {
        // clear answers from previous state
        delete item.answerCorrect;
        delete item.checked;
      });
      return {
        inProgress: true,
        correct: 0,
        checkAnswered: true,
        currentQuestion: 0,
        questions: questionsToUpdate,
        hoverTryagain: false,
        hover: false
      };
    }, () => {
      this.radioRef.current.reset();
    });
  }
  render() {
    let answerBtn = null;
    let correctanswer = null;
    mouseover = (this.props.content.btnType === "btn btn-outline-dark") ? true : false;
    btnType = this.props.content.btnType;
    alignment = this.props.content.alignment;
    buttonstyle = {
      background: `${this.props.content.buttonBgColor}`,
      fontFamily: `${this.props.content.fontFamily}`,
      fontSize: `${this.props.content.fontSize}px`,
      fontWeight: this.props.content.fontWeight,
      color: `${this.props.content.buttonFontColor}`,
      border: "2px solid" + `${this.props.content.buttonBgColor}`,
      paddingTop: (this.props.content.customPaddingtop === "") ? 10 : `${this.props.content.customPaddingtop}px`,
      paddingLeft: (this.props.content.customPaddingleft === "") ? 10 :`${this.props.content.customPaddingleft}px`,
      paddingBottom: (this.props.content.customPaddingbottom === "") ? 10 :`${this.props.content.customPaddingbottom}px`,
      paddingRight: (this.props.content.customPaddingright === "") ? 10: `${this.props.content.customPaddingright}px`
    };
  
    buttonUnhover = {
      background: "white",
      color:  this.props.content.buttonFontColor === "#ffffff" ? "#000000" : this.props.content.buttonFontColor,
      fontFamily: `${this.props.content.fontFamily}`,
      fontSize: `${this.props.content.fontSize}px`,
      fontWeight: this.props.content.fontWeight,
      border: "2px solid" + `${this.props.content.buttonBgColor}`,
      paddingTop: (this.props.content.customPaddingtop === "") ? 10 : `${this.props.content.customPaddingtop}px`,
      paddingLeft: (this.props.content.customPaddingleft === "") ? 10 :`${this.props.content.customPaddingleft}px`,
      paddingBottom: (this.props.content.customPaddingbottom === "") ? 10 :`${this.props.content.customPaddingbottom}px`,
      paddingRight: (this.props.content.customPaddingright === "") ? 10: `${this.props.content.customPaddingright}px`
    };
  
    buttonHover = {
      background: `${this.props.content.buttonBgColor}`,
      fontSize: `${this.props.content.fontSize}px`,
      border: "2px solid" + `${this.props.content.buttonBgColor}`,
      fontFamily: `${this.props.content.fontFamily}`,
      fontWeight: this.props.content.fontWeight,
      color: this.props.content.buttonFontColor,
      paddingTop: (this.props.content.customPaddingtop === "") ? 10 : `${this.props.content.customPaddingtop}px`,
      paddingLeft: (this.props.content.customPaddingleft === "") ? 10 :`${this.props.content.customPaddingleft}px`,
      paddingBottom: (this.props.content.customPaddingbottom === "") ? 10 :`${this.props.content.customPaddingbottom}px`,
      paddingRight: (this.props.content.customPaddingright === "") ? 10: `${this.props.content.customPaddingright}px`
    };

    const borderleftStyle = (this.props.content.borderType === "border-left border-dark") ? true : false;

    const borderrightStyle = (this.props.content.borderType === "border-right border-dark") ? true : false;

    const bordertopStyle = (this.props.content.borderType === "border-top border-dark") ? true : false;  

    const borderbottomStyle = (this.props.content.borderType === "border-bottom border-dark") ? true : false;  

    const borderfullStyle = (this.props.content.borderType === "border border-dark") ? true : false; 

    const mcqborderleftStyle = {
      borderLeft: `${this.props.content.borderSize}px ` + `${this.props.content.borderStyle} ` + `${this.props.content.borderColor}`
    };

    const mcqborderrightStyle = {
      borderRight: `${this.props.content.borderSize}px ` + `${this.props.content.borderStyle} ` + `${this.props.content.borderColor}`
    };

    const mcqbordertopStyle = {
      borderTop: `${this.props.content.borderSize}px ` + `${this.props.content.borderStyle} ` + `${this.props.content.borderColor}`
    };

    const mcqborderbottomtStyle = {
      borderBottom: `${this.props.content.borderSize}px ` + `${this.props.content.borderStyle} ` + `${this.props.content.borderColor}`
    };

    const mcqborderfullStyle = {
      border: `${this.props.content.borderSize}px ` + `${this.props.content.borderStyle} ` + `${this.props.content.borderColor}`
    };
    if (this.props.content.optiontype === "checkbox" && this.props.content.checkedAnswer === true) {
      answerBtn = (
        <>
          <div className={"text-" + `${this.props.content.alignment}`}>
            {mouseover ? (
              <Button
                className={`${this.props.content.btnType}`}
                style={this.state.hover ? buttonHover : buttonUnhover}
                key="btn"
                id="checkAnsBtn"
                onMouseEnter={this.hoverOn.bind(this, "checkAns")}
                onMouseLeave={this.hoverOff.bind(this, "checkAns")}
                onClick={this.checkAnswer}
                disabled={this.state.checkAnswered}
              >
                Check answer
              </Button>
            ) : (
              <Button
                className={`${this.props.content.btnType}`}
                style = {buttonstyle}
                key="btn"
                id="checkAnsBtn"
                type="button"
                onClick={this.checkAnswer}
                disabled={this.state.checkAnswered}
              >
                Check answer
              </Button>
            )}
          </div>
        </>
      );
    } else if (this.props.content.optiontype === "radio" && this.props.content.checkedAnswer === true) {
      answerBtn = (
        <>
          <div className={"text-" + `${this.props.content.alignment}`}>
            {mouseover ? (
              <Button
                className={`${this.props.content.btnType}`}
                style={this.state.hover ? buttonHover : buttonUnhover}
                key="disablebtn"
                onMouseEnter={this.hoverOn.bind(this, "checkAns")}
                onMouseLeave={this.hoverOff.bind(this, "checkAns")}
                onClick={this.checkAnswer}
                disabled={!("answerCorrect" in this.state.questions[this.state.currentQuestion])}
              >
              Check answer
              </Button>
            ) : (
              <Button
                className={`${this.props.content.btnType}`}
                style = {buttonstyle}
                key="disablebtn"
                id="btn"
                type="button"
                onClick={this.checkAnswer}
                disabled={!("answerCorrect" in this.state.questions[this.state.currentQuestion])}
              >
              Check answer
              </Button>
            )}
          </div>
        </>
      );
    }
    if (this.props.content.checkedAnswer === true) {
      correctanswer = (
        <div className="bottom">
          {!this.state.questions[this.state.currentQuestion].checked && [answerBtn]}
          {(this.state.currentQuestion + 1) < this.state.questions.length && this.state.questions[this.state.currentQuestion].checked &&
            <>
              <div className={"text-" + `${alignment}`}>
                {mouseover ? (
                  <Button
                    className={`${btnType}`}
                    style={this.state.hoverNext ? buttonHover : buttonUnhover}
                    onMouseEnter={this.hoverOn.bind(this, "next")}
                    onMouseLeave={this.hoverOff.bind(this, "next")}
                    type="button"
                    onClick={this.nextQuestion}
                    id="nextBtn"
                    disabled={this.state.checkAnswered}
                  >
                 Next <i className="fa fa-arrow-right"></i>
                  </Button>
                ) : (
                  <Button
                    className={`${btnType}`}
                    style = {buttonstyle}
                    type="button"
                    onClick={this.nextQuestion}
                    id="nextBtn"
                    disabled={this.state.checkAnswered}
                  >
                 Next <i className="fa fa-arrow-right"></i>
                  </Button>
                )}
              </div>
            </>
            
          }
          {(this.state.currentQuestion + 1) === this.state.questions.length && this.state.questions[this.state.currentQuestion].checked &&
           <>
             <div className={"text-" + `${alignment}`}>
               {mouseover ? (
                 <Button
                   className={`${btnType} get-results pulse`}
                   style={this.state.hoverGetRes ? buttonHover : buttonUnhover}
                   onMouseEnter={this.hoverOn.bind(this, "result")}
                   onMouseLeave={this.hoverOff.bind(this, "result")}
                   onClick={this.getResults}
                   disabled={this.state.checkAnswered}
                 >
             Get Results
                 </Button>
               ) : (
                 <Button
                   className={`${btnType} get-results pulse`}
                   style = {buttonstyle}
                   type="button"
                   id="nextBtn"
                   onClick={this.getResults}
                   disabled={this.state.checkAnswered}
                 >
             Get Results
                 </Button>
               )}
             </div>
           </>
          }
          {!this.state.inProgress &&
            <Results correct={this.state.correct} questionLength={this.state.questions.length} customtryagain={this.props.content.customtryagain} 
              startOver={this.startOver} hover={this.state.hoverTryagain} hoverOn={this.hoverOn.bind(this, "tryagain")} hoverOff = {this.hoverOff.bind(this, "tryagain")}
              style = {{background: this.props.content.buttonBgColor, color: this.props.content.buttonFontColor}} customisation = {this.props.content}
            />
          }
        </div>
      );
    }
    else if (this.props.content.checkedAnswer === false) {
      correctanswer = (
        <div className="bottom">
          {!this.state.questions[this.state.currentQuestion].checked && (this.state.currentQuestion + 1) < this.state.questions.length &&
           <>
             <div className={"text-" + `${alignment}`}>
               {mouseover ? (
                 <Button
                   className={`${btnType}`}
                   style={this.state.hoverNext ? buttonHover : buttonUnhover}
                   onMouseEnter={this.hoverOn.bind(this, "next")}
                   onMouseLeave={this.hoverOff.bind(this, "next")}
                   type="button"
                   onClick={this.nextQuestion}
                   id="nextBtn"
                   disabled={this.state.checkAnswered}
                 >
                Next <i className="fa fa-arrow-right"></i>
                 </Button>
               ) : (
                 <Button
                   className={`${btnType}`}
                   style = {buttonstyle}
                   type="button"
                   onClick={this.nextQuestion}
                   id="nextBtn"
                   disabled={this.state.checkAnswered}
                 >
                Next <i className="fa fa-arrow-right"></i>
                 </Button>
               )}
             </div>
           </>
          }
          {(this.state.currentQuestion + 1) === this.state.questions.length &&
             <>
               <div className={"text-" + `${alignment}`}>
                 {mouseover ? (
                   <Button
                     className={`${btnType} get-results pulse`}
                     style={this.state.hoverGetRes ? buttonHover : buttonUnhover}
                     onMouseEnter={this.hoverOn.bind(this, "result")}
                     onMouseLeave={this.hoverOff.bind(this, "result")}
                     onClick={this.getResults}
                     disabled={this.state.checkAnswered}
                   >
                 Get Results
                   </Button>
                 ) : (
                   <Button
                     className={`${btnType} get-results pulse`}
                     style = {buttonstyle}
                     type="button"
                     id="nextBtn"
                     onClick={this.getResults}
                     disabled={this.state.checkAnswered}
                   >
                 Get Results
                   </Button>
                 )}
               </div>
             </>
          }
          {!this.state.inProgress &&
            <Results correct={this.state.correct} questionLength={this.state.questions.length} customtryagain={this.props.content.customtryagain} 
              startOver={this.startOver} hover={this.state.hoverTryagain} hoverOn={this.hoverOn.bind(this, "tryagain")} hoverOff = {this.hoverOff.bind(this, "tryagain")}
              style = {{background: this.props.content.buttonBgColor, color: this.props.content.buttonFontColor}}
            />
          }
        </div>
      );
    }
    return (
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton >
          <Modal.Title id="contained-modal-title-vcenter" style={{wordBreak:"break-word"}}>
            {this.props.content.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <React.Fragment>
            <Container>
              <section className="quiz fade-in" aria-live="polite" style={{background: this.props.content.bgColor, padding: "15px"}}>
                <QuizProgress currentQuestion={this.state.currentQuestion} questionLength={this.state.questions.length} customisation={this.props.content}/>
                <div className="p-3 question-container" style = {borderleftStyle ? mcqborderleftStyle : (borderrightStyle) ? mcqborderrightStyle : (bordertopStyle) ? mcqbordertopStyle : (borderbottomStyle) ? mcqborderbottomtStyle : (borderfullStyle) ? mcqborderfullStyle : null }>
                  <div className="question" style={{fontSize: `${this.props.content.quFontSize}px `, fontWeight: `${this.props.content.quFontWeight}` }}>
                    {ReactHtmlParser(this.state.questions[this.state.currentQuestion].question)}
                  </div>
                  <form ref={this.radioRef}>
                    {this.state.questions[this.state.currentQuestion].options.map((item, index) => {
                      return <div key={index}
                        className={"option" + (this.state.questions[this.state.currentQuestion].checked && !item.correct ? " dim" : "") + (this.state.questions[this.state.currentQuestion].checked && item.correct ? " correct" : "")}
                        style={{background: this.props.content.opBgColor}}>
                        <input id={`${this.props.content.optiontype}-` + index} onClick={this.updateAnswer} type={this.props.content.optiontype} name="option" value={item.correct}
                          disabled={this.state.questions[this.state.currentQuestion].checked} />
                        <label htmlFor={`${this.props.content.optiontype}-` + index}><div className="optionAlignment"
                          style={{fontSize: `${this.props.content.opFontSize}px `, fontWeight: `${this.props.content.opFontWeight}`}}>
                          {ReactHtmlParser(item.option)}
                        </div>
                        </label>
                        {(item.check===true)? 
                          <Mytips tip={item.mytip}/>:""}
                      </div>;
                    })}
                  </form>
                  {correctanswer}
                </div>
              </section>
            </Container>
          </React.Fragment>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="btn btn-dark btn-sm btn-pill btn btn-primary" onClick={this.props.onHide}>Back to Editor</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
export default McqPreview;
