/* eslint-disable no-undefined */
import Quill from "quill";
import React,{Component} from "react";
import "./editor.scss";
import "quill/dist/quill.snow.css";
import swal from "sweetalert2";
import ImageResize from "quill-image-resize-module-react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import Rnd from "../Interactives/draganddrop/rnd";
import ReactHtmlParser from "react-html-parser";
import { setParameter } from "../../redux/form/form.actions";
import { connect } from "react-redux";
import Hotspoteditor from "../Interactives/hotspot/hotspotEditor";
import { validateDropZone, validateHotspot } from "../form/validate";
import { uploadToS3, deleteImagesFromS3 } from "../../middleware/api";
import ImageBlotExtension from "./editorExtension";

Quill.debug("error"); // To solve the warning errors created by quill
Quill.register(ImageBlotExtension);
Quill.register("modules/imageResize", ImageResize);
var FontAttributor = Quill.import("formats/font");
FontAttributor.whitelist = FontAttributor.whitelist = ["arial", "arialBlack", "brushScriptMT", "comicNewCourier", "georgia",  "helvetica", "lucida", "openSans", "lucidaSansUnicode", "tahoma", "timesNewRoman", "trebuchetMS", "verdana"];
Quill.register(FontAttributor, true);


const styles = {
  display: "inline-block",
};
const defaultMargins = {
  top: 0,
  left: 3,
  bottom: 3,
  right: 3
};
class Editor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show:false,
      img: "<p><br></p>",
      details: false,
      label:"",
      text:"",
      area:[],
      params:[{background: "", dropZone:[]}],
      activeZone: "",
      edit: false,
      position: false,
      editorValue: "",
      hotspotValue: "",
      iconShow: false,
      type: "",
      editorSelection: null,
      deletedImages: [],
      uploading: false
    };
    this.rndDimensions = this.rndDimensions.bind(this);
    this.handleEditor = this.handleEditor.bind(this);
  }
  componentDidMount() {
    const { id, value, type} = this.props;
    this.setState({ type : (type === "DragandDrop") ? "DropZone" : "Hotspot"});
    var icons =Quill.import("ui/icons");
    // used for changing the tool bar icon of image
    if(type === "Hotspoteditor"){
      icons["image"] = "<svg viewBox='0 0 18 18'> <rect class='ql-stroke' height='10' width='12' x='3' y='4'></rect> <circle class='ql-fill' cx='6' cy='7' r='1'></circle> <polyline class='ql-even ql-fill' points='5 12 5 11 7 9 8 10 11 7 13 9 13 12 5 12'></polyline> </svg>";
    }
    if(type === "DragandDrop" || type === "Hotspot"){
      icons["image"] = "Add Image";
    }

    let editor = new Quill (`#${id}`, {
      modules: {
        toolbar:`#toolbar${id}`,
        clipboard: {
          matchVisual: false
        },
        imageResize: { //used for resize and image inside the Quill Editor
          modules: [ "Resize", "DisplaySize" ]
        },
      },
      theme: "snow"
    });
    

    this.editor = editor;
    this.setEditorContents(editor, value);

    // binding the custom image upload function to quill editor
    editor.getModule("toolbar").addHandler("image", () => {
      this.selectLocalImage();
    });


    editor.on("text-change", async (newContents, oldContents, source) => {
      // eslint-disable-next-line no-undefined
      this.setState({img:editor.root.innerHTML});
      this.props.onChange(editor.root.innerHTML);

      // To capture the deleted images inside the editor
      if (source !== "user") return;
      const deletedImages = this.getImgUrls(editor.getContents().diff(oldContents));
      if(deletedImages.length >= 1){
        const src = deletedImages[0].src;
        if (src) deleteImagesFromS3(deletedImages);
      }
    });

    if(type === "DragandDrop" || type === "Hotspot") {
      let limit = 1;
      editor.on("text-change", () => {
        if (editor.getLength() > limit) {
          editor.deleteText(limit, editor.getLength());
        }
      });
      editor.root.addEventListener("keydown", (e) => {
        e.preventDefault();
      });
    }
  }

  onPaste = (e)=>{
    const text = e.clipboardData.getData("text/html");
    const element = document.createElement("div");
    element.innerHTML = text;
    let ImageTags = element.getElementsByTagName("img");
    if(ImageTags.length !== 0 ) {
      e.preventDefault();
      swal.fire({
        allowOutsideClick: false,
        icon: "warning",
        title: "Copied Images cannot be inserted",
        text: "Upload and use the images",
      });
    }
  }

  // To get the deleted Image urls inside the editor
  getImgUrls = (delta) => {
    return delta.ops.filter(i => i.insert && i.insert.image).map(i => i.insert.image);
  }

  // To upload image in editor
  selectLocalImage() {
    if(this.state.uploading) {swal.fire({
      allowOutsideClick: false,
      icon: "info",
      text: "Please wait until the image gets upload",
    });}else{
      const input = document.createElement("input");
      input.setAttribute("type", "file");
      input.click();

      // Listen upload local image and save to server
      input.onchange = () => {
        const file = input.files[0];

        // file type is only image.
        if (/^image\//.test(file.type)) {
          if(file.size <= 1000000){
            const range = this.editor.getSelection();
            this.setState({editorSelection: range !== null ? range.index : 0});
            this.setState({uploading: true});
            this.props.handleUpload();
            this.saveTos3(file);
          }else{
            swal.fire({
              allowOutsideClick: false,
              icon: "error",
              title: "Oops...",
              text: "Image size is bigger than 1MB",
              footer: file.name
            });
          }
        } else {
          swal.fire({
            allowOutsideClick: false,
            icon: "error",
            title: "Oops...",
            text: "This will support only Image Format",
            footer: file.name
          });
        }
      };
    
    }
  }

  /**save to s3**/

  saveTos3 = async(file) => {
    const data = new FormData();
    data.append("image", file);
    const updatedFile = await uploadToS3(data);
    this.insertToEditor(updatedFile.url);
  }

  /**insert image url to rich editor.**/

  insertToEditor(url) {
    const { editorSelection } = this.state;
    // push image url to rich editor.
    this.editor.insertEmbed(editorSelection, "image", {
      class : "image-uploading",
      src: url
    });
    this.setState({uploading: false});
    this.setState({editorSelection: null});
    this.props.handleUpload();
  }

  // set contents in editor
  setEditorContents (editor, value) {
    this.setState({img: value});
    var sel = editor.getSelection();
    if (typeof value === "string") {
      editor.setContents(editor.clipboard.convert(value));
    } else {
      editor.setContents(value);
    }
    if (sel && editor.hasFocus()) this.setEditorSelection(editor, sel);
  }

  setEditorSelection (editor, range) {
    if (range) {
      // Validate bounds before applying.
      var length = editor.getLength();
      range.index = Math.max(0, Math.min(range.index, length-1));
      range.length = Math.max(0, Math.min(range.length, (length-1) - range.index));
    }
    editor.setSelection(range);
  }

  componentDidUpdate (prevProps) {
    if (this.props.value !== prevProps.value){
      this.setEditorContents(this.editor, this.props.value);
      return true;
    }
  }

  // Modal functionalities

  handleopen (e){
    e.preventDefault();
    const { dndParameters, index } = this.props;
    let duplicateArr = Array.from(dndParameters);
    let params= Array.from(duplicateArr[index].dropZone);
    this.setState({
      area: params,
      show:true,
      label:"",
      text:"",
      params: dndParameters,
      position: true
    });
  }

  // reset the state values when closing the modal
  resetState () {
    this.setState({
      show:false,
      details: false,
      label:"",
      text:"",
      area:[],
      params:[{background: "", dropZone:[]}],
      position: false,
      edit:false,
      editorValue: "", 
      hotspotValue: "",
      activeZone: "",
      iconShow: false
    });
  }

  handleCloseIcon () {
    if (this.state.details) this.setState({ details: false, label:"", text:"", position: false, edit: false, editorValue: "", hotspotValue: "", activeZone: "" , iconShow: false});
    else this.resetState();
  }
  handleClose (e){
    e.preventDefault();
    this.resetState();
  }

  //handle the input fields inside the modal
  handleDetails (event) {
    const { value } = event.target;
    const { name } = event.target;
    if(name === "label"){ this.setState({label: value}); }
    else{ this.setState({text:value}); }
  }

  // handle the values of editor inside the modal
  handleEditor(value){
    this.setState({hotspotValue: value});
  }

  //save values when click on OK button in the modal
  handleSubmit(e) {
    this.setState({position: false});
    e.preventDefault();
    const { errors, SetParameter } = this.props;
    SetParameter({
      dndParameters: this.state.params,
      errors: errors
    });
    this.resetState();
  }

  //Drag and drop functionalitites

  //add dropZone on image
  addDropZone (index) {
    this.setState({position: false});
    const randomCoordinates = Math.floor(Math.random() * 200) + 1;
    let dropZone = [];
    this.state.params.map((data)=>{
      let obj = {background: data.background, dropZone:Array.from(data.dropZone)};
      dropZone.push(obj);
      return null;
    });
    const error = validateDropZone(dropZone, index, this.state.label, this.state.text, this.state.activeZone, this.state.edit);
    if(Object.keys(error).length === 0){
      dropZone[index].dropZone = [...dropZone[index].dropZone,
        {id: dropZone[index].dropZone.length, 
          data: { x:randomCoordinates,y:randomCoordinates,label: this.state.label, text:this.state.text, width: 150, height: 150 }}];
      this.setState({
        params: dropZone
      });
      let arr = [];
      if(arr.indexOf(randomCoordinates) === -1) arr.push(randomCoordinates);
      this.setState({
        details: false,
        label:"",
        text:"",
        area: dropZone[index].dropZone
      });
    }
    const active = document.getElementsByClassName("active");
    Array.from(active).forEach((ele) => {
      ele.classList.remove("active");
    });
  }

  // used to save the resized value of dropZone
  rndDimensions(obj, idx) {
    this.setState({
      activeZone: idx,
    });
    const { index } = this.props;
    const parameters = [];
    this.state.params.map((data)=>{
      let obj = {background: data.background, dropZone:data.dropZone};
      parameters.push(obj);
      return null;
    });
    const elementsIndex = parameters[index].dropZone.findIndex(element => element.id === idx );
    let data = parameters[index].dropZone;
    parameters[index].dropZone[elementsIndex].data = {...parameters[index].dropZone[elementsIndex].data, x:obj.x, y:obj.y, width:obj.w, height: obj.h };
    parameters[index].dropZone = data;
    this.setState({
      area: parameters[index].dropZone,
      params: parameters
    });
  }

  // update the dropZone values
  updateDropZone = () =>{
    this.setState({position: false});
    const { index } = this.props;
    const parameters = Array.from(this.state.params);
    let duplicateArray =[];
    parameters.map((data)=>{
      let obj = {background: data.background, dropZone: Array.from(data.dropZone)};
      duplicateArray.push(obj);
      return null;
    });
    const error = validateDropZone(duplicateArray, index, this.state.label, this.state.text, this.state.activeZone, this.state.edit);
    if(Object.keys(error).length === 0){
      const elementsIndex = parameters[index].dropZone.findIndex(element => element.id ===this.state.activeZone);
      parameters[index].dropZone[elementsIndex].data = {...parameters[index].dropZone[elementsIndex].data,label: this.state.label, text:this.state.text};
      this.setState({
        area: parameters[index].dropZone,
        params: parameters,
        details:false ,
        edit: false,
        label:"",
        text: ""
      });
    }
  }
  
  // hotspot functionalities

  //add hotspot on the image
  addHotspot (index){
    this.setState({position: false});
    const randomCoordinates = Math.floor(Math.random() * 200) + 1;
    let dropZone = [];
    this.state.params.map((data)=>{
      let obj = {background: data.background, dropZone:Array.from(data.dropZone)};
      dropZone.push(obj);
      return null;
    });
    const error = validateHotspot(dropZone, index, this.state.label, this.state.hotspotValue, this.state.activeZone, this.state.edit);
    if(Object.keys(error).length === 0){
      dropZone[index].dropZone = [...dropZone[index].dropZone,
        {id: dropZone[index].dropZone.length, 
          data: { x:randomCoordinates,y:randomCoordinates,label: this.state.label, text:this.state.hotspotValue, width: 150, height: 150 }}];
      this.setState({
        params: dropZone
      });
      let arr = [];
      if(arr.indexOf(randomCoordinates) === -1) arr.push(randomCoordinates);
      this.setState({
        details: false,
        label:"",
        hotspotValue:"",
        area: dropZone[index].dropZone,
        iconShow: false
      });
    }
  }

  // update the hotspot values
  updateHotspot = () =>{
    this.setState({position: false});
    const { index } = this.props;
    const parameters = Array.from(this.state.params);
    let duplicateArray =[];
    parameters.map((data)=>{
      let obj = {background: data.background, dropZone: Array.from(data.dropZone)};
      duplicateArray.push(obj);
      return null;
    });
    const error = validateHotspot(duplicateArray, index, this.state.label, this.state.hotspotValue, this.state.activeZone, this.state.edit);
    if(Object.keys(error).length === 0){
      const elementsIndex = parameters[index].dropZone.findIndex(element => element.id ===this.state.activeZone);
      parameters[index].dropZone[elementsIndex].data = {...parameters[index].dropZone[elementsIndex].data,label: this.state.label, text:this.state.hotspotValue};
      this.setState({
        area: parameters[index].dropZone,
        params: parameters,
        details:false ,
        edit: false,
        label:"",
        text: "",
        editorValue: "",
        hotspotValue: ""
      });
    }
  }

  //common functionalites of DragandDrop and Hotspot

  //delete the dropzone/hotspot
  dropZoneDetacher(e) {
    e.preventDefault();
    const { id } = this.props;
    let editorIndex = id.match(/\d+/);
    editorIndex = editorIndex[0];
    const dropzoneState = this.state.params;
    const elementsIndex = dropzoneState[editorIndex].dropZone.findIndex(element => element.id === this.state.activeZone );
    let dropZoneData = [...dropzoneState[editorIndex].dropZone];
    if (elementsIndex !== -1) dropZoneData.splice(elementsIndex, 1);
    dropzoneState[editorIndex].dropZone = dropZoneData;
    const clonedAreaState = this.state.area;
    const index = clonedAreaState.findIndex(element => element.id === this.state.activeZone);
    if (index !== -1) clonedAreaState.splice(index, 1);
    this.setState({
      area: clonedAreaState,
      params: dropzoneState,
      iconShow: false
    });
  }

  //edit the dropZone/hotspot
  editDropZone = (e) =>{
    e.preventDefault();
    const { index } = this.props;
    const dropzoneState = this.state.params;
    const elementsIndex = dropzoneState[index].dropZone.findIndex(element => element.id === this.state.activeZone );
    const dropZoneData = dropzoneState[index].dropZone[elementsIndex];
    this.setState({
      details:true ,
      edit: true,
      label: dropZoneData.data.label,
      text: dropZoneData.data.text,
      editorValue: dropZoneData.data.text,
      hotspotValue: dropZoneData.data.text
    });
  }

  setActiveZone = (index) => {
    this.setState({activeZone: index, iconShow: true});
  }

  render() {
    const { id, type, dndLayout, index } = this.props;
    return(
      <React.Fragment>
        {/* Customizing toolbar */}
        <div id={`toolbar${id}`} className="ql-toolbar ql-snow">
          { (type !== "DragandDrop") && (type !== "Hotspot")?
            <>
              <select className="ql-header" title="Header">
                <option value="">Normal</option>
                <option value="1"></option>
                <option value="2"></option>
                <option value="3"></option>
                <option value="4"></option>
                <option value="5"></option>
                <option value="6"></option>
              </select>
              <button className="ql-bold" title="Bold"/>
              <button className="ql-italic" title="Italic"/>
              <button className="ql-underline" title="Underline"/>
              <button className="ql-strike" title="Strike"/>
              <button className="ql-align" value="" title="Left-align"/>
              <button className="ql-align" value="right" title="right-align"/>
              <button className="ql-align" value="center"title="center-align"/>
              <button className="ql-align" value="justify" title="Justify"/>
              <button className="ql-indent" value="+1" title="Left-indent"/>
              <button className="ql-indent" value="-1" title="Right-indent"/>
              <button className="ql-blockquote" title="Blockquote"/>
              <button className="ql-list"  value="ordered" title="Ordered-list"/>
              <button className="ql-list"  value="bullet" title="Bullet-list"/>
              <select className="ql-color" title="color"/>
              <select className="ql-background" title="BACKGROUND" />
              <button className="ql-image" title="Image"/>
              <button className="ql-link" title="Link"/>
              <button className="ql-video" title="Video"/>
              <button className="ql-clean" title="Clean"/>
              <select className="ql-font" title="Font-family">
                <option value="arial" defaultValue>Arial</option>
                <option value="arialBlack">Arial Black</option>
                <option value="brushScriptMT">Brush Script MT</option>
                <option value="comicNewCourier">Comic New Courier</option>
                <option value="georgia">Georgia</option>
                <option value="helvetica">Helvetica</option>
                <option value="lucida">Lucida</option>
                <option value="openSans">Open Sans</option>
                <option value="lucidaSansUnicode">Lucida Sans Unicode</option>
                <option value="tahoma">Tahoma</option>
                <option value="timesNewRoman">Times New Roman</option>
                <option value="trebuchetMS">Trebuchet MS</option>
                <option value="verdana">Verdana</option>
              </select>
              {this.state.uploading && this.editorSelection !== null ? <small className="ml-5 text-danger">Uploading <i className="fas fa-sync fa-spin"></i></small> : ""}
            </> :  ( <>
              <button className="ql-image dndImgTool">Add Image</button>
              { 
                ((this.state.img!=="" && this.state.img!=="<p><br></p>")&& ((dndLayout === "CustomOverlay" && type === "DragandDrop" ) || type === "Hotspot" )) ? 
                  <button id="dropzoneModalBtn" className="dropzone-modalBtn" onClick={(e) => this.handleopen(e)}>
                    {`Add ${this.state.type}`} </button> : "" 
              }
              {this.state.uploading && this.editorSelection !== null ? <small className="ml-5 text-danger">Uploading <i className="fas fa-sync fa-spin"></i></small> : ""}
            </>
            ) 
          }
        </div>
        <div id={id} onPaste={(e)=>this.onPaste(e)}/>
        <Modal className="dropzone-modal"  size="lg" centered show={this.state.show} onHide={() => this.handleCloseIcon()}>
          <Modal.Header closeButton>
            <Modal.Title> { !this.state.edit ?  `Add ${this.state.type}` : `Update ${this.state.type}`} </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="zone position-relative">
              { !this.state.details && ((dndLayout === "CustomOverlay" && type === "DragandDrop") || type === "Hotspot")?
                <>
                  <div className="dropzone-toolbar">
                    <button title= "Drop Zone" onClick={() => this.setState({details:true })}>
                      <i title=  {type === "Hotspot" ? "Hotspot" : "Drop Zone"} className="fa fa-plus-circle" aria-hidden="true"></i>
                    </button>
                    {(this.state.iconShow) ? <><button title= "Delete" onClick={(e) => this.dropZoneDetacher(e)}>
                      <i title= "Delete" className="far fa-trash-alt" aria-hidden="true"></i> 
                    </button><button className="mx-2 px-1 rounded" title= "Edit" onClick={this.editDropZone}>
                      <i className="far fa-edit" aria-hidden="true"></i>
                    </button></>
                      : "" }
                  </div> 
                  <div className="position-relative innerContent">
                    {
                      this.state.area.map((data, idx) => {
                        return(
                          <Rnd key={idx} id={idx} margin={defaultMargins} prop = {data} setActiveZone = {this.setActiveZone}
                            dimensions={this.rndDimensions} position = {this.state.position} type={type} size={this.props.size}>
                            <h4 key={idx} style={styles}>{data.label}</h4>
                          </Rnd>
                        );})
                    }
                    <div className="boundry" id="boundry">
                      {ReactHtmlParser(this.state.img)}
                    </div>
                  </div> 
                </>
                :
                <Form>
                  {
                    type === "DragandDrop" ?
                      <Row>
                        <Col>
                          <Form.Control value={this.state.label} placeholder="Label" name="label" onChange={(e) => this.handleDetails(e)}/>
                          <Form.Text id= "label" className="text-danger"></Form.Text>
                        </Col>
                        <Col>
                          <Form.Control value={this.state.text} placeholder="Text" name="text" onChange={(e) => this.handleDetails(e)}/>
                          <Form.Text id= "text" className="text-danger"></Form.Text>
                        </Col>
                      </Row>:
                      <Form.Group>
                        <Col>
                          <Form.Label htmlFor={index}>Label</Form.Label>
                          <Form.Control value={this.state.label} placeholder="Label" name="label" onChange={(e) => this.handleDetails(e)}/>
                          <Form.Text id= "label" className="text-danger"></Form.Text>
                        </Col>
                        <Col>
                          <Form.Label htmlFor={index}>Content</Form.Label>
                          <Hotspoteditor value={this.state.editorValue} events={this.handleEditor}></Hotspoteditor>
                          <Form.Text id= "text" className="text-danger"></Form.Text>
                        </Col>
                      </Form.Group>
                  }
                  {!this.state.edit ?
                    <Button onClick={() => type === "DragandDrop" ? this.addDropZone(index) : this.addHotspot(index)} className="mt-3">Add</Button> :
                    <Button onClick={type === "DragandDrop" ?this.updateDropZone: this.updateHotspot} className="mt-3">Update</Button> 
                  }
                </Form>
              }
            </div>
          </Modal.Body>
          {!this.state.details && ((dndLayout === "CustomOverlay" && type === "DragandDrop") || type === "Hotspot") ?
            <Modal.Footer>
              <Button variant="secondary" onClick={(e) => this.handleClose(e)}>
            Cancel
              </Button>
              <Button variant="primary" onClick={(e) => this.handleSubmit(e)}>
            Ok
              </Button> 
            </Modal.Footer> : ""}
        </Modal>
      </React.Fragment>
    );
  }
}
const mapStateToProps = ({ form }) => ({
  errors: form.errors,
  dndParameters: form.dndParameters
});
//props to redux
const mapDispatchToProps = dispatch => ({
  SetParameter : form => dispatch(setParameter(form))
});
//connecting form with redux
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Editor);
