/* eslint-disable no-useless-concat */
/* eslint-disable no-undefined */
import React from "react";
import ReactHtmlParser from "react-html-parser";
import { putItems } from "../../redux/components/reducer.action";
import { Card, Container, Button, Row, Col} from "react-bootstrap";
import { fetchContent, viewContent } from "../../middleware/api";
import { initializeReactGA } from "../../middleware/googleAnalytics";
import { connect } from "react-redux";
import Header from "../layouts/navbar";
import { Redirect } from "react-router-dom";
import "../../assets/scss/interactiveCard.scss";
import PostHeightIframe from "../../middleware/sendHeight";

let content, response;

class InteractiveCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewpage: this.props.location.pathname,
    };
  }
  //Getting data from the Redux-state
  async componentDidMount() {
    const { PutItems } = this.props;
    const { id } = this.props.match.params;
    if (
      this.props.location.pathname === `ClickAndRevealInteractions/${id}` ||
      `clickAndRevealInteractions/${id}`
    ) {
      response = await viewContent(id);
    } else {
      if (
        this.props.match.params.user === undefined ||
        this.props.match.params.user === ""
      ) {
        content = sessionStorage.getItem("user_id");
      } else {
        content = this.props.match.params.user;
      }
      response = await fetchContent(id, content);
    }
    if (!response.message && response.library_id === 7) {

      //To check the tracking id of GA
      if(this.state.viewpage !== `/ClickAndRevealInteractions/${id}` ) initializeReactGA(this.props.location.pathname);

      PutItems({
        title: response.title,
        parameters: JSON.parse(response.parameters),
        customisations: response.customisations,
      });
    } else {
      this.props.history.push("/*");
    }
    if(this.state.viewpage === `/ClickAndRevealInteractions/${id}` ) PostHeightIframe();
  }

  //Edit functionality for interactivecard
  handleEdit = () => {
    let path;
    if (this.props.match.params.user === undefined) {
      path = "/edit/ClickAndRevealInteractions";
    } else {
      path = `/content/ClickAndRevealInteractions/${this.props.match.params.user}`;
    }
    const { id } = this.props.match.params;
    if (id) {
      this.props.history.push({
        pathname: `${path}/${id}/${sessionStorage.getItem("org_id")}/${sessionStorage.getItem("loreeVersion")}`,
        state: {
          user:
            this.props.location.state !== undefined
              ? this.props.location.state.user
              : "",
          user_id:
            this.props.location.state !== undefined
              ? this.props.location.state.user_id
              : "",
        },
      });
    }
  };

  handleClick = (idx) => {
    document.getElementById(`sub_card${idx}`).className="w-50";
  }

  render() {
    // render interactivecard view
    const { title, parameters, customisations } = this.props;
    const { id } = this.props.match.params;
    const { viewpage } = this.state;
    const fontHeadings = ((this.props.customisations.fontHeadings !== "") ? this.props.customisations.fontHeadings : "div");

    const borderleftStyle = (customisations.borderType === "border-left border-dark") ? true : false;

    const borderrightStyle = (customisations.borderType === "border-right border-dark") ? true : false;

    const bordertopStyle = (customisations.borderType === "border-top border-dark") ? true : false;  

    const borderbottomStyle = (customisations.borderType === "border-bottom border-dark") ? true : false;  

    const borderfullStyle = (customisations.borderType === "border border-dark") ? true : false;  

    const cardStyle = {
      fontFamily: `${customisations.fontFamily}`,
      fontWeight: `${customisations.fontWeight}`,
      fontSize: `${customisations.fontSize}px`,
      textAlign: `${customisations.alignment}`,
      color: `${customisations.fontColor}`,
    };

    const interactivecardborderleftStyle = {
      borderLeft: `${customisations.borderSize}px ` + `${customisations.borderStyle} ` + `${customisations.borderColor}`
    };

    const interactivecardborderrightStyle = {
      borderRight: `${customisations.borderSize}px ` + `${customisations.borderStyle} ` + `${customisations.borderColor}`
    };

    const interactivecardbordertopStyle = {
      borderTop: `${customisations.borderSize}px ` + `${customisations.borderStyle} ` + `${customisations.borderColor}`
    };

    const interactivecardborderbottomtStyle = {
      borderBottom: `${customisations.borderSize}px ` + `${customisations.borderStyle} ` + `${customisations.borderColor}`
    };

    const interactivecardborderfullStyle = {
      border: `${customisations.borderSize}px ` + `${customisations.borderStyle} ` + `${customisations.borderColor}`
    };

    if (viewpage === `/ClickAndRevealInteractions/${id}`) {
      return (
        <React.Fragment>
          <Container>
            {
              parameters.map ((content, idx)=> (
                <Row className="my-3" key={idx}>
                  <Col xs={6} id="interactive-image" className="interactive-imageView" >                  
                    <Card key={idx} id={`card${idx}`} style={borderleftStyle ? interactivecardborderleftStyle : (borderrightStyle) ? interactivecardborderrightStyle : (bordertopStyle) ? interactivecardbordertopStyle : (borderbottomStyle) ? interactivecardborderbottomtStyle : (borderfullStyle) ? interactivecardborderfullStyle : null} className={`w-75 cardHeight shadow ${customisations.radius}`} onClick={()=>this.handleClick(idx)}  >
                      <Card.Body className="interactive-textstyle">
                        {ReactHtmlParser(content["text"])}
                      </Card.Body>
                    </Card>
                                
                  </Col>
                  <Col xs={6} key={idx} id={`sub_card${idx}`}  className={"d-none"} >
                    <Card  className={`w-75 cardHeight shadow ${customisations.radius}`} style={borderleftStyle ? interactivecardborderleftStyle : (borderrightStyle) ? interactivecardborderrightStyle : (bordertopStyle) ? interactivecardbordertopStyle : (borderbottomStyle) ? interactivecardborderbottomtStyle : (borderfullStyle) ? interactivecardborderfullStyle : null }  id="interactive-content">
                      <Card.Body as={fontHeadings} className="interactive-textstyle" style={cardStyle}>
                        {content["title"]}
                      </Card.Body>
                    </Card>
                  
                  </Col>
                </Row>
              ))
            }
          </Container>
        </React.Fragment>
      );
    } else if (
      viewpage === `/create/new/ClickAndRevealInteractions/${id}` ||
      `contentview/ClickAndRevealInteractions/${this.props.match.params.user}/${id}`
    ) {
      if (this.props.location.state !== undefined) {
        return (
          <React.Fragment>
            <Header
              user={localStorage.getItem("user")}
              user_id={sessionStorage.getItem("user_id")}
              data={this.props}
            />
            <Container className="margin">
              <Row className="d-flex justify-content-between mt-3">
                <Col style={{wordBreak:"break-word"}}>
                  <h2>{title}</h2>
                </Col>
                <Col xs lg="1">
                  <Button className="btn btn-dark" onClick={this.handleEdit}>
                    Edit
                  </Button>
                </Col>
              </Row>

              {
                parameters.map ((content, idx)=> (
                  <Row className="my-3" key={idx}>
                    <Col xs={6} id="interactive-image" className="interactive-imageView" >                  
                      <Card key={idx} id={`card${idx}`} style={borderleftStyle ? interactivecardborderleftStyle : (borderrightStyle) ? interactivecardborderrightStyle : (bordertopStyle) ? interactivecardbordertopStyle : (borderbottomStyle) ? interactivecardborderbottomtStyle : (borderfullStyle) ? interactivecardborderfullStyle : null} className={`w-75 cardHeight shadow ${customisations.radius}`} onClick={()=>this.handleClick(idx)}  >
                        <Card.Body className="interactive-textstyle">
                          {ReactHtmlParser(content["text"])}
                        </Card.Body>
                      </Card>
                                    
                    </Col>
                    <Col xs={6} key={idx} id={`sub_card${idx}`}  className={"d-none"} >
                      <Card className={`w-75 cardHeight shadow ${customisations.radius}`} style={borderleftStyle ? interactivecardborderleftStyle : (borderrightStyle) ? interactivecardborderrightStyle : (bordertopStyle) ? interactivecardbordertopStyle : (borderbottomStyle) ? interactivecardborderbottomtStyle : (borderfullStyle) ? interactivecardborderfullStyle : null } id="interactive-content">
                        <Card.Body as={fontHeadings} className="interactive-textstyle" style={cardStyle}>
                          {content["title"]}
                        </Card.Body>
                      </Card>
                      
                    </Col>
                  </Row>
                ))
              }
            </Container>
          </React.Fragment>
        );
      } else {
        return <Redirect to="/401" />;
      }
    }
  }
}

//state from redux
const mapStateToProps = ({ component }) => ({
  title: component.title,
  parameters: component.parameters,
  customisations: component.customisations,
});

//props to redux
const mapDispatchToProps = dispatch => ({
  PutItems: component => dispatch(putItems(component)),
});

//connecting form with redux
export default connect(mapStateToProps, mapDispatchToProps)(InteractiveCard);
