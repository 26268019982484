import React from "react";
import { Rnd } from "react-rnd";

const getBounds = (cssStyleDeclaration) => {
  if (cssStyleDeclaration.boxSizing === "border-box") {
    return {
      h: parseInt(cssStyleDeclaration.height, 10),
      w: parseInt(cssStyleDeclaration.width, 10)
    };
  }

  return {
    h:
      parseInt(cssStyleDeclaration.height, 10) +
      parseInt(cssStyleDeclaration.marginTop, 10) +
      parseInt(cssStyleDeclaration.marginBottom, 10),
    w:
      parseInt(cssStyleDeclaration.width, 10) +
      parseInt(cssStyleDeclaration.marginLeft, 10) +
      parseInt(cssStyleDeclaration.marginRight, 10)
  };
};

const WrapChild = ({ children, prop, dimensions, position, type, setActiveZone, size}) => {
  const ref = React.useRef();
  const [bounds, setBounds] = React.useState({ h: 0, w: 0 });

  const removeActive = () => {
    const active = document.getElementsByClassName("active");
    Array.from(active).forEach((ele) => {
      ele.classList.remove("active");
    });
  };

  const updateBounds = () => {
    removeActive();
    if (ref.current) {
      const wrapper = ref.current.parentNode.parentNode;
      wrapper.classList.add("active");
      const rect = getBounds(getComputedStyle(ref.current));
      if (rect.h !== bounds.h || rect.w !== bounds.w) setBounds(rect);
    }
  };

  const calculateCoordinates = () => {
    // eslint-disable-next-line no-undefined
    if(document.getElementsByClassName("active")[0] !== undefined){
      let parentPos = document.getElementsByClassName("active")[0].parentNode.getBoundingClientRect(),
        childPos = document.getElementsByClassName("active")[0].getBoundingClientRect(),
        coordinates= {};
      coordinates.top = childPos.top - parentPos.top;
      coordinates.right = childPos.right - parentPos.right;
      coordinates.bottom = childPos.bottom - parentPos.bottom;
      coordinates.left = childPos.left - parentPos.left;
      return coordinates;
    }
  };

  const getBoundary = (info,dimensions) => {
    removeActive();
    if (ref.current) {
      const wrapper = ref.current.offsetParent;
      wrapper.classList.add("active");
    }
    const coords = calculateCoordinates();
    const act = document.getElementsByClassName("active")[0];
    // eslint-disable-next-line no-undefined
    if(coords !== undefined){
      const obj = {
        x:coords.left,
        y:coords.top,
        w:act.style.width,
        h:act.style.height,
      };
      dimensions(obj,info.id);
    }
  };

  const getSize = (info,dimensions) => {
    const coords = calculateCoordinates();
    const act = document.getElementsByClassName("active")[0];
    const obj = {
      x:coords.left,
      y:coords.top,
      w:act.style.width,
      h:act.style.height,
    };
    dimensions(obj,info.id);
  };

  React.useLayoutEffect(updateBounds, [bounds]);

  const child = React.Children.only(children);

  let renderStyle = {
    backgroundColor: "transparent",
    display: "inline-block",
    border: "2px solid red",
    willChange: "transform",
    left: prop.data.x + "px",
    top: prop.data.y + "px",
    position: "absolute"
  };

  let updatedStyle = {
    backgroundColor: "transparent",
    display: "inline-block",
    border: "2px solid red",
    position: "absolute"
  };

  if(type === "Hotspot"){
    return (
      <Rnd
        style={ position ? renderStyle : updatedStyle}
        position={ !position ? { x: prop.data.x, y: prop.data.y } : ""}
        size={{ width: size, height: size}}
        minWidth={20}
        minHeight={20}
        bounds=".boundry"
        onClick = {()=>{setActiveZone(prop.id);}}
        onDragStop={() => getBoundary(prop, dimensions)}
        enableResizing = {{
          bottom: false,
          bottomLeft: false,
          bottomRight: false,
          left: false,
          right: false,
          top: false,
          topLeft: false,
          topRight: false,
        } | false
        }
      >
        <div>{React.cloneElement(child, { ref })}</div>
      </Rnd>
    );
  } else{
    return (
      <Rnd
        style={ position ? renderStyle : updatedStyle}
        position={ !position ? { x: prop.data.x, y: prop.data.y } : ""}
        size={{ width: prop.data.width, height: prop.data.height }}
        minWidth={100}
        minHeight={100}
        bounds=".boundry"
        onClick = {()=>{setActiveZone(prop.id);}}
        onDragStop={() => getBoundary(prop, dimensions)}
        onResize={updateBounds}
        onResizeStop= {() => getSize(prop, dimensions)}
      >
        <div>{React.cloneElement(child, { ref })}</div>
      </Rnd>
    );
  }
};

export default WrapChild;
