/* eslint-disable no-undefined */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Card, CardDeck, Col, Accordion, FormControl, Button, InputGroup, ButtonGroup, ButtonToolbar } from "react-bootstrap";
import { customOption, customCheck, retry, customshuffle, pbBgColor,pbFontColor, quFontSize, opFontSize, qufontWeight, opfontWeight, bgColor, opBgColor,
  borderType, borderColor, borderSize, borderStyle, buttonFontColor, buttonBgColor, fontType, fontSize, fontWeight, pbFontHeadings, 
  pbFontType, pbFontSize, pbFontWeight, setAlignment, setBtnType, customPaddingbottom, customPaddingright, customPaddingleft, customPaddingtop} from "../../redux/multipleChoice/mcq.action";
import { SketchPicker } from "react-color";
import styles from "../../assets/customstyles";

class Customize extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseID: "collapse1",
      displayColorPicker: false,
      displayBgColorPicker: false,
      displayOpBgColorPicker: false,
      displayPbFontColorPicker: false,
      displaypbBgColorPicker: false,
      displayButtonBgColorPicker: false,
      displayButtonFontColorPicker: false,

    };
  }

  // toggle the collapse ID for changing the accordion icon
  toggleCollapse(collapseID) {
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : "",
      displayColorPicker: false,
      displaybgColorPicker: false,
      displayOpBgColorPicker: false,
      displayPbFontColorPicker: false,
      displaypbBgColorPicker: false,
      displayButtonBgColorPicker: false
    }));
  }
  // customization for option(single choice or multiple choice)
  customOption(e) {
    const { Customoption } = this.props;
    let optiontypes = e.target.value;
    Customoption({
      optiontype: optiontypes
    });
  }

  // customization for checking answer
  customCheck() {
    const { CustomCheck } = this.props;
    const { checkedAnswer } = this.props.content;
    CustomCheck({
      checkedAnswer: !checkedAnswer
    });
  }

  // customization for try again button
  retry() {
    const { tryagain } = this.props;
    const { customtryagain } = this.props.content;
    tryagain({
      customtryagain: !customtryagain
    });
  }
  // customization for shuffle
  customshuffle() {
    const { customShuffle } = this.props;
    const{ shuffle } = this.props.content;
    customShuffle({
      shuffle: !shuffle
    });
  }

  // fontsize customization
  handlefontsize = (type, e) => {
    const { QuFontSize, OpFontSize , HandleFontSize, HandlepbFontSize} = this.props;
    const {value} = e.target;
    var charCode = (e.which) ? e.which : e.keyCode;
    if ((value <= 40) === true ) {
      if (value==="00" || charCode === 8 || charCode === 13 || charCode === 99 || charCode === 118 || charCode === 46 || charCode === 101 || charCode === 45 
      || charCode === 43) {
        e.preventDefault();
      }
      if(type === "question"){
        QuFontSize({
          quFontSize: e.target.value,
        });
      } else if(type==="option"){
        OpFontSize({
          opFontSize: e.target.value,
        });
      } else if(type === "progressBar"){
        HandlepbFontSize({pbFontSize: e.target.value});
      }else {
        HandleFontSize({fontSize: e.target.value});
      }
    }
  }

  // customisation for fontWeight
  handlefontWeight = (type, e) => {
    const { QuFontWeight, OpFontWeight, HandleFontWeight, HandlepbFontWeight } = this.props;
    if(type === "question"){
      QuFontWeight({
        quFontWeight: e.target.value,
      });
    } else if(type==="option"){
      OpFontWeight({
        opFontWeight: e.target.value,
      });
    } else if(type === "progressBar"){
      HandlepbFontWeight({pbFontWeight: e.target.value});
    } else {
      HandleFontWeight({fontWeight: e.target.value});
    }
  }
  //border type
  handleborderType = (e) => {
    const { BorderType } = this.props;
    BorderType({
      borderType: e.target.value
    });
  }

  handleborderSize = (e) => {
    const { BorderSize } = this.props;
    BorderSize({
      borderSize: e.target.value
    });
  }

  handleborderStyle = (e) => {
    const { BorderStyle } = this.props;
    BorderStyle({
      borderStyle: e.target.value
    });
  }

  //change the border color in the color pallet
  changeborderColor = (color) => {
    const { BorderColor } = this.props;
    BorderColor({
      borderColor: color.hex ? color.hex : color.target.value
    });
  }
  colorSubmit = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  }

  //customize buttons
   // background color
   changeButtonBgColor= (color) => {
     const { ButtonBgColor } = this.props;
     ButtonBgColor({
       buttonBgColor: color.hex ? color.hex : color.target.value
     });
   }
   buttonbgColorSubmit= () => {
     this.setState({ displayButtonBgColorPicker: !this.state.displayButtonBgColorPicker });
   }
  // Font color
  changeButtonfontColor= (color) => {
    const { ButtonFontColor } = this.props;
    ButtonFontColor({
      buttonFontColor: color.hex ? color.hex : color.target.value
    });
  }
  buttonFontColorSubmit= () => {
    this.setState({ displayButtonFontColorPicker: !this.state.displayButtonFontColorPicker });
  }
 


  //Customize progress bar
  // background color
  changePbBgColor= (color) => {
    const { PbBgColor } = this.props;
    PbBgColor({
      pbBgColor: color.hex ? color.hex : color.target.value
    });
  }
  pBbgColorSubmit= () => {
    this.setState({ displaypbBgColorPicker: !this.state.displaypbBgColorPicker });
  }
  // Font color
  changePbfontColor= (color) => {
    const { PbFontColor } = this.props;
    PbFontColor({
      pbFontColor: color.hex ? color.hex : color.target.value
    });
  }
  pbFontColorSubmit= () => {
    this.setState({ displayPbFontColorPicker: !this.state.displayPbFontColorPicker });
  }

 //Background color change
 // customize question Background color
 changeBgColor= (color) => {
   const { BgColor } = this.props;
   BgColor({
     bgColor: color.hex ? color.hex : color.target.value
   });
 }
 bgColorSubmit= () => {
   this.setState({ displaybgColorPicker: !this.state.displaybgColorPicker });
 }

 //customize option Backgound color
 changeOptionBgColor = (color) => {
   const { OpBgColor } = this.props;
   OpBgColor({
     opBgColor: color.hex ? color.hex : color.target.value
   });
 }
 oPbgColorSubmit= () => {
   this.setState({ displayOpBgColorPicker: !this.state.displayOpBgColorPicker });
 }

 pbFontHeadings = (e) => {
   const { HandlepbFontHeadings, HandlepbFontWeight, HandlepbFontSize } = this.props;
   HandlepbFontHeadings({pbFontHeadings: e.target.value});
   HandlepbFontWeight({pbFontWeight: "Bold"});

   if(e.target.value === "h1"){
     HandlepbFontSize({pbFontSize: 32});
   }
   else if(e.target.value === "h2"){
     HandlepbFontSize({pbFontSize: 24});
   }
   else if(e.target.value === "h3"){
     HandlepbFontSize({pbFontSize: 21});
   }
   else if(e.target.value === "h4"){
     HandlepbFontSize({pbFontSize: 16});
   }
   else if(e.target.value === "h5"){
     HandlepbFontSize({pbFontSize: 12});
   }
   else if(e.target.value === "h6"){
     HandlepbFontSize({pbFontSize: 11});
   }
   else{
     HandlepbFontWeight({
       pbFontWeight: "Normal"
     });
     HandlepbFontSize({
       pbFontSize: 16
     });
   }
 }

//font customisation
fontType = (type,e) => {
  const { HandleFontFamily, HandlepbFontFamily } = this.props;
  if(type === "progressBar"){
    HandlepbFontFamily({pbFontFamily: e.target.value});
  }
  else if(type === "button"){
    HandleFontFamily({fontFamily: e.target.value});
  }
}

setAlignment = (e) => {
  const { Setalignment } = this.props;
  Setalignment({
    alignment:e.target.value
  });
}

//value for buttontype
btnType = (event) => {
  const { BtnType } = this.props;
  BtnType({
    btnType: event.target.value
  });
}

setCustompadding = (type,e) => {
  const { SetcustomPaddingtop, SetcustomPaddingbottom, SetcustomPaddingright, SetcustomPaddingleft } = this.props;
  const {value} = e.target;
  var charCode = (e.which) ? e.which : e.keyCode;

  if ((value <= 200) === true ) {
    if (charCode === 8 || charCode === 13 || charCode === 99 || charCode === 118 || charCode === 46 || charCode === 101 || charCode === 45 || charCode === 43) {
      e.preventDefault();
    }

    if(type === "top") {SetcustomPaddingtop({customPaddingtop:e.target.value});}
    else if (type === "bottom") {SetcustomPaddingbottom({customPaddingbottom:e.target.value});}
    else if (type === "right") {SetcustomPaddingright({customPaddingright:e.target.value});}
    else {SetcustomPaddingleft({customPaddingleft:e.target.value});}
  }
}

render() {
  const { customtryagain, checkedAnswer, shuffle, optiontype,pbFontColor, pbBgColor, quFontSize, quFontWeight, opFontSize, opFontWeight, bgColor, opBgColor,
    borderSize, borderStyle, borderColor, borderType, buttonBgColor, buttonFontColor, fontFamily, fontSize, fontWeight, btnType, alignment,
    customPaddingtop, customPaddingleft, customPaddingbottom, customPaddingright, pbFontHeadings, pbFontFamily, pbFontSize, pbFontWeight } =  this.props.content;
  const { collapseID } = this.state;
  return (
    <React.Fragment>
      <Col sm={4} className="float-right">
        <Card>
          <Card.Header className="h4 font-weight-bold header">Customize</Card.Header>
          <Card.Body className="p-0">
            <Accordion defaultActiveKey="0">
              <Accordion.Toggle className="font-weight-bold" as={Card.Header} eventKey="0" onClick={() => this.toggleCollapse("collapse1")}>
                <i className={collapseID === "collapse1" ? "fa fa-caret-down" : "fa fa-caret-right"}></i> Progress Bar
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body className="subheader_bottom">
                  <Form>
                    <Form.Row>
                      <Form.Group as={Col}>
                        <Form.Label>Font Header</Form.Label>
                        <Form.Control as="select" value={pbFontHeadings} onChange={this.pbFontHeadings} size="md">
                          <option style={{ display: "none" }}>
                            {((pbFontHeadings ==="" || pbFontHeadings === undefined)|| pbFontHeadings === "Normal") ? "Normal" : (pbFontHeadings.toUpperCase())}
                          </option>
                          {
                            styles.fontHeadings.map((fontlist, i) => {
                              const value = ((fontlist === "Normal") ? "" :`h${i + 1}`);
                              const data =  ((fontlist === "Normal") ? fontlist :fontlist.toUpperCase());
                              return (<option key={i} value={value} className={`h${i + 1}`}>{data}</option>);
                            })
                          }
                        </Form.Control>
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Label>Font Family</Form.Label>
                        <Form.Control as="select" defaultValue = {pbFontFamily} onChange={this.fontType.bind(this, "progressBar")} size="md">
                          {/* Looping for font family */}
                          {
                            Object.entries(styles.fontFamily).map(([key, value], i) => {
                              return <option key={i} style={{ fontFamily: value }} value = {value} >{key}</option>;
                            })
                          }
                        </Form.Control>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col}>
                        <Form.Label>Font Size</Form.Label>
                        <InputGroup className="mb-3">
                          <InputGroup.Prepend>
                          </InputGroup.Prepend>
                          <Form.Control type="number" min="10" max="40" name="fontSize" size="md" value={pbFontSize} 
                            onKeyPress={this.handlefontsize.bind(this, "progressBar")} onChange={this.handlefontsize.bind(this, "progressBar")} />
                          <InputGroup.Append>
                            <InputGroup.Text>px</InputGroup.Text>
                          </InputGroup.Append>
                          <Form.Text className="text-muted">Value must be 10-40px</Form.Text>
                        </InputGroup>
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Label>Font Weight</Form.Label>
                        <Form.Control as="select"  value = {pbFontWeight} onChange={this.handlefontWeight.bind(this, "progressBar")} size="md">
                          {
                            styles.fontWeight.map((fontlist, i) => {
                              return <option key={i} value = {fontlist}>{fontlist}</option>;
                            })
                          }
                        </Form.Control>
                      </Form.Group>
                    </Form.Row>
                    <Form.Group>
                      <label>Background Color</label>
                      <InputGroup size="sm">
                        <FormControl
                          value={pbBgColor}
                          onChange={this.changePbBgColor}
                          maxLength="7"
                        />
                        <InputGroup.Append>
                          <Button variant="outline-secondary" onClick={this.pBbgColorSubmit} style={{ backgroundColor: pbBgColor }}><i className="fa fa-eye-dropper"></i></Button>
                        </InputGroup.Append>
                      </InputGroup>
                      {this.state.displaypbBgColorPicker ? <SketchPicker disableAlpha color={pbBgColor} onChange={this.changePbBgColor}></SketchPicker> : null}
                    </Form.Group>
                    <Form.Group>
                      <label>Font Color</label>
                      <InputGroup size="sm">
                        <FormControl
                          value={pbFontColor}
                          onChange={this.changePbfontColor}
                          maxLength="7"
                        />
                        <InputGroup.Append>
                          <Button variant="outline-secondary" onClick={this.pbFontColorSubmit} style={{ backgroundColor: pbFontColor }}><i className="fa fa-eye-dropper"></i></Button>
                        </InputGroup.Append>
                      </InputGroup>
                      {this.state.displayPbFontColorPicker ? <SketchPicker disableAlpha color={pbFontColor} onChange={this.changePbfontColor}></SketchPicker> : null}
                    </Form.Group>
                  </Form>
                </Card.Body>
              </Accordion.Collapse>
              <Accordion.Toggle className="font-weight-bold" as={Card.Header} eventKey="1" onClick={() => this.toggleCollapse("collapse2")}>
                <i className={collapseID === "collapse2" ? "fa fa-caret-down" : "fa fa-caret-right"}></i> Buttons
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <Card.Body className="subheader_bottom">
                  <Form>
                    <label>Type</label>
                    <CardDeck>
                      {Object.entries(styles.btnType).map(([key,value], idx) => {
                        return (
                          <label key={idx}>
                            <input type="radio" name="btn-type" value={value} onChange={this.btnType} checked={btnType === value} className="card-input-element" />
                            <Card className="small-card card-input">
                              <Button variant={value} size="sm" style={{ marginTop: 15, height: 30 }}>{key}</Button>
                            </Card>
                          </label>
                        );
                      })}
                    </CardDeck>
                    <label className="mt-3">Alignment</label>
                    <ButtonToolbar>
                      <ButtonGroup style={{width: "100%"}} size="sm">
                        <Button variant="secondary" active={alignment === "left"} onClick={this.setAlignment} value="left" className="iconleft border-right border-white"></Button>
                        <Button variant="secondary" active={alignment === "center"} onClick={this.setAlignment} value="center" className="iconcenter border-right border-white"></Button>
                        <Button variant="secondary" active={alignment === "right"} onClick={this.setAlignment} value="right" className="iconright border-right border-white"></Button>
                      </ButtonGroup>
                    </ButtonToolbar>
                    <Form.Group className="mt-3">
                      <Form.Label>Padding <span style={{ fontSize: 13 }} className="text-muted"> (Value must be 0-200px) </span> </Form.Label>
                      <Form.Row>
                        <Col xs={6}>                    
                          <Form.Label>Top</Form.Label>
                          <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                            </InputGroup.Prepend>
                            <Form.Control type="number" min="0" max="200" value={customPaddingtop} onKeyPress={this.setCustompadding.bind(this, "top")} 
                              onChange={this.setCustompadding.bind(this, "top")} />
                            <InputGroup.Append>
                              <InputGroup.Text>px</InputGroup.Text>
                            </InputGroup.Append>
                          </InputGroup>
                        </Col>
                        <Col>
                          <Form.Label>Left</Form.Label>
                          <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                            </InputGroup.Prepend>
                            <Form.Control type="number" min="0" max="200" value={customPaddingleft} onKeyPress={this.setCustompadding.bind(this, "left")} 
                              onChange={this.setCustompadding.bind(this, "left")} />
                            <InputGroup.Append>
                              <InputGroup.Text>px</InputGroup.Text>
                            </InputGroup.Append>
                          </InputGroup>
                        </Col>
                        <Col xs={6}>
                          <Form.Label>Bottom</Form.Label>
                          <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                            </InputGroup.Prepend>
                            <Form.Control type="number" min="0" max="200" value={customPaddingbottom} onKeyPress={this.setCustompadding.bind(this, "bottom")} 
                              onChange={this.setCustompadding.bind(this, "bottom")} />
                            <InputGroup.Append>
                              <InputGroup.Text>px</InputGroup.Text>
                            </InputGroup.Append>
                          </InputGroup> </Col>
                        <Col>
                          <Form.Label>Right</Form.Label>
                          <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                            </InputGroup.Prepend>
                            <Form.Control type="number" min="0" max="200" value={customPaddingright} onKeyPress={this.setCustompadding.bind(this, "right")} 
                              onChange={this.setCustompadding.bind(this, "right")} />
                            <InputGroup.Append>
                              <InputGroup.Text>px</InputGroup.Text>
                            </InputGroup.Append>
                          </InputGroup>
                        </Col>
                      </Form.Row>
                    </Form.Group>
                    <Form.Group>
                      <label>Background Color</label>
                      <InputGroup size="sm">
                        <FormControl
                          value={buttonBgColor}
                          onChange={this.changeButtonBgColor}
                          maxLength="7"
                        />
                        <InputGroup.Append>
                          <Button variant="outline-secondary" onClick={this.buttonbgColorSubmit} style={{ backgroundColor: buttonBgColor }}><i className="fa fa-eye-dropper"></i></Button>
                        </InputGroup.Append>
                      </InputGroup>
                      {this.state.displayButtonBgColorPicker ? <SketchPicker disableAlpha color={buttonBgColor} onChange={this.changeButtonBgColor}></SketchPicker> : null}
                    </Form.Group>
                    <Form.Group className="mt-4">
                      <label>Font Color</label>
                      <InputGroup size="sm">
                        <FormControl
                          value={buttonFontColor}
                          onChange={this.changeButtonfontColor}
                          maxLength="7"
                        />
                        <InputGroup.Append>
                          <Button variant="outline-secondary" onClick={this.buttonFontColorSubmit} style={{ backgroundColor: buttonFontColor }}><i className="fa fa-eye-dropper"></i></Button>
                        </InputGroup.Append>
                      </InputGroup>
                      {this.state.displayButtonFontColorPicker ? <SketchPicker disableAlpha color={buttonFontColor} onChange={this.changeButtonfontColor}></SketchPicker> : null}
                    </Form.Group>
                    <Form.Row>
                      <Form.Group as={Col}>
                        <Form.Label>Font Size</Form.Label>
                        <InputGroup className="mb-3">
                          <InputGroup.Prepend>
                          </InputGroup.Prepend>
                          <Form.Control type="number" min="10" max="40" name="fontSize" size="md" value={fontSize} 
                            onKeyPress={this.handlefontsize.bind(this,"button")} onChange={this.handlefontsize.bind(this,"button")} />
                          <InputGroup.Append>
                            <InputGroup.Text>px</InputGroup.Text>
                          </InputGroup.Append>
                          <Form.Text className="text-muted">Value must be 10-40px</Form.Text>
                        </InputGroup>
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Label>Font Family</Form.Label>
                        <Form.Control as="select" value = {fontFamily} onChange={this.fontType.bind(this, "button")} size="md">
                          {/* Looping for font family */}
                          {
                            Object.entries(styles.fontFamily).map(([key, value], i) => {
                              return <option key={i} style={{ fontFamily: value }} value = {value} >{key}</option>;
                            })
                          }
                        </Form.Control>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                    
                      <Form.Group as={Col}>
                        <Form.Label>Font Weight</Form.Label>
                        <Form.Control as="select"  value = {fontWeight} onChange={this.handlefontWeight.bind(this, "button")} size="md">
                          {
                            styles.fontWeight.map((fontlist, i) => {
                              return <option key={i} value = {fontlist}>{fontlist}</option>;
                            })
                          }
                        </Form.Control>
                      </Form.Group>
                    </Form.Row>
                  </Form>
                </Card.Body>
              </Accordion.Collapse>
              <Accordion.Toggle className="font-weight-bold" as={Card.Header} eventKey="2" onClick={() => this.toggleCollapse("collapse3")}>
                <i className={collapseID === "collapse3" ? "fa fa-caret-down" : "fa fa-caret-right"}></i> Question
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="2">
                <Card.Body className="subheader_bottom">
                  <Form>
                    <Form.Group>
                      <Form.Label>Type</Form.Label>
                      <Form.Check 
                        type="radio"
                        name="answertype"
                        id="answertypeSingle"
                        label="Single Select"
                        onChange={this.customOption.bind(this)}
                        defaultChecked={optiontype === "radio"}
                        value="radio"
                      />
                      <Form.Check 
                        type="radio"
                        name="answertype"
                        id="answertypeMultiple"
                        label="Multi Select"
                        onChange={this.customOption.bind(this)}
                        defaultChecked={optiontype === "checkbox"} 
                        value="checkbox"
                      />
                      <small id="emailHelp" className="mb-3 form-text text-muted">{optiontype === "radio" ? "Single Select - Able to select only one option" : "Multi Select - Able to select multiple options"}</small>
                    </Form.Group>
                    <Form.Row>
                      <Form.Group as={Col}>
                        <Form.Label>Font Size</Form.Label>
                        <InputGroup className="mb-3">
                          <InputGroup.Prepend>
                          </InputGroup.Prepend>
                          <Form.Control type="number" min="10" max="40" name="fontSize" size="md" value={quFontSize} 
                            onChange={this.handlefontsize.bind(this, "question")}  onKeyPress={this.handlefontsize.bind(this, "question")}/>
                          <InputGroup.Append>
                            <InputGroup.Text>px</InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Label>Font Weight</Form.Label>
                        <Form.Control as="select"  defaultValue = {quFontWeight} onChange={this.handlefontWeight.bind(this, "question")}  size="md">
                          {
                            styles.fontWeight.map((fontlist, i) => {
                              return <option key={i} value = {fontlist}>{fontlist}</option>;
                            })
                          }
                        </Form.Control>
                      </Form.Group>
                    </Form.Row>
                    <Form.Group>
                      <label>Background Color</label>
                      <InputGroup size="sm">
                        <FormControl
                          value={bgColor}
                          onChange={this.changeBgColor}
                          maxLength="7"
                        />
                        <InputGroup.Append>
                          <Button variant="outline-secondary" onClick={this.bgColorSubmit} style={{ backgroundColor: bgColor }}><i className="fa fa-eye-dropper"></i></Button>
                        </InputGroup.Append>
                      </InputGroup>
                      {this.state.displaybgColorPicker ? <SketchPicker disableAlpha color={bgColor} onChange={this.changeBgColor}></SketchPicker> : null}
                    </Form.Group>
                  </Form>
                </Card.Body>
              </Accordion.Collapse>
              <Accordion.Toggle className="font-weight-bold" as={Card.Header} eventKey="3" onClick={() => this.toggleCollapse("collapse4")}>
                <i className={collapseID === "collapse4" ? "fa fa-caret-down" : "fa fa-caret-right"}></i> Option
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="3">
                <Card.Body className="subheader_bottom">
                  <Form>
                    <Form.Row>
                      <Form.Group as={Col}>
                        <Form.Label>Font Size</Form.Label>
                        <InputGroup className="mb-3">
                          <InputGroup.Prepend>
                          </InputGroup.Prepend>
                          <Form.Control type="number" min="10" max="40" name="fontSize" size="md" value={opFontSize} 
                            onChange={this.handlefontsize.bind(this, "option")} onKeyPress={this.handlefontsize.bind(this, "option")} />
                          <InputGroup.Append>
                            <InputGroup.Text>px</InputGroup.Text>
                          </InputGroup.Append>
                          <Form.Text className="text-muted">Value must be 10-40px</Form.Text>
                        </InputGroup>
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Label>Font Weight</Form.Label>
                        <Form.Control as="select"  defaultValue = {opFontWeight} onChange={this.handlefontWeight.bind(this, "option")} size="md">
                          {
                            styles.fontWeight.map((fontlist, i) => {
                              return <option key={i} value = {fontlist}>{fontlist}</option>;
                            })
                          }
                        </Form.Control>
                      </Form.Group>
                    </Form.Row>
                    <Form.Group>
                      <label>Background Color</label>
                      <InputGroup size="sm">
                        <FormControl
                          value={opBgColor}
                          onChange={this.changeOptionBgColor}
                          maxLength="7"
                        />
                        <InputGroup.Append>
                          <Button variant="outline-secondary" onClick={this.oPbgColorSubmit} style={{ backgroundColor: opBgColor }}><i className="fa fa-eye-dropper"></i></Button>
                        </InputGroup.Append>
                      </InputGroup>
                      {this.state.displayOpBgColorPicker ? <SketchPicker disableAlpha color={opBgColor} onChange={this.changeOptionBgColor}></SketchPicker> : null}
                    </Form.Group>
                  </Form>
                </Card.Body>
              </Accordion.Collapse>
              <Accordion.Toggle className="font-weight-bold" as={Card.Header} eventKey="4" onClick={() => this.toggleCollapse("collapse5")}>
                <i className={collapseID === "collapse5" ? "fa fa-caret-down" : "fa fa-caret-right"}></i> Border
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="4">
                <Form className="p-4">
                  <label>Border-alignment</label>
                  <CardDeck>
                    {Object.entries(styles.borderType).map(([key,value], idx) => {
                      return (
                        <label key={idx}>
                          <input type="radio" name="border-type" defaultValue={value} onChange={this.handleborderType} defaultChecked={borderType === value} className="card-input-element" />
                          <Card className="small-card card-input">
                            <span className={value} size="sm" style={{ marginTop: 15, height: 30}}>{key}</span>
                          </Card>
                        </label>
                      );
                    })}
                  </CardDeck>
                  <Form.Group>
                    <label>Border Color</label>
                    <InputGroup size="sm">
                      <FormControl
                        value={borderColor}
                        onChange={this.changeborderColor}
                        maxLength="7"
                      />
                      <InputGroup.Append>
                        <Button variant="outline-secondary" onClick={this.colorSubmit} style={{ backgroundColor: borderColor }}><i className="fa fa-eye-dropper"></i></Button>
                      </InputGroup.Append>
                    </InputGroup>
                    {this.state.displayColorPicker ? <SketchPicker disableAlpha color={borderColor} onChange={this.changeborderColor}></SketchPicker> : null}
                  </Form.Group> 
                  <Form.Row>
                    <Form.Group as={Col}>
                      <Form.Label>Border Size</Form.Label>
                      <Form.Control as="select" defaultValue={borderSize} onChange={this.handleborderSize} size="sm">
                        <option style={{ display: "none" }}>{(borderSize) ? borderSize : "Select"}</option>
                        {
                          styles.borderSize.map((borderlist, i) => {
                            return <option key={i} value={borderlist}>{borderlist}</option>;
                          })
                        }
                      </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Form.Label>Border Style</Form.Label>
                      <Form.Control as="select"  defaultValue={borderStyle} className="text-capitalize" onChange={this.handleborderStyle} size="sm">
                        <option style={{ display: "none" }}>{(borderStyle) ? borderStyle : "Select"}</option>
                        {
                          Object.entries(styles.borderStyle).map(([key,value], idx) => {
                            return <option value = {value}  key={idx}>{key}</option>;
                          })
                        }
                      </Form.Control>
                    </Form.Group>
                  </Form.Row>
  
                </Form>
              </Accordion.Collapse>
              <Accordion.Toggle className="font-weight-bold" as={Card.Header} eventKey="5" onClick={() => this.toggleCollapse("collapse6")}>
                <i className={collapseID === "collapse6" ? "fa fa-caret-down" : "fa fa-caret-right"}></i> Behavioural Settings
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="5">
                <Form className="p-4">
                  <Form.Check 
                    type="switch"
                    className="switch-dark"
                    id="custom-switch1"
                    label="Check Answer"
                    defaultChecked={checkedAnswer} 
                    onChange={this.customCheck.bind(this)}
                  />
                  <small id="emailHelp" className="mb-3 form-text text-muted">{checkedAnswer ? "Individual answer details will be shown" : "Individual answer details will not be shown"}</small>
                  <Form.Check 
                    type="switch"
                    className="switch-dark"
                    id="custom-switch2"
                    label="Retry Button"
                    onChange={this.retry.bind(this)} 
                    defaultChecked={customtryagain}
                  />
                  <small id="emailHelp" className="mb-3 form-text text-muted">{customtryagain ? "Able to retry the test" : "No retry option after complete test"}</small>
                  <Form.Check 
                    type="switch"
                    className="switch-dark"
                    id="custom-switch3"
                    label="Shuffle Order"
                    onChange={this.customshuffle.bind(this)} 
                    defaultChecked={shuffle}
                  />
                  <small id="emailHelp" className="mb-3 form-text text-muted">{shuffle ? "Options shuffle in each try" : "Options display in same order"}</small>
                </Form>
              </Accordion.Collapse>
            </Accordion>
          </Card.Body>
        </Card>
      </Col>
    </React.Fragment>
  );
}
}

const mapStateToProps = (mcqform) => ({
  customresult: mcqform.customresult,
  optiontype: mcqform.optiontype,
  checkedAnswer: mcqform.checkedAnswer,
  customtryagain: mcqform.customtryagain,
  shuffle: mcqform.shuffle,
  pbBgColor: mcqform.pbBgColor,
  pbFontColor: mcqform.pbFontColor,
  quFontSize: mcqform.quFontSize,
  quFontWeight: mcqform.quFontWeight,
  opFontSize : mcqform.opFontSize,
  opFontWeight: mcqform.opFontWeight,
  bgColor: mcqform.bgColor,
  opBgColor: mcqform.opBgColor,
  borderSize: mcqform.borderSize, 
  borderStyle: mcqform.borderStyle, 
  borderColor:mcqform.borderColor,
  borderType:mcqform.borderType,
  buttonFontColor: mcqform.buttonFontColor,
  buttonBgColor: mcqform.buttonBgColor,
  fontFamily: mcqform.fontFamily,
  fontSize: mcqform.fontSize,
  fontWeight: mcqform.fontWeight,
  pbFontHeadings: mcqform.pbFontHeadings,
  pbFontFamily: mcqform.pbFontFamily,
  pbFontSize: mcqform.pbFontSize,
  pbFontWeight: mcqform.pbFontWeight,
  alignment: mcqform.alignment,
  btnType: mcqform.btnType,
  customPaddingtop:mcqform.customPaddingtop,
  customPaddingleft:mcqform.customPaddingleft,
  customPaddingbottom:mcqform.customPaddingbottom,
  customPaddingright:mcqform.customPaddingright
});

const mapDispatchToProps = dispatch => ({
  Customoption: form => dispatch(customOption(form)),
  CustomCheck: form => dispatch(customCheck(form)),
  tryagain: form => dispatch(retry(form)),
  customShuffle: form => dispatch(customshuffle(form)),
  PbBgColor : form => dispatch(pbBgColor(form)),
  PbFontColor : form => dispatch(pbFontColor(form)),
  QuFontSize: form => dispatch(quFontSize(form)),
  OpFontSize: form => dispatch(opFontSize(form)),
  QuFontWeight: form => dispatch(qufontWeight(form)),
  OpFontWeight: form => dispatch(opfontWeight(form)),
  BgColor: form => dispatch(bgColor(form)),
  OpBgColor: form => dispatch(opBgColor(form)),
  BorderType: form => dispatch(borderType(form)),
  BorderColor: form => dispatch(borderColor(form)),
  BorderSize: form => dispatch(borderSize(form)),
  BorderStyle: form => dispatch(borderStyle(form)),
  ButtonFontColor: form => dispatch(buttonFontColor(form)),
  ButtonBgColor: form => dispatch(buttonBgColor(form)),
  HandleFontFamily: form => dispatch(fontType(form)),
  HandleFontSize: form => dispatch(fontSize(form)),
  HandleFontWeight: form => dispatch(fontWeight(form)),
  HandlepbFontHeadings: form => dispatch(pbFontHeadings(form)),
  HandlepbFontFamily: form => dispatch(pbFontType(form)),
  HandlepbFontSize: form => dispatch(pbFontSize(form)),
  HandlepbFontWeight: form => dispatch(pbFontWeight(form)),
  Setalignment: form => dispatch(setAlignment(form)),
  BtnType: data => dispatch(setBtnType(data)),
  SetcustomPaddingtop: form => dispatch(customPaddingtop(form)),
  SetcustomPaddingleft: form => dispatch(customPaddingleft(form)),
  SetcustomPaddingbottom: form => dispatch(customPaddingbottom(form)),
  SetcustomPaddingright: form => dispatch(customPaddingright(form))
});

export default connect(mapStateToProps, mapDispatchToProps)(Customize);
