//initial state for redux
const INITIAL_STATE = {
  num: 0,
  contentArr: [["title_0", "fronttext_0", "backtext_0"]],
  title: "",
  parameters: [{title: "", fronttext: "", backtext: ""}],
  errors: {},
  type: "",
  bgcolor: "#ffffff",
  backColor: "#ffffff",
  height: "253",
  noOfCard: "4",
  borderType:"",
  borderColor:"#000000",
  borderSize:"1",
  borderStyle:"solid",
  radius: "rounded-0",
  flip: "0"
};

//action creators with actions
const formReducer = (state = INITIAL_STATE, data) => {
  switch (data.type) {
  //add panel action
  case "ADD_PANEL":
    return {
      ...state,
      parameters: data.parameters,
      contentArr: data.contentArr,
      num: data.num
    };
    //title within panel action
  case "HANDLE_CHANGE":
    return {
      ...state,
      parameters: data.parameters,
      errors: data.errors
    };

  //rearange the parameters
  case "REARANGE_ARRAY":
    return {
      ...state,
      parameters: data.parameters,
      contentArr: data.contentArr
    };

  //main-title action  
  case "HANDLE_TITLE":
    return {
      ...state,
      title: data.title
    };

  //remove action
  case "REMOVE_CLICK": 
    return {
      ...state,
      parameters: data.parameters,
      contentArr: data.contentArr,
      errors: data.errors
    };

  //validation action
  case "ERRORS": 
    return {
      ...state,
      errors: data.errors
    };
  case "MAKE_EDIT":
    return {
      ...state,
      parameters: data.parameters,
      contentArr: data.contentArr,
      title: data.title,
      type: data.component,
      bgcolor : data.bgcolor,
      backColor: data.backColor,
      height: data.height,
      noOfCard: data.noOfCard,
      radius: data.radius,
      borderType: data.borderType,
      borderColor: data.borderColor,
      borderSize: data.borderSize,
      borderStyle: data.borderStyle,
      flip: data.flip
    };
  case "SET_TYPE":
    return {
      ...state,
      type: data.component
    };

  // To set the ID for the panels 
  case "SET_VALUE":
    return{
      ...state,
      num: data.num
    };

  case "CUSTOM_BGCOLOR":
    return {
      ...state,
      bgcolor: data.bgcolor
    };
  case "CUSTOM_BACKCOLOR":
    return {
      ...state,
      backColor: data.backColor
    };
  case "CUSTOM_HEIGHT":
    return {
      ...state,
      height: data.height
    };
  case "CUSTOM_NOOFCARD":
    return {
      ...state,
      noOfCard: data.noOfCard
    };

  case "CUSTOM_BORDER":
    return {
      ...state,
      borderType: data.borderType
    };

  case "BORDER_COLOR":
    return {
      ...state,
      borderColor: data.borderColor
    };

  case "BORDER_SIZE":
    return {
      ...state,
      borderSize:data.component
    };   

  case "BORDER_STYLE":
    return {
      ...state,
      borderStyle:data.component
    };
  case "CUSTOM_RADIUS":
    return {
      ...state,
      radius: data.radius
    };
  case "CUSTOM_FLIP":
    return {
      ...state,
      flip: data.flip
    };
  default: 
    return state;
  }
};
  
export default formReducer;
